
import "./App.css";
import React,{ useLayoutEffect ,useEffect ,useState } from "react";
import {  Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import App2 from "./App2";
import CookieConsent, { Cookies } from "react-cookie-consent";


const Loader = () => <h1>Loading...</h1>;

export default function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => { 
    console.log("in my app") 
    console.log(isMobile)
    }, [isMobile]); 
    
  function useWindowSize() {

    useLayoutEffect(() => {
     
      function updateSize() {
        if(window.innerWidth<window.innerHeight){
        setIsMobile(true);
        console.log("change to true") 
        
        }else{
          console.log("change to false");
          setIsMobile(false);
        }
       
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
   return isMobile;
  }
  function ShowWindowDimensions(props) {
    useWindowSize();
   
  // return <span>Window size: {window.innerHeight} x {tooltipHeight} size1 {size1} size2 {size2} nav {navHht} bar {barHht}</span>;
  }
  return (
    <div className="App" key={isMobile}>
       {isMobile?
       <div></div>
:<CookieConsent
       enableDeclineButton
       buttonText='Agree'
       buttonClasses="CookiesAcceptButton"
       declineButtonText='Disagree'
       declineButtonClasses="CookiesDisagreeButton"
  location="top"
  style={{ background: "#606060",fontSize: "1.8vw",height:"7vw" ,marginTop:'5vw'}}

  buttonStyle={{ borderRadius:'2vw',backgroundColor:"#f7941d",height:"2.5vw",width:"7vw",color: "white", fontSize: "1.4vw" ,marginTop:'2vw'}}
  declineButtonStyle={{ borderRadius:'2vw',height:"2.5vw",width:"9vw", backgroundColor:"#a41c24",color: "white", fontSize: "1.4vw" ,marginTop:'2vw'}}
  expires={150}
>
  This website uses cookies to enhance the user experience
</CookieConsent> }
<ShowWindowDimensions/>
    
    
        <Routes>
          <Route path="/" element={<App2 isMobile1={isMobile} />} />
         
        </Routes>
       
   
      
    </div>
  );
}
