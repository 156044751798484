import React, {  useState,useEffect }  from "react";
import "./MainPrec.css"; 

import FrontBodyImg from './Images/FrontBodyMain.png';
import FrontBodyImgWithMove from './Images/test.png';
import RingsBackground from './Images/rings1.png';
import WelcomeTalk from './Images/TalkWelcome.png';
import HandsImg from './Images/technology-human-touch-background-modern-remake-creation-adam.jpg';
import DohaLogoImg from './Images/DohaLogo.png';
import DohaFirstLogoImg from './Images/DohaLogo1st.png';

import MainMobileImg from './Images/MobileMAinPic.png';
function MainPrec(){
  const [flag, setFlag] = useState(true );
  const [bodyImage, setbodyImage] = useState(FrontBodyImg);
  const[isMobile]=useState(window.innerWidth<window.innerHeight);
  //const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });

 
  useEffect(() => {
    const DelayFunction = () => {
      if(flag===true && !isMobile)
      {
      setTimeout(() => {
          var TalkRef    =document.getElementById("TalkImageContainer");
          var RingsRef   =document.getElementById("RingsBackgroundContainer");
          var DohaLogoRef=document.getElementById("DohaLogoFirstContainer");
          var BtnRef     =document.getElementById("ExploreButton");
          if(TalkRef !=null && RingsRef !=null && BtnRef !=null)
          {
           // WordsRef.style.display="none";
            setbodyImage(FrontBodyImgWithMove);
            DohaLogoRef.style.display="block";
             TalkRef.style.display="grid";
             RingsRef.style.display="block";
             BtnRef.style.display="block";
          }
             }, 100);
             setFlag(false );
      }else{
       console.log("main prec mobile i am here ");
      }
  };
  DelayFunction();
}, []);

 
  /* function DelayFunction2(){
        
    setTimeout(() => {
        var WordsRef =document.getElementById("WordsWelcome");
        var BodyRef =document.getElementById("Body");
        var RingsRef=document.getElementById("RingsBackgroundContainer");
       // var RingsFinalRef=document.getElementById("FinalRingsCorner");
        
        if(BodyRef !=null && WordsRef!=null)
        {
          RingsRef.style.display="none";
           // RingsRef.style.display="block";
          // RingsFinalRef.style.display="block";
            WordsRef.style.animation = "WordsWelcomeAnimate 0.1s linear forwards 1"; 
            BodyRef.style.animation = "BodyWelcomeAnimate 0.1s linear forwards 1"; 
            
            
        }
           }, 1000);
    setTimeout(() => {
        var MainContentRef=document.getElementById("MainContent1");
        var BookRef=document.getElementById("BallMain");
         BookRef.style.display="block"
        BookRef.style.animation = "BallMainAnimate 0.5s linear forwards 1"; 
        setbodyImage(FrontBodyImgWithMove);
       MainContentRef.style.display="block"
               }, 1500);
               

  }*/
  const Go2Main3 = () =>{ 
    var HandsRef=document.getElementById("HandsContainer");
    var TalkRef=document.getElementById("TalkImageContainer");
    var BtnRef=document.getElementById("ExploreButton");
    var RingsRef=document.getElementById("RingsBackgroundContainer");
    var BodyRef=document.getElementById("Body");
    var DohaLogoRef=document.getElementById("DohaLogoContainer");
    var DohaLogoFirstRef=document.getElementById("DohaLogoFirstContainer");
    var WordsWelcomeRef=document.getElementById("TextWelcomContainer");
    var TextPart1Ref=document.getElementById("Text1part");
    var MorebtnRef=document.getElementById("MoreContainer");
    if(HandsRef !=null )
    {
      WordsWelcomeRef.style.display="block";
      TextPart1Ref.style.display="block";
      MorebtnRef.style.display="block";
      DohaLogoRef.style.display="block";
      DohaLogoRef.style.animation = "DohaLogoAnimate 1s linear forwards 1"; 
      BodyRef.style.display="none";
      RingsRef.style.display="none";
      DohaLogoFirstRef.style.display="none";
      HandsRef.style.display="block";
      HandsRef.style.animation = "HandsAnimate 1s linear forwards 1"; 
      TalkRef.style.display="none";
      BtnRef.style.display="none";
    }
    
  } 
  const Go2More = () =>{
    var WordsWelcomeRef               =document.getElementById("TextWelcomContainer");
    var Text1Ref                      =document.getElementById("Text1");
    var TextFixedRef                  =document.getElementById("fixedText");
    var HandsContainerRef             =document.getElementById("HandsContainer");
    var PhargraphcontainerRef         =document.getElementById("Phargraphcontainer");
    var MorebtnRef                    =document.getElementById("MoreBullet");
    HandsContainerRef.style.animation = "Hands2Animate 0.5s linear forwards 1"; 
    MorebtnRef.style.display          ="none";
    Text1Ref.style.display            ="none";
    TextFixedRef.style.display        ="block";
    PhargraphcontainerRef.style.display="grid";
    WordsWelcomeRef.style.top="3%";
  }
  const Go2MobileMore = () =>{
    var Text1Ref                      =document.getElementById("Text2WelcomContainerMobile");
    var Text2Ref                      =document.getElementById("Text3WelcomContainerMobile");
    var btnRef                        =document.getElementById("MoreMobileContainer");
    var PhargraphcontainerRef         =document.getElementById("MobilePhargraphcontainer");
    btnRef.style.display              ="none";
    Text1Ref.style.display            ="none";
    Text2Ref.style.display            ="block";
    PhargraphcontainerRef.style.display="grid";
  }
 /* const Go2Main2 = () =>{ 
    var TalkRef=document.getElementById("TalkImageContainer");
    var RingsRef=document.getElementById("RingsBackgroundContainer");
    var BtnRef=document.getElementById("ExploreButton");
    var WordsRef =document.getElementById("WordsWelcome");
    if(TalkRef !=null && RingsRef !=null && BtnRef !=null && WordsRef !=null)
    {
      
      WordsRef.style.display="block";
      setbodyImage(FrontBodyImg);
       TalkRef.style.display="none";
       RingsRef.style.animation="RingsAnimate 1s linear forwards 1"; 
       RingsRef.style.transform = "translateX(" + (-100) + "%) translateY(" + (50) + "%)";
       BtnRef.style.display="none";
       
    }
    DelayFunction2();
  } */
 

      return (
      
        <div className="MainPrecContainerAll">
          {(isMobile)&&
          <div className="MobileOuter">
<div className="MainMobileContainer">

<img className="MainMobileImg" fetchpriority="high" loading="eager" id ="MainMobileImg" src={MainMobileImg}  alt=""></img>

 </div>
 <div className="TextWelcomContainerMobile">
            <p >  نقدم أفضل الحلول</p>  
            <p> لتلبية <span  style={{ color:" #005368"}}>أهدافك</span></p>
  </div>
  <div className="Text2WelcomContainerMobile" id = "Text2WelcomContainerMobile">
            <p >تعمل الدوحة لينك وفق إستراتيجية واضحة تستثمر في الأنسان، وتركز على جودة الحياة التي تؤثر على تنمية المجتمعات والدول. أنشأت وفق الرؤية الوطنية لدولة قطر 2030 </p>  
  </div>              
  <div className="Text3WelcomContainerMobile" id = "Text3WelcomContainerMobile">
            <p >تعمل الدوحة لينك وفق إستراتيجية واضحة تستثمر في الأنسان، وتركز على جودة الحياة التي تؤثر على تنمية المجتمعات والدول. أنشأت وفق الرؤية الوطنية لدولة قطر 2030 </p>  
   
   <div className="MobilePhargraphcontainer" id="MobilePhargraphcontainer">
      <p className="typedMobile">، لدعم التعليم والثقافة والفنون والتكنولوجيا، وقد تبنت الشركة تلك الرؤية الوطنية لتصبح خطة عملها الخاصة لصنع مستقبل أفضل للإنسان في كل مكان.</p>
   </div>
   </div>      
 
  <div className= "MoreMobileContainer" id= "MoreMobileContainer" >
      <div className="MoreMobileBullet" id="MoreMobileBullet"onPointerEnter={() =>Go2MobileMore()}>
        <div className="MoreMobileText">المزيد</div>
    </div></div>  

</div>
          }
          {(!isMobile)&&
        <div className="container">
  
<div className="HandsContainer" id="HandsContainer">
<img className="HandsImg" id ="HandsImg" src={HandsImg}  alt=""></img>
</div>
            <div className=" col-1 " >
            <div className="DohaLogoContainer" id="DohaLogoContainer">
                <img className="DohaLogoImg" id ="DohaLogoImg" src={DohaLogoImg}  alt=""></img>
            </div>
            </div>
            <div className=" col-2 ">
            </div>
            <div className="col-3" >
            <div className="DohaLogoFirstContainer" id="DohaLogoFirstContainer">
                <img className="DohaLogoFirstImg" id ="DohaLogoFirstImg" src={DohaFirstLogoImg}  alt=""></img>
            </div>
            <div className="BallMain" id="BallMain">

</div>
            </div>
            <div className="col-4">
            <div className="RingsBackgroundContainer" id="RingsBackgroundContainer" >                      
               <img className="RingsBackgroundImg" id ="RingsBackgroundImg" src={RingsBackground}  alt=""></img>
            </div>
            </div>
            <div className="col-5" >

            </div>
            <div className="col-6">
            <div className="body" id="Body" >                      
               <img className="FrontBodyImg" id ="FrontBodyImg" src={bodyImage}  alt=""></img>
            </div>
           
            </div>
            <div className="col-7" >
            <div className="NewDiv">
            <p className="fixedText" id="fixedText">تعمل الدوحة لينك وفق إستراتيجية واضحة تستثمر في الأنسان، وتركز على جودة الحياة التي تؤثر على تنمية المجتمعات والدول. أنشأت وفق الرؤية الوطنية لدولة قطر 2030</p>
            <div className="Phargraphcontainer" id="Phargraphcontainer">
              <p className="typed11">، لدعم التعليم والثقافة والفنون والتكنولوجيا، وقد تبنت الشركة تلك الرؤية الوطنية لتصبح خطة عملها الخاصة لصنع مستقبل أفضل للإنسان في كل مكان.</p>
            </div>
            </div>
            </div>
            <div className="col-8 ">
            <div className="TalkImageContainer" id="TalkImageContainer" >
            <img className="WelcomeTalkImage"src={WelcomeTalk}  alt=""></img>
           
           </div>

            <div  className="ExploreButton" id="ExploreButton">
              <button className="btn" onClick={Go2Main3}>اكتشف اكثر</button>
            </div>
           
            </div>
            <div className="col-9" >
            <div className="TextWelcomContainer" id="TextWelcomContainer">
            <p>  نقدم أفضل الحلول</p> 
                   
            <p> لتلبية <span  style={{ color:" #005368"}}>أهدافك</span></p>
                  
                
</div>
<div className="hello" id="Text1">
  <div>
<p  className="Text1part"id="Text1part"> تعمل الدوحة لينك وفق إستراتيجية واضحة تستثمر في الأنسان، وتركز على جودة الحياة التي تؤثر على تنمية المجتمعات والدول. أنشأت وفق الرؤية الوطنية لدولة قطر 2030

</p>

</div>

</div>

  
            </div>
            <div className="col-10" >
            <div className="MoreContainer" id="MoreContainer" >
      <div className="MoreBullet" id="MoreBullet"onClick={() =>Go2More()}>
        <div className="MoreText">المزيد</div>
    </div></div>  
            </div>
            <div className="col-11">
           
            </div>
            <div className="col-12" >
          
            </div>
            
          
        </div>
}
</div>  
 
      );
   
  }
  
  export default MainPrec;