import React, { useState }  from "react";
import "./MainPrec.css";
import "./App.css";
import "./AboutDoha.css";
import AboutImg from './Images/About.png';
import BodyImg from './Images/forntbody.png';
import BodyWMoving from './Images/BodyWithMoveRight.png';
import AboutImgMobile from './Images/A111-01.jpg';

function AboutDoha(){

      const [bodyimage, setBodyimage] = useState( BodyImg);
      const[isMobile]=useState(window.innerWidth<window.innerHeight);

      function DelayFunction(){
            
            setTimeout(() => {
        
              var BodyRef =document.getElementById("bodyAbout");
              if(BodyRef !=null  )
              {
        
                  setBodyimage(BodyWMoving);
                  
              }
                 }, 1000);
                
          }

      return (
            <div className="AboutContainerAll">
                  {(isMobile)&&
               
             <div className="MobileOuterContainer">
                  <div className="AboutImgContainerMobile" id="AboutImgContainerMobile">
                  <img className="AboutImgMobile" id ="AboutImgMobile" src={AboutImgMobile}  alt=""></img>
                </div>
                <div className="WordsAboutMobile" id="WordsAboutMobile">
                    <p>عن الدوحة لينك</p> 
                      
            </div>
            <div className="AboutText1containerMobile" id="AboutText1containerMobile">
                     <p > تأسست شركة الدوحة لينك في عام ٢٠١٦م ، وهي شركة متخصصة في مجال خدمات تكنولوجيا المعلومات وإعداد وتطوير المناهج التعليمية والمحتوى الإلكتروني التفاعلي والاستشارات التدريبية بالاضافة الى التسويق الالكتروني وإدارة منصات التواصل الاجتماعي.

وهي شركة وطنية برأس مال وطني بنسبة 100%، تم إنشاؤها بناء على توجيهات سمو أمير البلاد المفدى الخاصة بإنشاء شركات وطنية لخدمة العملية التعليمية والنهوض بها وفق رؤية قطر 2030. الدوحة لينك شركة عضو في مجموعة ناس NAAAS القابضة. تضم المجموعة 12 شركة تدعم بعضها البعض في مختلف المجالات.   </p>
                  </div>
              </div>  
                 
                  }
      {(!isMobile)&&
        <div className="container">
 
               
            <div className=" col-1 " >
            <div className="AboutImgContainer" id="AboutImgContainer">
                      <img className="AboutImg" id ="AboutImg" src={AboutImg}  alt=""></img>
                </div>
                <div className="bodyAbout" id="bodyAbout" >                      
               <img className="bodyAboutImg" id ="bodyAboutImg" src={bodyimage}  alt=""></img>
            </div>
  </div>
            
            <div className=" col-2 ">
            </div>
            <div className="col-3" >
           
            </div>
            <div className="col-4">
           
            </div>
            <div className="col-5" > 
            <div className="AboutText1" id="AboutText1">
                  <div className="AboutText1container" id="AboutText1container">
                     <p className="typedAbout"> تأسست شركة الدوحة لينك في عام ٢٠١٦م ، وهي شركة متخصصة في مجال خدمات تكنولوجيا المعلومات وإعداد وتطوير المناهج التعليمية والمحتوى الإلكتروني التفاعلي والاستشارات التدريبية بالاضافة الى التسويق الالكتروني وإدارة منصات التواصل الاجتماعي.

وهي شركة وطنية برأس مال وطني بنسبة 100%، تم إنشاؤها بناء على توجيهات سمو أمير البلاد المفدى الخاصة بإنشاء شركات وطنية لخدمة العملية التعليمية والنهوض بها وفق رؤية قطر 2030. الدوحة لينك شركة عضو في مجموعة ناس NAAAS القابضة. تضم المجموعة 12 شركة تدعم بعضها البعض في مختلف المجالات.   </p>
                  </div>
            </div>
            </div>
            <div className="col-6">
           
            
            
           
            </div>
            <div className="col-7" >
            <div className="WordsAbout" id="WordsAbout">
                    <p>عن الدوحة لينك</p> 
                      
            </div>
            </div>
            <div className="col-8 ">
         
       
            </div>
            <div className="col-9" >
  
            </div>
            <div className="col-10" >
      
            </div>
            <div className="col-11">
           
            </div>
            <div className="col-12" >
          
            </div>
      
</div>  
 }
<DelayFunction/> 
</div>

      );
    }

  
  export default AboutDoha;