import React , { useState } from "react";
import "./Projects.css";
import "./MainPrec.css";
import ProjectBackgroundImg from './Images/Image 24 copy.png';
import VideoPoliceImg from './Images/PolicePic.png';
import ProjectsImgMobile2 from './Images/MobileProjectsBackground.png';

import AshghalVideo1 from './videos/Ash3al1.mp4';
import AshghalVideo2 from './videos/Ash3al2.mp4';
import AshghalVideo3 from './videos/Ash3al3.mp4';
import AshghalThumbnail1 from './Images/Ashghal1thumbnail.png';
import AshghalThumbnail2 from './Images/Ashghal2thumbnail.png';
import AshghalThumbnail3 from './Images/Ashghal3thumbnail.png';


import MunVideo1 from './videos/Infraction.mp4#t=15';
import MunVideo2 from './videos/Infraction2.mp4#t=15';
import MunVideo3 from './videos/Infraction3.mp4#t=15';
import MunVideo4 from './videos/Infraction4.mp4#t=15';
import MunVideo5 from './videos/Infraction5.mp4';
import MunVideo6 from './videos/Infraction6.mp4';
import Mun1Thumbnail from './Images/Mun1Thumbnail.png';
import Mun2Thumbnail from './Images/Mun2Thumbnail.png';
import Mun3Thumbnail from './Images/Mun3Thumbnail.png';
import Mun4Thumbnail from './Images/Mun4Thumbnail.png';
import Mun5Thumbnail from './Images/Mun5Thumbnail.png';
import Mun6Thumbnail from './Images/Mun6Thumbnail.png';

import AtfalnaVideo1 from './videos/AtfalnaVideo1.mp4';
import AtfalnaVideo2 from './videos/AtfalnaVideo2.mp4';
import AtfalnaVideo3 from './videos/AtfalnaVideo3.mp4';
import AtfalnaVideo4 from './videos/AtfalnaVideo4.mp4';
import AtfalnaVideo5 from './videos/AtfalnaVideo5.mp4';
import AtfalnaVideo6 from './videos/AtfalnaVideo6.mp4';
import AtfalnaThumbnail1 from './Images/AtfalnaThumbnail1.png';
import AtfalnaThumbnail2 from './Images/AtfalnaThumbnail2.png';
import AtfalnaThumbnail3 from './Images/AtfalnaThumbnail3.png';
import AtfalnaThumbnail4 from './Images/AtfalnaThumbnail4.png';
import AtfalnaThumbnail5 from './Images/AtfalnaThumbnail5.png';
import AtfalnaThumbnail6 from './Images/AtfalnaThumbnail6.png';

import CyberVideo1 from './videos/Cybervideo1.mp4';
import CyberVideo2 from './videos/Cybervideo2.mp4';
import CyberVideo3 from './videos/Cybervideo3.mp4';
import CyberVideo4 from './videos/Cybervideo4.mp4';
import CyberVideo5 from './videos/Cybervideo5.mp4';
import CyberThumbnail1 from './Images/CyberThumbnail1.png';
import CyberThumbnail2 from './Images/CyberThumbnail2.png';
import CyberThumbnail3 from './Images/CyberThumbnail3.png';
import CyberThumbnail4 from './Images/CyberThumbnail4.png';
import CyberThumbnail5 from './Images/CyberThumbnail5.png';

import JuticeVideo1 from './videos/JusticeVideo1.mp4';
import JuticeVideo2 from './videos/JusticeVideo2.mp4';
import JuticeVideo3 from './videos/JusticeVideo3.mp4';
import JuticeVideo4 from './videos/JusticeVideo4.mp4';
import JuticeVideo5 from './videos/JusticeVideo5.mp4';
import JuticeVideo6 from './videos/JusticeVideo6.mp4';
import JuticeVideo7 from './videos/JusticeVideo7.mp4';
import JuticeVideo8 from './videos/JusticeVideo8.mp4';
import Jutice1Thumbnail from './Images/JusticeThumbnsil1.png';
import Jutice2Thumbnail from './Images/JusticeThumbnsil2.png';
import Jutice3Thumbnail from './Images/JusticeThumbnsil3.png';
import Jutice4Thumbnail from './Images/JusticeThumbnsil4.png';
import Jutice5Thumbnail from './Images/JusticeThumbnsil5.png';
import Jutice6Thumbnail from './Images/JusticeThumbnsil6.png';
import Jutice7Thumbnail from './Images/JusticeThumbnsil7.png';
import Jutice8Thumbnail from './Images/JusticeThumbnsil8.png';

import EduVideo1 from './videos/MOEvideo1.mp4';
import EduVideo2 from './videos/MOEvideo2.mp4';
import EduVideo3 from './videos/MOEvideo3.mp4';
import EduVideo4 from './videos/MOEvideo4.mp4';
import EduThumbnail1 from './Images/MOE1thumbnail.png';
import EduThumbnail2 from './Images/MOE2thumbnail.png';
import EduThumbnail3 from './Images/MOE3thumbnail.png';
import EduThumbnail4 from './Images/MOE4thumbnail.png';

import QcbVideo1 from './videos/Qcbvideo1.mp4';
import QcbVideo2 from './videos/Qcbvideo2.mp4';
import QcbVideo3 from './videos/Qcbvideo3.mp4';
import QcbVideo4 from './videos/Qcbvideo4.mp4';
import QcbThumbnail1 from './Images/QcpThumbnsil1.png';
import QcbThumbnail2 from './Images/QcpThumbnsil2.png';
import QcbThumbnail3 from './Images/QcpThumbnsil3.png';
import QcbThumbnail4 from './Images/QcpThumbnsil4.png';

import Devloped1Video from './videos/geography1.mp4';
import Devloped2Video  from './videos/pressure (2).mp4';
import Devloped1Thumbnail from './Images/Developed1Thumbnail.png';
import Devloped2Thumbnail from './Images/Developed2Thumbnail.png';

import QatarCharity1Video from './videos/QatarCharity1.mp4';
import QatarCharity2Video  from './videos/QatarCharity2.mp4';
import QatarCharity3Video  from './videos/QatarCharity3.mp4';
import QatarCharity1Thumbnail from './Images/QatarChity1thumbnail.png';
import QatarCharity2Thumbnail from './Images/QatarCharity2thumbnail.png';
import QatarCharity3Thumbnail from './Images/QatarCharity3thumbnail.png';

import policeVideo from './videos/PoliceAcademy.mp4';
import ProjectsDohaLogo from './Images/ProjectsDohaLogo.png';
import PoliceProjectLogo from './Images/MobileVideo.png';
import MunProjectLogo from './Images/MunMobileIcon.png';
import DevLearnProjectLogo from './Images/devLearnMobile.png';
import AtfalnaProjectLogo from './Images/AtfalnaMobile.png';
import CyberProjectLogo from './Images/CyberMobile.png';
import JusticeProjectLogo from './Images/JusticeMobile.png';
import QatarCharityProjectLogo from './Images/QatarCharityMobile.png';
import AshghalProjectLogo from './Images/AshghalMobile.png';
import SoialProjectLogo from './Images/SoialMobile.png';
import QatarCenterProjectLogo from './Images/A123-01 (2).png';
function Projects({ isMobile }){
  const [flag, setFlag] = useState( 0);
  const [clickMun, setClickMun] = useState( 0);
  const [clickDev, setClickDev] = useState( 0);
  const [clickQatarCharity, setClickQatarCharity] = useState( 0);
  const [clickAshghal, setClickAshghal] = useState( 0);
  const [clickEdu, setClickEdu] = useState( 0);
  const [clickQcb, setClickQcb] = useState( 0);
  const [clickJustice, setClickJustice] = useState( 0);
  const [clickCyber, setClickCyber] = useState( 0);
  const [clickAtfalna, setClickAtfalna] = useState( 0);
  const [flagProjMobile,setFlagProjMobile]=useState( 0);

  function SwapVideosForAtfalna(){
    
    var VideosRef = document.getElementById("MainPic4");
    var VideosRef1= document.getElementById("Atfalnavideo11");
    var VideosRef2= document.getElementById("Atfalnavideo22");
    var VideosRef3= document.getElementById("Atfalnavideo33");
    var VideosRef4= document.getElementById("Atfalnavideo44");
    var VideosRef5= document.getElementById("Atfalnavideo55");
   
    switch(clickAtfalna){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef1.style.opacity  = 1;
        VideosRef1.style.visibility  = "visible";
        setClickAtfalna(1);
        break;
      case 1:
  
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickAtfalna(2);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef3.style.opacity  = 1;
      VideosRef3.style.visibility  = "visible";
      setClickAtfalna(3);
        break;
      case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef4.style.opacity  = 1;
      VideosRef4.style.visibility  = "visible";
      setClickAtfalna(4);
        break;
      case 4:
      VideosRef4.pause();
      VideosRef4.style.visibility  = "hidden";
      VideosRef5.style.opacity  = 1;
      VideosRef5.style.visibility  = "visible";
      setClickAtfalna(5);
        break;
      case 5:
        VideosRef5.pause();
        VideosRef5.style.visibility  = "hidden";
        VideosRef.style.opacity  = 1;
        VideosRef.style.visibility  = "visible";
        setClickAtfalna(0);
        break;
        default:
          break;
    }
  } 


  function SwapVideosForAtfalnaByminus(){
    
    var VideosRef = document.getElementById("MainPic4");
    var VideosRef1= document.getElementById("Atfalnavideo11");
    var VideosRef2= document.getElementById("Atfalnavideo22");
    var VideosRef3= document.getElementById("Atfalnavideo33");
    var VideosRef4= document.getElementById("Atfalnavideo44");
    var VideosRef5= document.getElementById("Atfalnavideo55");

    switch(clickAtfalna){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef5.style.opacity  = 1;
        VideosRef5.style.visibility  = "visible";
        setClickAtfalna(5);
        break;
  
      case 1:
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickAtfalna(0);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef1.style.opacity  = 1;
      VideosRef1.style.visibility  = "visible";
      setClickAtfalna(1);
        break;
      case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickAtfalna(2);
        break;
      case 4:
      VideosRef4.pause();
      VideosRef4.style.visibility  = "hidden";
      VideosRef3.style.opacity  = 1;
      VideosRef3.style.visibility  = "visible";
      setClickAtfalna(3);
        break;
      case 5:
        VideosRef5.pause();
        VideosRef5.style.visibility  = "hidden";
        VideosRef4.style.opacity  = 1;
        VideosRef4.style.visibility  = "visible";
        setClickAtfalna(4);
        break;

        default:
          break;
    }
  

  }
  function SwapVideosForCyberByminus(){
    
    var VideosRef = document.getElementById("MainPic5");
    var VideosRef1= document.getElementById("Cybervideo11");
    var VideosRef2= document.getElementById("Cybervideo22");
    var VideosRef3= document.getElementById("Cybervideo33");
    var VideosRef4= document.getElementById("Cybervideo44");
 
   
    switch(clickCyber){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef4.style.opacity  = 1;
        VideosRef4.style.visibility  = "visible";
        setClickCyber(4);
        break;
  
      case 1:
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickCyber(0);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef1.style.opacity  = 1;
      VideosRef1.style.visibility  = "visible";
      setClickCyber(1);
        break;
      case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickCyber(2);
        break;
      case 4:
      VideosRef4.pause();
      VideosRef4.style.visibility  = "hidden";
      VideosRef3.style.opacity  = 1;
      VideosRef3.style.visibility  = "visible";
      setClickCyber(3);
        break;
        default:
          break;
    }
  }
  function SwapVideosForCyber(){
    
    var VideosRef = document.getElementById("MainPic5");
    var VideosRef1= document.getElementById("Cybervideo11");
    var VideosRef2= document.getElementById("Cybervideo22");
    var VideosRef3= document.getElementById("Cybervideo33");
    var VideosRef4= document.getElementById("Cybervideo44");
    switch(clickJustice){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef1.style.opacity  = 1;
        VideosRef1.style.visibility  = "visible";
        setClickJustice(1);
        break;
      case 1:
  
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickJustice(2);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef3.style.opacity  = 1;
      VideosRef3.style.visibility  = "visible";
      setClickJustice(3);
        break;
      case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef4.style.opacity  = 1;
      VideosRef4.style.visibility  = "visible";
      setClickJustice(4);
        break;
      case 4:
      VideosRef4.pause();
      VideosRef4.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickJustice(0);
        break;
        default:
          break;
    }

  }

  function SwapVideosForJusticeByminus(){
    
    var VideosRef = document.getElementById("MainPic6");
    var VideosRef1= document.getElementById("Justicevideo11");
    var VideosRef2= document.getElementById("Justicevideo22");
    var VideosRef3= document.getElementById("Justicevideo33");
    var VideosRef4= document.getElementById("Justicevideo44");
    var VideosRef5= document.getElementById("Justicevideo55");
    var VideosRef6= document.getElementById("Justicevideo66");
    var VideosRef7= document.getElementById("Justicevideo77");
   
    switch(clickJustice){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef7.style.opacity  = 1;
        VideosRef7.style.visibility  = "visible";
        setClickJustice(7);
        break;
  
      case 1:
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickJustice(0);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef1.style.opacity  = 1;
      VideosRef1.style.visibility  = "visible";
      setClickJustice(1);
        break;
      case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickJustice(2);
        break;
      case 4:
      VideosRef4.pause();
      VideosRef4.style.visibility  = "hidden";
      VideosRef3.style.opacity  = 1;
      VideosRef3.style.visibility  = "visible";
      setClickJustice(3);
        break;
      case 5:
        VideosRef5.pause();
        VideosRef5.style.visibility  = "hidden";
        VideosRef4.style.opacity  = 1;
        VideosRef4.style.visibility  = "visible";
        setClickJustice(4);
        break;
    case 6:
      VideosRef6.pause();
      VideosRef6.style.visibility  = "hidden";
      VideosRef5.style.opacity  = 1;
      VideosRef5.style.visibility  = "visible";
      setClickJustice(5);
      break;
    case 7:
      VideosRef7.pause();
      VideosRef7.style.visibility  = "hidden";
      VideosRef6.style.opacity  = 1;
      VideosRef6.style.visibility  = "visible";
      setClickJustice(6);
      break;
      default:
        break;
    }
  }

  function SwapVideosForJustice(){
    
    var VideosRef = document.getElementById("MainPic6");
    var VideosRef1= document.getElementById("Justicevideo11");
    var VideosRef2= document.getElementById("Justicevideo22");
    var VideosRef3= document.getElementById("Justicevideo33");
    var VideosRef4= document.getElementById("Justicevideo44");
    var VideosRef5= document.getElementById("Justicevideo55");
    var VideosRef6= document.getElementById("Justicevideo66");
    var VideosRef7= document.getElementById("Justicevideo77");
    switch(clickJustice){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef1.style.opacity  = 1;
        VideosRef1.style.visibility  = "visible";
        setClickJustice(1);
        break;
      case 1:
  
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickJustice(2);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef3.style.opacity  = 1;
      VideosRef3.style.visibility  = "visible";
      setClickJustice(3);
        break;
      case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef4.style.opacity  = 1;
      VideosRef4.style.visibility  = "visible";
      setClickJustice(4);
        break;
      case 4:
      VideosRef4.pause();
      VideosRef4.style.visibility  = "hidden";
      VideosRef5.style.opacity  = 1;
      VideosRef5.style.visibility  = "visible";
      setClickJustice(5);
        break;
      case 5:
        VideosRef5.pause();
        VideosRef5.style.visibility  = "hidden";
        VideosRef6.style.opacity  = 1;
        VideosRef6.style.visibility  = "visible";
        setClickJustice(6);
        break;
      case 6:
        VideosRef6.pause();
        VideosRef6.style.visibility  = "hidden";
        VideosRef7.style.opacity  = 1;
        VideosRef7.style.visibility  = "visible";
        setClickJustice(7);
        break;
      case 7:
        VideosRef7.pause();
        VideosRef7.style.visibility  = "hidden";
        VideosRef.style.opacity  = 1;
        VideosRef.style.visibility  = "visible";
        setClickJustice(0);
        break;
        default:
        break;
    }

  }
  function SwapVideosForDeveloped(){
    
    var VideosRef =document.getElementById("MainPic3");
    var VideosRef1=document.getElementById("Developedvideo11");
  
    switch(clickDev){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef1.style.opacity  = 1;
        VideosRef1.style.visibility  = "visible";
        setClickDev(1);
        break;
      case 1:
  
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickDev(0);
      break;
      default:
        break;
    }

  }
  
  function SwapVideosForQcbByminus(){

    var VideosRef = document.getElementById("MainPic9");
    var VideosRef1= document.getElementById("Qcbvideo11");
    var VideosRef2= document.getElementById("Qcbvideo22");
    var VideosRef3= document.getElementById("Qcbvideo33");
    switch(clickQcb){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef3.style.opacity  = 1;
        VideosRef3.style.visibility  = "visible";
        setClickQcb(3);
        break;
  
      case 1:
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickQcb(0);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef1.style.opacity  = 1;
      VideosRef1.style.visibility  = "visible";
      setClickQcb(1);
        break;
     case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickQcb(2);
        break;
        default:
        break;
    }
  
    }
function SwapVideosForQcb(){

  var VideosRef = document.getElementById("MainPic9");
var VideosRef1= document.getElementById("Qcbvideo11");
var VideosRef2= document.getElementById("Qcbvideo22");
var VideosRef3= document.getElementById("Qcbvideo33");
switch(clickQcb){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef1.style.opacity  = 1;
        VideosRef1.style.visibility  = "visible";
        setClickQcb(1);
        break;
  
      case 1:
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickQcb(2);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef3.style.opacity  = 1;
      VideosRef3.style.visibility  = "visible";
      setClickQcb(3);
        break;
    case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickQcb(0);
        break;
        default:
        break;
    }
  
    }
  function SwapVideosForEduByminus(){

    var VideosRef = document.getElementById("MainPic9");
    var VideosRef1= document.getElementById("Eduvideo11");
    var VideosRef2= document.getElementById("Eduvideo22");
    var VideosRef3= document.getElementById("Eduvideo33");
    switch(clickEdu){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef3.style.opacity  = 1;
        VideosRef3.style.visibility  = "visible";
        setClickEdu(3);
        break;
  
      case 1:
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickEdu(0);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef1.style.opacity  = 1;
      VideosRef1.style.visibility  = "visible";
      setClickEdu(1);
        break;
     case 3:
      VideosRef3.pause();
      VideosRef3.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickEdu(2);
        break;
        default:
        break;
    }
  
    }
    function SwapVideosForEdu(){

    var VideosRef = document.getElementById("MainPic9");
    var VideosRef1= document.getElementById("Eduvideo11");
    var VideosRef2= document.getElementById("Eduvideo22");
    var VideosRef3= document.getElementById("Eduvideo33");

      switch(clickEdu){
        case 0:
          VideosRef.pause();
          VideosRef.style.visibility  = "hidden";
          VideosRef1.style.opacity  = 1;
          VideosRef1.style.visibility  = "visible";
          setClickEdu(1);
          break;
    
        case 1:
        VideosRef1.pause();
        VideosRef1.style.visibility  = "hidden";
        VideosRef2.style.opacity  = 1;
        VideosRef2.style.visibility  = "visible";
        setClickEdu(2);
        break;
        case 2:
        VideosRef2.pause();
        VideosRef2.style.visibility  = "hidden";
        VideosRef3.style.opacity  = 1;
        VideosRef3.style.visibility  = "visible";
        setClickEdu(3);
          break;
      case 3:
        VideosRef3.pause();
        VideosRef3.style.visibility  = "hidden";
        VideosRef.style.opacity  = 1;
        VideosRef.style.visibility  = "visible";
        setClickEdu(0);
          break;
          default:
        break;
      }
    
      }

  function SwapVideosForAshghalByminus(){

    var VideosRef = document.getElementById("MainPic8");
    var VideosRef1= document.getElementById("Ashghalvideo11");
    var VideosRef2= document.getElementById("Ashghalvideo22");
   
    switch(clickAshghal){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef2.style.opacity  = 1;
        VideosRef2.style.visibility  = "visible";
        setClickAshghal(2);
        break;
  
      case 1:
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickAshghal(0);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef1.style.opacity  = 1;
      VideosRef1.style.visibility  = "visible";
      setClickAshghal(1);
        break;
        default:
          break;
    }
  
    }
    function SwapVideosForAshghal(){

    var VideosRef = document.getElementById("MainPic8");
    var VideosRef1= document.getElementById("Ashghalvideo11");
    var VideosRef2= document.getElementById("Ashghalvideo22");
     
      switch(clickAshghal){
        case 0:
          VideosRef.pause();
          VideosRef.style.visibility  = "hidden";
          VideosRef1.style.opacity  = 1;
          VideosRef1.style.visibility  = "visible";
          setClickAshghal(1);
          break;
    
        case 1:
        VideosRef1.pause();
        VideosRef1.style.visibility  = "hidden";
        VideosRef2.style.opacity  = 1;
        VideosRef2.style.visibility  = "visible";
        setClickAshghal(2);
        break;
        case 2:
        VideosRef2.pause();
        VideosRef2.style.visibility  = "hidden";
        VideosRef.style.opacity  = 1;
        VideosRef.style.visibility  = "visible";
        setClickAshghal(0);
          break;
          default:
        break;
       
      }
    
      }
  function SwapVideosForQatarCharityByminus(){

  var VideosRef = document.getElementById("MainPic7");
  var VideosRef1= document.getElementById("QatarCharityvideo11");
  var VideosRef2= document.getElementById("QatarCharityvideo22");
 
  switch(clickQatarCharity){
    case 0:
      VideosRef.pause();
      VideosRef.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickQatarCharity(2);
      break;

    case 1:
    VideosRef1.pause();
    VideosRef1.style.visibility  = "hidden";
    VideosRef.style.opacity  = 1;
    VideosRef.style.visibility  = "visible";
    setClickQatarCharity(0);
    break;
    case 2:
    VideosRef2.pause();
    VideosRef2.style.visibility  = "hidden";
    VideosRef1.style.opacity  = 1;
    VideosRef1.style.visibility  = "visible";
    setClickQatarCharity(1);
      break;
      default:
        break;
  }

  }
  function SwapVideosForQatarCharity(){

    var VideosRef = document.getElementById("MainPic7");
    var VideosRef1= document.getElementById("QatarCharityvideo11");
    var VideosRef2= document.getElementById("QatarCharityvideo22");
   
    switch(clickQatarCharity){
      case 0:
        VideosRef.pause();
        VideosRef.style.visibility  = "hidden";
        VideosRef1.style.opacity  = 1;
        VideosRef1.style.visibility  = "visible";
        setClickQatarCharity(1);
        break;
  
      case 1:
      VideosRef1.pause();
      VideosRef1.style.visibility  = "hidden";
      VideosRef2.style.opacity  = 1;
      VideosRef2.style.visibility  = "visible";
      setClickQatarCharity(2);
      break;
      case 2:
      VideosRef2.pause();
      VideosRef2.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickQatarCharity(0);
        break;
        default:
          break;
    }
  
    }
  function SwapVideosForMun(){
    
  var VideosRef =document.getElementById("MainPic2");
  var VideosRef1=document.getElementById("Munvideo11");
  var VideosRef2=document.getElementById("Munvideo22");
  var VideosRef3=document.getElementById("Munvideo33");
  var VideosRef4=document.getElementById("Munvideo44");
  var VideosRef5=document.getElementById("Munvideo55");
  //var SourceRef =document.getElementById("MunVideos");
  console.log("arrow clicked");
  console.log(clickMun);
  switch(clickMun){
    case 0:
      VideosRef.pause();
      VideosRef.style.visibility  = "hidden";
      VideosRef1.style.opacity  = 1;
      VideosRef1.style.visibility  = "visible";
      setClickMun(1);
      break;
    case 1:

    VideosRef1.pause();
    VideosRef1.style.visibility  = "hidden";
    VideosRef2.style.opacity  = 1;
    VideosRef2.style.visibility  = "visible";
    setClickMun(2);
    break;
    case 2:
    VideosRef2.pause();
    VideosRef2.style.visibility  = "hidden";
    VideosRef3.style.opacity  = 1;
    VideosRef3.style.visibility  = "visible";
    setClickMun(3);
      break;
    case 3:
    VideosRef3.pause();
    VideosRef3.style.visibility  = "hidden";
    VideosRef4.style.opacity  = 1;
    VideosRef4.style.visibility  = "visible";
    setClickMun(4);
      break;
    case 4:
    VideosRef4.pause();
    VideosRef4.style.visibility  = "hidden";
    VideosRef5.style.opacity  = 1;
    VideosRef5.style.visibility  = "visible";
    setClickMun(5);
      break;
    case 5:
      VideosRef5.pause();
      VideosRef5.style.visibility  = "hidden";
      VideosRef.style.opacity  = 1;
      VideosRef.style.visibility  = "visible";
      setClickMun(0);
      break;
      default:
        break;
  }

  console.log("arrow clicked");
  console.log(clickMun);
}
  
function SwapVideosForMunByminus(){
    
  var VideosRef = document.getElementById("MainPic2");
  var VideosRef1= document.getElementById("Munvideo11");
  var VideosRef2= document.getElementById("Munvideo22");
  var VideosRef3= document.getElementById("Munvideo33");
  var VideosRef4= document.getElementById("Munvideo44");
  var VideosRef5= document.getElementById("Munvideo55");
  //var SourceRef =document.getElementById("MunVideos");
  console.log("arrow clicked");
  console.log(clickMun);
  switch(clickMun){
    case 0:
      VideosRef.pause();
      VideosRef.style.visibility  = "hidden";
      VideosRef5.style.opacity  = 1;
      VideosRef5.style.visibility  = "visible";
      setClickMun(5);
      break;

    case 1:
    VideosRef1.pause();
    VideosRef1.style.visibility  = "hidden";
    VideosRef.style.opacity  = 1;
    VideosRef.style.visibility  = "visible";
    setClickMun(0);
    break;
    case 2:
    VideosRef2.pause();
    VideosRef2.style.visibility  = "hidden";
    VideosRef1.style.opacity  = 1;
    VideosRef1.style.visibility  = "visible";
    setClickMun(1);
      break;
    case 3:
    VideosRef3.pause();
    VideosRef3.style.visibility  = "hidden";
    VideosRef2.style.opacity  = 1;
    VideosRef2.style.visibility  = "visible";
    setClickMun(2);
      break;
    case 4:
    VideosRef4.pause();
    VideosRef4.style.visibility  = "hidden";
    VideosRef3.style.opacity  = 1;
    VideosRef3.style.visibility  = "visible";
    setClickMun(3);
      break;
    case 5:
      VideosRef5.pause();
      VideosRef5.style.visibility  = "hidden";
      VideosRef4.style.opacity  = 1;
      VideosRef4.style.visibility  = "visible";
      setClickMun(4);
      break;
      default:
        break;
  }

  console.log("arrow clicked");
  console.log(clickMun);
}
  
  function goToPoliceVideos ()
  {
    var Icon1ContainerRef=document.getElementById("VideoContainer");
    
    if(window.getComputedStyle(Icon1ContainerRef).getPropertyValue("opacity") !==0)
    {
    setFlag(1);
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";
    

    var MainRef          =document.getElementById("MainPicForProject1");
    var MainPicRef       =document.getElementById("MainPic1");
  
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.animation =  "PoliceVideoAnimation 1s linear forwards 1"; 
    Icon2ContainerRef.style.visibility =  "hidden"; 
    Icon3ContainerRef.style.visibility =  "hidden"; 
    Icon4ContainerRef.style.visibility =  "hidden"; 
    Icon5ContainerRef.style.visibility =  "hidden"; 
    Icon6ContainerRef.style.visibility =  "hidden"; 
    Icon7ContainerRef.style.visibility =  "hidden"; 
    Icon8ContainerRef.style.visibility =  "hidden"; 
    Icon9ContainerRef.style.visibility =  "hidden";  
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef   =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef       =document.getElementById("PoliceVideos");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef       =document.getElementById("LawAwareness");
    var CyberTrainingRef      =document.getElementById("CyberTraining");
    var atfalnaAppRef         =document.getElementById("atfalnaApp");
    var QatarCenterCampRef    =document.getElementById("QatarCenterCamp");
    var SoialCampRef          =document.getElementById("SoialCamp");
    var awarnessCampRef       =document.getElementById("awarnessCamp");

    var PoliceVideosTextRef          =document.getElementById("PoliceVideosText");
    var PoliceVideosPhargraphRef     =document.getElementById("PoliceVideosPhargraph");
    PoliceVideosTextRef.style.animation       = "PoliceVideoTextAnimation 1s linear forwards 1"; 
    PoliceVideosPhargraphRef.style.animation  = "PoliceVideoText2Animation 1.5s linear forwards 1"; 
    //PoliceVideosPhargraphRef.style.visibility = "visible" ;

    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 

    PoliceVideosRef.style.animation     = "RoundVideoContainerAnimation 1s linear forwards 1"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 
   

    setTimeout(() => {
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
  
    }, 1000);
  }
  }
  function goToPoliceMobile ()
  {
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    
    if(window.getComputedStyle(Icon1ContainerRef).getPropertyValue("opacity") !==0)
    {
    setFlagProjMobile(1);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var PoliceLogoRef              =document.getElementById("PoliceProjectLogo");
    var MainTextRef                =document.getElementById("MainText1");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    var PoliceTextRef   =document.getElementById("PoliceTextMobile");
    
    var MainRef          =document.getElementById("MainPicForProject1");
    var MainPicRef       =document.getElementById("MainPic1");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility =  "hidden";  
    Icon2ContainerRef.style.visibility =  "hidden";  
    Icon3ContainerRef.style.visibility =  "hidden"; 
    Icon4ContainerRef.style.visibility =  "hidden"; 
    Icon5ContainerRef.style.visibility =  "hidden";  
    Icon6ContainerRef.style.visibility =  "hidden"; 
    Icon7ContainerRef.style.visibility =  "hidden"; 
    Icon8ContainerRef.style.visibility =  "hidden"; 
    Icon9ContainerRef.style.visibility =  "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
   // PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility =  "hidden"; 
    MunpilcyMinistryRef.style.visibility =  "hidden"; 
    PoliceVideosRef.style.visibility =  "hidden"; 
    QatarCharityVideoRef.style.visibility =  "hidden"; 
    LawAwarenessRef.style.visibility =  "hidden"; 
    CyberTrainingRef.style.visibility =  "hidden"; 
    atfalnaAppRef.style.visibility =  "hidden"; 
    QatarCenterCampRef.style.visibility =  "hidden"; 
    SoialCampRef.style.visibility =  "hidden"; 
    awarnessCampRef.style.visibility =  "hidden"; 
    ProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation =  "ProjectTextAnimationMobile 1s linear forwards 1"; 
    PoliceLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
      PoliceTextRef.style.display="block";
      MainTextRef.style.display="block";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
  
  } 
  }
  function goToMunplicyMinistryPro(){
   
    var Icon2ContainerRef=document.getElementById("AwareContainer");

    if(window.getComputedStyle(Icon2ContainerRef).getPropertyValue("opacity") !==0)
{ 
    setFlag(2);
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";


    var MainRef       =document.getElementById("MainPicForProject2");
   
    var MainPicRef       =document.getElementById("MainPic2");

    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.animation = "MunciplityAnimation 1s linear forwards 1"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    var MunpilcyMinistryTextRef          =document.getElementById("MunpilcyMinistryText");
    var MunpilcyMinistryPhargraphRef         =document.getElementById("MunpilcyMinistryPhargraph");
    MunpilcyMinistryTextRef.style.animation  = "MunTextAnimation 1s linear forwards 1"; 
    MunpilcyMinistryPhargraphRef.style.animation  = "MunText2Animation 1.5s linear forwards 1"; 

    var DevlopAppsRef         =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef   =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef       =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef  =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef       =document.getElementById("LawAwareness");
    var CyberTrainingRef      =document.getElementById("CyberTraining");
    var atfalnaAppRef         =document.getElementById("atfalnaApp");
    var QatarCenterCampRef    =document.getElementById("QatarCenterCamp");
    var SoialCampRef          =document.getElementById("SoialCamp");
    var awarnessCampRef       =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility = "hidden";  
    MunpilcyMinistryRef.style.pointerEvents    = "none";
    MunpilcyMinistryRef.style.animation  = "RoundMunAwareContainerAnimation 1s linear forwards 1"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 

    setTimeout(() => {
      MainRef.style.display            ="block";
      MainPicRef.style.animation      = "ImageOpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               =document.getElementById("smallArrowR");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               =document.getElementById("smallArrowL");
      SmallArrowLRef.style.visibility  = "visible";
      SmallArrowLRef.addEventListener("onClick", SwapVideosForMun);
      SmallArrowRRef.addEventListener("onClick", SwapVideosForMunByminus);
      console.log("hellllo");
    }, 1000);
  }
  }
  function goToMunMobile ()
  {
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    console.log("mun id")
    console.log(window.getComputedStyle(Icon2ContainerRef).getPropertyValue("opacity"))
    if(window.getComputedStyle(Icon2ContainerRef).getPropertyValue("opacity") !==0)
{ 
    setFlagProjMobile(2);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var MunProjectLogoRef              =document.getElementById("MunProjectLogo");
    var MainTextRef                =document.getElementById("MainText2");
    var smallArrowRRef                =document.getElementById("smallArrowRMunMobile");
    var smallArrowLRef                =document.getElementById("smallArrowLMunMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    var MunTextRef   =document.getElementById("MunTextMobile");
    
    var MainRef          =document.getElementById("MainPicForProject2");
    var MainPicRef       =document.getElementById("MainPic2");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility =  "hidden"; 
    Icon2ContainerRef.style.visibility =  "hidden"; 
    Icon3ContainerRef.style.visibility =  "hidden"; 
    Icon4ContainerRef.style.visibility =  "hidden"; 
    Icon5ContainerRef.style.visibility =  "hidden"; 
    Icon6ContainerRef.style.visibility =  "hidden"; 
    Icon7ContainerRef.style.visibility =  "hidden"; 
    Icon8ContainerRef.style.visibility =  "hidden"; 
    Icon9ContainerRef.style.visibility =  "hidden"; 
    Icon10ContainerRef.style.visibility=  "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility =  "hidden"; 
    MunpilcyMinistryRef.style.visibility =  "hidden"; 
    PoliceVideosRef.style.visibility =  "hidden"; 
    QatarCharityVideoRef.style.visibility =  "hidden"; 
    LawAwarenessRef.style.visibility =  "hidden"; 
    CyberTrainingRef.style.visibility =  "hidden";  
    atfalnaAppRef.style.visibility =  "hidden"; 
    QatarCenterCampRef.style.visibility =  "hidden"; 
    SoialCampRef.style.visibility =  "hidden"; 
    awarnessCampRef.style.visibility =  "hidden"; 
    ProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation =  "ProjectTextAnimationMobile 1s linear forwards 1"; 
    MunProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
   
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
  
}
  }
  function goToDepLearnPro(){
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");

    if(window.getComputedStyle(Icon3ContainerRef).getPropertyValue("opacity") !==0)
    {
    setFlag(3);
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";


    var MainRef       =document.getElementById("MainPicForProject3");
    var MainPicRef       =document.getElementById("MainPic3");

    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.animation = "DevlopedLearnAnimation 1s linear forwards 1"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden";  
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 

    var DevelopedAppTextRef                    =document.getElementById("DevelopedAppText");
    var DevelopedAppPhargraphRef               =document.getElementById("DevelopedAppPharaghap");
    DevelopedAppTextRef.style.animation       = "DevelopAppsTextAnimation 1s linear forwards 1"; 
    DevelopedAppPhargraphRef.style.animation  = "DevelopAppsText2Animation 1.5s linear forwards 1"; 
  
    var DevlopAppsRef         =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef   =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef       =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef  =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef       =document.getElementById("LawAwareness");
    var CyberTrainingRef      =document.getElementById("CyberTraining");
    var atfalnaAppRef         =document.getElementById("atfalnaApp");
    var QatarCenterCampRef    =document.getElementById("QatarCenterCamp");
    var SoialCampRef          =document.getElementById("SoialCamp");
    var awarnessCampRef       =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.pointerEvents    = "none";
    DevlopAppsRef.style.animation        = "RoundDevlopAppsContainerAnimation 1s linear forwards 1"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden";  

    setTimeout(() => { 

      MainRef.style.display            ="block";
      MainRef.style.animation         = "OpacityAnimation 1s linear forwards 1"; 
      MainPicRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               =document.getElementById("smallArrowRDeveloped");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               =document.getElementById("smallArrowLDeveloped");
      SmallArrowLRef.style.visibility  = "visible";
   
    }, 1000);}
  }
  function goToDevLearnMobile ()
  {
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    if(window.getComputedStyle(Icon3ContainerRef).getPropertyValue("opacity") !==0)
    {
    setFlagProjMobile(3);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var DevLearnProjectLogoRef          =document.getElementById("DevLearnProjectLogo");
    var MainTextRef                =document.getElementById("MainText3");
    var smallArrowRRef             =document.getElementById("smallArrowRDevelopedMobile");
    var smallArrowLRef             =document.getElementById("smallArrowLDevelopedMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    var MunTextRef   =document.getElementById("DevLearnTextMobile");
    
    var MainRef          =document.getElementById("MainPicForProject3");
    var MainPicRef       =document.getElementById("MainPic3");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility =  "hidden"; 
    Icon2ContainerRef.style.visibility =  "hidden"; 
    Icon3ContainerRef.style.visibility =  "hidden"; 
    Icon4ContainerRef.style.visibility =  "hidden"; 
    Icon5ContainerRef.style.visibility =  "hidden"; 
    Icon6ContainerRef.style.visibility =  "hidden";  
    Icon7ContainerRef.style.visibility =  "hidden"; 
    Icon8ContainerRef.style.visibility =  "hidden"; 
    Icon9ContainerRef.style.visibility =  "hidden"; 
    Icon10ContainerRef.style.visibility =  "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility =  "hidden"; 
    MunpilcyMinistryRef.style.visibility =  "hidden"; 
    PoliceVideosRef.style.visibility =  "hidden"; 
    QatarCharityVideoRef.style.visibility =  "hidden"; 
    LawAwarenessRef.style.visibility =  "hidden"; 
    CyberTrainingRef.style.visibility =  "hidden"; 
    atfalnaAppRef.style.visibility =  "hidden"; 
    QatarCenterCampRef.style.visibility =  "hidden"; 
    SoialCampRef.style.visibility =  "hidden"; 
    awarnessCampRef.style.visibility =  "hidden"; 
    ProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation =  "ProjectTextAnimationMobile 1s linear forwards 1"; 
    DevLearnProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
   
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
    }
   
  }
  function goAtfalnaPro(){

    var Icon4ContainerRef =document.getElementById("AtfalanaContainer");
    if(window.getComputedStyle(Icon4ContainerRef).getPropertyValue("opacity") !==0)

{
    setFlag(4);

    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";

    var MainRef       =document.getElementById("MainPicForProject4");

    var MainPicRef       =document.getElementById("MainPic4");

    var Icon1ContainerRef =document.getElementById("VideoContainer");
    var Icon2ContainerRef =document.getElementById("AwareContainer");
    var Icon3ContainerRef =document.getElementById("DevelopedContainer");
    var Icon5ContainerRef =document.getElementById("FaienessContainer");
    var Icon6ContainerRef =document.getElementById("CyberContainer");
    var Icon7ContainerRef =document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef =document.getElementById("PR1Container");
    var Icon9ContainerRef =document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.animation  = "AtfalnaAnimation 1s linear forwards 1"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden";  
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    var atfalnaAppTextRef                   =document.getElementById("atfalnaAppText");
    var atfalnaAppPharagphRef               =document.getElementById("atfalnaAppPharagph");
    atfalnaAppTextRef.style.animation      = "MunTextAnimation 1s linear forwards 1"; 
    atfalnaAppPharagphRef.style.animation  = "MunText2Animation 1.5s linear forwards 1"; 


    var DevlopAppsRef             =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef       =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef           =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef      =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef           =document.getElementById("LawAwareness");
    var CyberTrainingRef          =document.getElementById("CyberTraining");
    var atfalnaAppRef             =document.getElementById("atfalnaApp");
    var QatarCenterCampRef        =document.getElementById("QatarCenterCamp");
    var SoialCampRef              =document.getElementById("SoialCamp");
    var awarnessCampRef           =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.pointerEvents    = "none";
    atfalnaAppRef.style.animation        = "RoundAtfalnaContainerAnimation 1s linear forwards 1"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden";  

    setTimeout(() => {
      MainRef.style.display="block";
      MainRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               =document.getElementById("smallArrowRAtfalna");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               =document.getElementById("smallArrowLAtfalna");
      SmallArrowLRef.style.visibility  = "visible";
       
    }, 1000);}
  }
  function goToAtfalnaMobile ()
  {
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    if(window.getComputedStyle(Icon4ContainerRef).getPropertyValue("opacity") !==0){
    setFlagProjMobile(4);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var AtfalnaProjectLogoRef     =document.getElementById("AtfalnaProjectLogo");
    
    var MainTextRef                =document.getElementById("MainText7");
    var smallArrowRRef             =document.getElementById("smallArrowRAtfalnaMobile");
    var smallArrowLRef             =document.getElementById("smallArrowLAtfalnaMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    var MunTextRef   =document.getElementById("AtfalnaTextMobile");
    
    var MainRef          =document.getElementById("MainPicForProject4");
    var MainPicRef       =document.getElementById("MainPic4");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 
    ProjectLogoRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation      = "ProjectTextAnimationMobile 1s linear forwards 1"; 
    AtfalnaProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
  
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
    }
    
  }
  function goCyberPro(){
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    if(window.getComputedStyle(Icon5ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlag(5);
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";

    var MainRef          =document.getElementById("MainPicForProject5");
    var MainPicRef       =document.getElementById("MainPic5");

    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden";  
    Icon6ContainerRef.style.animation  = "CyberAnimation 1s linear forwards 1"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
  
    var CyberTrainingTextRef                   = document.getElementById("CyberTrainingText");
    var CyberTrainingPharagphRef               = document.getElementById("CyberTrainingPharagph");
    CyberTrainingTextRef.style.animation      = "CyberTextAnimation 1s linear forwards 1"; 
    CyberTrainingPharagphRef.style.animation  = "CyberText2Animation 1s linear forwards 1"; 



    var DevlopAppsRef         =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef   =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef       =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef  =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef       =document.getElementById("LawAwareness");
    var CyberTrainingRef      =document.getElementById("CyberTraining");
    var atfalnaAppRef         =document.getElementById("atfalnaApp");
    var QatarCenterCampRef    =document.getElementById("QatarCenterCamp");
    var SoialCampRef          =document.getElementById("SoialCamp");
    var awarnessCampRef       =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden";  
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.pointerEvents="none";
    CyberTrainingRef.style.animation     = "RoundCyberContainerAnimation 1s linear forwards 1"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden";  
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 

    setTimeout(() => {
      MainRef.style.display            = "block";
      MainRef.style.animation         = "OpacityAnimation 1s linear forwards 1"; 
      MainPicRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               = document.getElementById("smallArrowRCyber");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               = document.getElementById("smallArrowLCyber");
      SmallArrowLRef.style.visibility  = "visible";
    }, 1000);}
  }
  function goToCyberMobile ()
  {
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    if(window.getComputedStyle(Icon6ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlagProjMobile(5);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var CyberProjectLogoRef      =document.getElementById("CyberProjectLogo");
    var MainTextRef                =document.getElementById("MainText4");
    
    var smallArrowRRef             =document.getElementById("smallArrowRCyberMobile");
    var smallArrowLRef             =document.getElementById("smallArrowLCyberMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    var MunTextRef   =document.getElementById("CyberTextMobile");
    
    var MainRef          =document.getElementById("MainPicForProject5");
    var MainPicRef       =document.getElementById("MainPic5");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden";  
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 
    ProjectLogoRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation      = "ProjectTextAnimationMobile 1s linear forwards 1"; 
    CyberProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
   
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
}
 
  }
  function goFairnessPro(){
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    if(window.getComputedStyle(Icon6ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlag(6);
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";

    var MainRef          =document.getElementById("MainPicForProject6");
    var MainPicRef       =document.getElementById("MainPic6");

    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.animation  = "FairnessAnimation 1s linear forwards 1"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden";  
    Icon10ContainerRef.style.visibility= "hidden"; 
  
    var LawAwarenessTextRef                   =document.getElementById("LawAwarenessText");
    var LawAwarenessPhargaphRef               =document.getElementById("LawAwarenessPhargaph");
    LawAwarenessTextRef.style.animation      = "JusticTextAnimation 1s linear forwards 1"; 
    LawAwarenessPhargaphRef.style.animation  = "CyberTrainingText2Animation 1s linear forwards 1"; 


    var DevlopAppsRef             =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef       =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef           =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef      =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef           =document.getElementById("LawAwareness");
    var CyberTrainingRef          =document.getElementById("CyberTraining");
    var atfalnaAppRef             =document.getElementById("atfalnaApp");
    var QatarCenterCampRef        =document.getElementById("QatarCenterCamp");
    var SoialCampRef              =document.getElementById("SoialCamp");
    var awarnessCampRef           =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.pointerEvents="none";
    LawAwarenessRef.style.animation      = "RoundFairnessContainerAnimation 1s linear forwards 1"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 

    setTimeout(() => {

      MainRef.style.display="block";
      MainRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               =document.getElementById("smallArrowRJustice");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               =document.getElementById("smallArrowLJustice");
      SmallArrowLRef.style.visibility  = "visible";
      }, 1000);}
  }
  function goToJusticeMobile ()
  {
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    if(window.getComputedStyle(Icon5ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlagProjMobile(6);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var JusticeProjectLogoRef      =document.getElementById("JusticeProjectLogo");
    var MainTextRef                =document.getElementById("MainText8");
    var MunTextRef   =document.getElementById("JusticeTextMobile");

    var smallArrowRRef             =document.getElementById("smallArrowRJusticeMobile");
    var smallArrowLRef             =document.getElementById("smallArrowLJusticeMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    
    var MainRef          =document.getElementById("MainPicForProject6");
    var MainPicRef       =document.getElementById("MainPic6");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 
    ProjectLogoRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation      = "ProjectTextAnimationMobile 1s linear forwards 1"; 
    JusticeProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
   
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
  
}   
  }
  function goQatarCharityPro(){
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    if(window.getComputedStyle(Icon7ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlag(7);
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s ease forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";

    var MainRef          =document.getElementById("MainPicForProject7");
    var MainPicRef       =document.getElementById("MainPic7");

    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.animation = "QatarCharityAnimation 1s linear forwards 1"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 

    var QatarCharityVideoTextRef                   =document.getElementById("QatarCharityVideoText");
    var QatarCharityVideoPhargaphRef               =document.getElementById("QatarCharityVideoPhargraph");
    QatarCharityVideoTextRef.style.animation      = "CyberTrainingTextAnimation 1s linear forwards 1"; 
    QatarCharityVideoPhargaphRef.style.animation  = "CyberTrainingText2Animation 1s linear forwards 1"; 



    var DevlopAppsRef             =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef       =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef           =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef      =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef           =document.getElementById("LawAwareness");
    var CyberTrainingRef          =document.getElementById("CyberTraining");
    var atfalnaAppRef             =document.getElementById("atfalnaApp");
    var QatarCenterCampRef        =document.getElementById("QatarCenterCamp");
    var SoialCampRef              =document.getElementById("SoialCamp");
    var awarnessCampRef           =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.pointerEvents="none";
    QatarCharityVideoRef.style.animation = "RoundQatarCharityContainerAnimation 1s linear forwards 1"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden";  
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 

    setTimeout(() => {

      MainRef.style.display            ="block";
      MainRef.style.animation          = "OpacityAnimation 1s linear forwards 1"; 
      MainPicRef.style.animation       = "OpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               =document.getElementById("smallArrowRQatarCharity");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               =document.getElementById("smallArrowLQatarCharity");
      SmallArrowLRef.style.visibility  = "visible";
    }, 1000);}
  }

  function goToQatarCharityMobile ()
  {
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    if(window.getComputedStyle(Icon7ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlagProjMobile(7);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var QatarCharityProjectLogoRef      =document.getElementById("QatarCharityProjectLogo");
    var MainTextRef                =document.getElementById("MainText5");
    var MunTextRef   =document.getElementById("QatarCharityTextMobile");
    var smallArrowRRef             =document.getElementById("smallArrowRQatarCharityMobile");
    var smallArrowLRef             =document.getElementById("smallArrowLQatarCharityMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    
    
    var MainRef          =document.getElementById("MainPicForProject7");
    var MainPicRef       =document.getElementById("MainPic7");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden";  
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 
    ProjectLogoRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation      = "ProjectTextAnimationMobile 1s linear forwards 1"; 
    QatarCharityProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
    
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
} 
   
  }
  function goPRashghalPro(){
    var Icon8ContainerRef=document.getElementById("PR1Container");
    if(window.getComputedStyle(Icon8ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlag(8);
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";

    var MainRef          =document.getElementById("MainPicForProject8");
    var MainPicRef       =document.getElementById("MainPic8");

    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility = "hidden";  
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.animation = "PR1Animation 1s linear forwards 1"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    var awarnessCampTextRef                   =document.getElementById("awarnessCampText");
    var awarnessCampPharagaphRef               =document.getElementById("awarnessCampPharagaph");
    awarnessCampTextRef.style.animation      = "CyberTrainingTextAnimation 1s linear forwards 1"; 
    awarnessCampPharagaphRef.style.animation  = "CyberTrainingText2Animation 1s linear forwards 1"; 



    var DevlopAppsRef         =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef   =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef       =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef  =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef       =document.getElementById("LawAwareness");
    var CyberTrainingRef      =document.getElementById("CyberTraining");
    var atfalnaAppRef         =document.getElementById("atfalnaApp");
    var QatarCenterCampRef    =document.getElementById("QatarCenterCamp");
    var SoialCampRef          =document.getElementById("SoialCamp");
    var awarnessCampRef       =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden";  
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.pointerEvents    = "none";
    awarnessCampRef.style.animation      = "RoundPR1ContainerAnimation 1s linear forwards 1"; 

    setTimeout(() => {

      MainRef.style.display="block";
      MainRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               =document.getElementById("smallArrowRAshghal");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               =document.getElementById("smallArrowLAshghal");
      SmallArrowLRef.style.visibility  = "visible";
      
    }, 1000);}
  }
  function goToAshghalMobile ()
  {
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    if(window.getComputedStyle(Icon8ContainerRef).getPropertyValue("opacity") !==0)
{ 
    setFlagProjMobile(8);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var AshghalProjectLogoRef      =document.getElementById("AshghalProjectLogo");
    var MainTextRef                =document.getElementById("MainText9");
    var MunTextRef   =document.getElementById("AshghalTextMobile");

    var smallArrowRRef             =document.getElementById("smallArrowRAshghalMobile");
    var smallArrowLRef             =document.getElementById("smallArrowLAshghalMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    
    var MainRef          =document.getElementById("MainPicForProject8");
    var MainPicRef       =document.getElementById("MainPic8");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden";  
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 
    ProjectLogoRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation      = "ProjectTextAnimationMobile 1s linear forwards 1"; 
    AshghalProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
    
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
  
} 
  }
  function goSoialPro(){
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    if(window.getComputedStyle(Icon9ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlag(9);
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";

    var MainRef          =document.getElementById("MainPicForProject9");
    var MainPicRef       =document.getElementById("MainPic9");

    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.animation = "SoialAnimation 1s linear forwards 1"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    var SoialCampTextRef                   =document.getElementById("SoialCampText");
    var SoialCamppharagraphRef               =document.getElementById("SoialCamppharagraph");
    SoialCampTextRef.style.animation      = "soialTextAnimation 1s linear forwards 1"; 
    SoialCamppharagraphRef.style.animation  = "SoialText2Animation 1s linear forwards 1"; 



    var DevlopAppsRef         =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef   =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef       =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef  =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef       =document.getElementById("LawAwareness");
    var CyberTrainingRef      =document.getElementById("CyberTraining");
    var atfalnaAppRef         =document.getElementById("atfalnaApp");
    var QatarCenterCampRef    =document.getElementById("QatarCenterCamp");
    var SoialCampRef          =document.getElementById("SoialCamp");
    var awarnessCampRef       =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden";  
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden";  
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.pointerEvents      = "none";
    SoialCampRef.style.animation          = "RoundSoialContainerAnimation 1s linear forwards 1"; 
    awarnessCampRef.style.visibility = "hidden"; 

    setTimeout(() => {

      MainRef.style.display="block";
      MainRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               =document.getElementById("smallArrowREdu");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               =document.getElementById("smallArrowLEdu");
      SmallArrowLRef.style.visibility  = "visible";
     
    }, 1000);}
  }
  function goToSoialMobile ()
  {
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    if(window.getComputedStyle(Icon9ContainerRef).getPropertyValue("opacity") !==0)
{

    setFlagProjMobile(9);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var SoialProjectLogoRef        =document.getElementById("SoialProjectLogo");
    var MainTextRef                =document.getElementById("MainText6");
    var MunTextRef   =document.getElementById("SoialTextMobile");

    var smallArrowRRef             =document.getElementById("smallArrowREduMobile");
    var smallArrowLRef             =document.getElementById("smallArrowLEduMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    
    var MainRef          =document.getElementById("MainPicForProject9");
    var MainPicRef       =document.getElementById("MainPic9");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 
    ProjectLogoRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation      = "ProjectTextAnimationMobile 1s linear forwards 1"; 
    SoialProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
    
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
  
}
  }
  function goPRQatarPro(){
    var Icon10ContainerRef=document.getElementById("PR2Container");
    if(window.getComputedStyle(Icon10ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlag(10);
     var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ProjectTextAnimation 0.2s linear forwards 1"; 
    
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "visible";

    var MainRef          =document.getElementById("MainPicForProject10");
    var MainPicRef       =document.getElementById("MainPic10");

    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("FaienessContainer");
    var Icon6ContainerRef=document.getElementById("CyberContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden"; 
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden";  
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden";  
    Icon9ContainerRef.style.visibility = "hidden";  
    Icon10ContainerRef.style.animation ="PR2Animation 1s linear forwards 1"; 
    
    var QatarCenterCampTextRef                   =document.getElementById("QatarCenterCampText");
    var QatarCenterCampPhargraphRef              =document.getElementById("QatarCenterCampPhargraph");
    QatarCenterCampTextRef.style.animation      = "QatarCenterTextAnimation 1s linear forwards 1"; 
    QatarCenterCampPhargraphRef.style.animation  = "CyberTrainingText2Animation 1s linear forwards 1"; 



    var DevlopAppsRef        =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef  =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef      =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef      =document.getElementById("LawAwareness");
    var CyberTrainingRef     =document.getElementById("CyberTraining");
    var atfalnaAppRef        =document.getElementById("atfalnaApp");
    var QatarCenterCampRef   =document.getElementById("QatarCenterCamp");
    var SoialCampRef         =document.getElementById("SoialCamp");
    var awarnessCampRef      =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility = "hidden";  
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.pointerEvents      = "none";

    QatarCenterCampRef.style.animation   = "RoundPR2ContainerAnimation 1s linear forwards 1"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden"; 
    
    setTimeout(() => {
      MainRef.style.display="block";
      MainRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
      var SmallArrowRRef               =document.getElementById("smallArrowRQcb");
      SmallArrowRRef.style.visibility  = "visible";
      var SmallArrowLRef               =document.getElementById("smallArrowLQcb");
      SmallArrowLRef.style.visibility  = "visible";
    }, 1000);}
  }
    
  function goToQatarCenterMobile ()
  {
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    if(window.getComputedStyle(Icon10ContainerRef).getPropertyValue("opacity") !==0)
{
    setFlagProjMobile(10);
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    var ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var SoialProjectLogoRef      =document.getElementById("QatarCenterProjectLogo");
    var MainTextRef                =document.getElementById("MainText10");
    var MunTextRef   =document.getElementById("QatarCenterTextMobile");

    var smallArrowRRef             =document.getElementById("smallArrowRQcbMobile");
    var smallArrowLRef             =document.getElementById("smallArrowLQcbMobile");
    
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "visible";
    
    var MainRef          =document.getElementById("MainPicForProject10");
    var MainPicRef       =document.getElementById("MainPic10");
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    Icon1ContainerRef.style.visibility = "hidden"; 
    Icon2ContainerRef.style.visibility = "hidden"; 
    Icon3ContainerRef.style.visibility = "hidden";  
    Icon4ContainerRef.style.visibility = "hidden"; 
    Icon5ContainerRef.style.visibility = "hidden"; 
    Icon6ContainerRef.style.visibility = "hidden"; 
    Icon7ContainerRef.style.visibility = "hidden"; 
    Icon8ContainerRef.style.visibility = "hidden"; 
    Icon9ContainerRef.style.visibility = "hidden"; 
    Icon10ContainerRef.style.visibility = "hidden"; 
   
    
    
    var DevlopAppsRef         =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef   =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef       =document.getElementById("RoundContainerMobile3");
    PoliceVideosRef.style.pointerEvents    = "none";
    var QatarCharityVideoRef  =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef       =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef      =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef         =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef    =document.getElementById("RoundContainerMobile8");
    var SoialCampRef          =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef       =document.getElementById("RoundContainerMobile10");

   

    DevlopAppsRef.style.visibility = "hidden"; 
    MunpilcyMinistryRef.style.visibility = "hidden"; 
    PoliceVideosRef.style.visibility = "hidden"; 
    QatarCharityVideoRef.style.visibility = "hidden"; 
    LawAwarenessRef.style.visibility = "hidden"; 
    CyberTrainingRef.style.visibility = "hidden"; 
    atfalnaAppRef.style.visibility = "hidden"; 
    QatarCenterCampRef.style.visibility = "hidden"; 
    SoialCampRef.style.visibility = "hidden"; 
    awarnessCampRef.style.visibility = "hidden";  
    ProjectLogoRef.style.animation      = "OpacityAnimation 1s linear forwards 1"; 
    ProjectTextRef.style.animation      = "ProjectTextAnimationMobile 1s linear forwards 1"; 
    SoialProjectLogoRef.style.animation     = "OpacityAnimation 1s linear forwards 1"; 
    
    
      MunTextRef.style.display="block";
      MainTextRef.style.display="block";
      smallArrowRRef.style.visibility  = "visible";
      smallArrowLRef.style.visibility  = "visible";
      MainRef.style.display="block";
      MainPicRef.style.animation  = "OpacityAnimation 1s linear forwards 1"; 
}
   
  }
  function ReturnToOurProjects(){

    var VideosRef,VideosRef1,VideosRef2,VideosRef3,VideosRef4,VideosRef5,VideosRef6,VideosRef7;
    var SmallArrowRRef , SmallArrowLRef; 
    var ProjectTextRef             =document.getElementById("OurProjectsText11");
    ProjectTextRef.style.animation =  "ReverseProjectTextAnimation 1s ease forwards 1"; 
    var BackArrowRef               =document.getElementById("BackArrow");
    BackArrowRef.style.visibility  = "hidden";

    var DevlopAppsRef        =document.getElementById("DevlopApps");
    var MunpilcyMinistryRef  =document.getElementById("MunpilcyMinistry");
    var PoliceVideosRef      =document.getElementById("PoliceVideos");
    var QatarCharityVideoRef =document.getElementById("QatarCharityVideo");
    var LawAwarenessRef      =document.getElementById("LawAwareness");
    var CyberTrainingRef     =document.getElementById("CyberTraining");
    var atfalnaAppRef        =document.getElementById("atfalnaApp");
    var QatarCenterCampRef   =document.getElementById("QatarCenterCamp");
    var SoialCampRef         =document.getElementById("SoialCamp");
    var awarnessCampRef      =document.getElementById("awarnessCamp");
    DevlopAppsRef.style.visibility  = "visible";
    MunpilcyMinistryRef.style.visibility  = "visible"; 
    QatarCharityVideoRef.style.visibility  = "visible";
    LawAwarenessRef.style.visibility  = "visible";
    CyberTrainingRef.style.visibility  = "visible";
    atfalnaAppRef.style.visibility  = "visible"; 
    QatarCenterCampRef.style.visibility  = "visible";
    SoialCampRef.style.visibility  = "visible";
    awarnessCampRef.style.visibility  = "visible";
    PoliceVideosRef.style.visibility  = "visible";
    
    var Icon1ContainerRef=document.getElementById("VideoContainer");
    var Icon2ContainerRef=document.getElementById("AwareContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaContainer");
    var Icon5ContainerRef=document.getElementById("CyberContainer");
    var Icon6ContainerRef=document.getElementById("FaienessContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityContainer");
    var Icon8ContainerRef=document.getElementById("PR1Container");
    var Icon9ContainerRef=document.getElementById("SoialContainer");
    var Icon10ContainerRef=document.getElementById("PR2Container");
    Icon1ContainerRef.style.visibility  = "visible";
    Icon2ContainerRef.style.visibility  = "visible";
    Icon3ContainerRef.style.visibility  = "visible";
    Icon4ContainerRef.style.visibility  = "visible";
    Icon5ContainerRef.style.visibility  = "visible";
    Icon6ContainerRef.style.visibility  = "visible";
    Icon7ContainerRef.style.visibility  = "visible";
    Icon8ContainerRef.style.visibility  = "visible";
    Icon9ContainerRef.style.visibility  = "visible";
    Icon10ContainerRef.style.visibility  = "visible";
switch(flag)
{
  case 1:

    var MainRef                                =document.getElementById("MainPicForProject1");
    var MainPic1Ref                            =document.getElementById("MainPic1");
    MainPic1Ref.pause();
    MainPic1Ref.style.animation                = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainRef.style.display                      = "none";
    var PoliceVideosTextRef                    =document.getElementById("PoliceVideosText");
    var PoliceVideosPhargraphRef               =document.getElementById("PoliceVideosPhargraph");
    PoliceVideosTextRef.style.animation        = "PoliceVideoTextAnimationReverse 1s linear 1"; 
    PoliceVideosPhargraphRef.style.animation   = "PoliceVideoText2AnimationReverse 1s linear 1"; 
    PoliceVideosRef.style.pointerEvents        ="auto";
    PoliceVideosRef.style.animation            = "RoundVideoContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon1ContainerRef.style.animation          = "PoliceVideoReverseAnimation 0.5s linear forwards 1"; 
    break;

  case 2:
     VideosRef = document.getElementById("MainPic2");
     VideosRef1= document.getElementById("Munvideo11");
     VideosRef2= document.getElementById("Munvideo22");
     VideosRef3= document.getElementById("Munvideo33");
     VideosRef4= document.getElementById("Munvideo44");
     VideosRef5= document.getElementById("Munvideo55");
    if(!VideosRef.paused)
    {
      VideosRef.pause();
    }else if(!VideosRef1.paused){
      VideosRef1.pause();
    }else if(!VideosRef2.paused){
      VideosRef2.pause();
    }else if(!VideosRef3.paused){
      VideosRef3.pause();
    }else if(!VideosRef4.paused){
      VideosRef4.pause();
    }else if(!VideosRef5.paused){
      VideosRef5.pause();
    }

    var MainPic2Ref                                = document.getElementById("MainPicForProject2");
    MainPic2Ref.style.visibility                   = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic2Ref.style.display                      = "none";
    var MunpilcyMinistryTextRef                    = document.getElementById("MunpilcyMinistryText");
    var MunpilcyMinistryPhargraphRef               = document.getElementById("MunpilcyMinistryPhargraph");
    MunpilcyMinistryTextRef.style.animation        = "PoliceVideoTextAnimationReverse 1s linear 1"; 
    MunpilcyMinistryPhargraphRef.style.animation   = "PoliceVideoText2AnimationReverse 1.5s linear 1"; 
    MunpilcyMinistryRef.style.pointerEvents        ="auto";
    MunpilcyMinistryRef.style.animation            = "RoundMunAwareContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon2ContainerRef.style.animation              = "MunciplityReverseAnimation 0.5s linear forwards 1"; 
    SmallArrowRRef               =document.getElementById("smallArrowR");
    SmallArrowRRef.style.visibility  = "hidden";
    SmallArrowLRef               =document.getElementById("smallArrowL");
    SmallArrowLRef.style.visibility  = "hidden";
    break;

  case 3:
     VideosRef =document.getElementById("MainPic3");
     VideosRef1=document.getElementById("Developedvideo11");
    if(!VideosRef.paused)
    {
      VideosRef.pause();
    }else if(!VideosRef1.paused){
      VideosRef1.pause();
    }

    var MainPic3Ref       =document.getElementById("MainPicForProject3");
    MainPic3Ref.style.animation = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic3Ref.style.display="none";
    SmallArrowRRef              =document.getElementById("smallArrowRDeveloped");
    SmallArrowRRef.style.visibility  = "hidden";
    SmallArrowLRef              =document.getElementById("smallArrowLDeveloped");
    SmallArrowLRef.style.visibility  = "hidden"; 
    var DevelopedAppTextRef                    =document.getElementById("DevelopedAppText");
    var DevelopedAppPhargraphRef               =document.getElementById("DevelopedAppPharaghap");
    DevelopedAppTextRef.style.animation       = "PoliceVideoTextAnimationReverse 1s linear  1"; 
    DevelopedAppPhargraphRef.style.animation  = "PoliceVideoText2AnimationReverse 1.5s linear  1";
    DevlopAppsRef.style.pointerEvents        ="auto";
    DevlopAppsRef.style.animation              = "RoundDevlopAppsContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon3ContainerRef.style.animation          = "DevlopedLearnReverseAnimation 0.5s linear forwards 1"; 
    break;

  case 4:

    VideosRef = document.getElementById("MainPic4");
    VideosRef1= document.getElementById("Atfalnavideo11");
    VideosRef2= document.getElementById("Atfalnavideo22");
    VideosRef3= document.getElementById("Atfalnavideo33");
    VideosRef4= document.getElementById("Atfalnavideo44");
    VideosRef5= document.getElementById("Atfalnavideo55");
    if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }else if(!VideosRef3.paused){
        VideosRef3.pause();
      }else if(!VideosRef4.paused){
        VideosRef4.pause();
      }else if(!VideosRef5.paused){
        VideosRef5.pause();
      }
    var MainPic4Ref                         =document.getElementById("MainPicForProject4");
    MainPic4Ref.style.animation             = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic4Ref.style.display               ="none";
    var atfalnaAppTextRef                   =document.getElementById("atfalnaAppText");
    var atfalnaAppPharagphRef               =document.getElementById("atfalnaAppPharagph");
    atfalnaAppTextRef.style.animation       = "PoliceVideoTextAnimationReverse 1s linear  1"; 
    atfalnaAppPharagphRef.style.animation   = "PoliceVideoText2AnimationReverse 1.5s linear  1"; 
    atfalnaAppRef.style.pointerEvents       ="auto";
    atfalnaAppRef.style.animation           = "RoundAtfalnaContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon4ContainerRef.style.animation       = "AtfalnaReverseAnimation 0.5s linear forwards 1"; 
    SmallArrowRRef                          =document.getElementById("smallArrowRAtfalna");
    SmallArrowRRef.style.visibility         = "hidden";
    SmallArrowLRef                          =document.getElementById("smallArrowLAtfalna");
    SmallArrowLRef.style.visibility         = "hidden";
    break;

  case 5:
    VideosRef = document.getElementById("MainPic5");
    VideosRef1= document.getElementById("Cybervideo11");
    VideosRef2= document.getElementById("Cybervideo22");
    VideosRef3= document.getElementById("Cybervideo33");
    VideosRef4= document.getElementById("Cybervideo44");
    if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }else if(!VideosRef3.paused){
        VideosRef3.pause();
      }else if(!VideosRef4.paused){
        VideosRef4.pause();
      }
    var MainPic5Ref                            =document.getElementById("MainPicForProject5");
    MainPic5Ref.style.animation                = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic5Ref.style.display                  ="none";
    var CyberTrainingTextRef                   = document.getElementById("CyberTrainingText");
    var CyberTrainingPharagphRef               = document.getElementById("CyberTrainingPharagph");
    CyberTrainingTextRef.style.animation       = "PoliceVideoTextAnimationReverse 1s linear  1"; 
    CyberTrainingPharagphRef.style.animation   = "PoliceVideoText2AnimationReverse 1s linear  1"; 
    CyberTrainingRef.style.pointerEvents       ="auto";
    CyberTrainingRef.style.animation           = "RoundCyberContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon5ContainerRef.style.animation          = " CyberReverseAnimation  0.5s linear forwards 1"; 
    SmallArrowRRef                             =document.getElementById("smallArrowRCyber");
    SmallArrowRRef.style.visibility            = "hidden";
    SmallArrowLRef                             =document.getElementById("smallArrowLCyber");
    SmallArrowLRef.style.visibility            = "hidden";
    break;

  case 6:
     VideosRef = document.getElementById("MainPic6");
     VideosRef1= document.getElementById("Justicevideo11");
     VideosRef2= document.getElementById("Justicevideo22");
     VideosRef3= document.getElementById("Justicevideo33");
     VideosRef4= document.getElementById("Justicevideo44");
     VideosRef5= document.getElementById("Justicevideo55");
     VideosRef6= document.getElementById("Justicevideo66");
     VideosRef7= document.getElementById("Justicevideo77");
    if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }else if(!VideosRef3.paused){
        VideosRef3.pause();
      }else if(!VideosRef4.paused){
        VideosRef4.pause();
      }else if(!VideosRef5.paused){
        VideosRef5.pause();
      }else if(!VideosRef6.paused){
        VideosRef6.pause();
      }else if(!VideosRef7.paused){
        VideosRef7.pause();
      }
    var MainPic6Ref                           =document.getElementById("MainPicForProject6");
    MainPic6Ref.style.animation               = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic6Ref.style.display                 = "none";
    var LawAwarenessTextRef                   = document.getElementById("LawAwarenessText");
    var LawAwarenessPhargaphRef               = document.getElementById("LawAwarenessPhargaph");
    LawAwarenessTextRef.style.animation      = "PoliceVideoTextAnimationReverse 1s linear  1"; 
    LawAwarenessPhargaphRef.style.animation  = "PoliceVideoText2AnimationReverse 1s linear  1"; 
    LawAwarenessRef.style.pointerEvents       ="auto";
    LawAwarenessRef.style.animation           = "RoundFairnessContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon6ContainerRef.style.animation         = " FairnessReverseAnimation 0.5s linear forwards 1"; 
    SmallArrowRRef                        =document.getElementById("smallArrowRJustice");
    SmallArrowRRef.style.visibility           = "hidden";
    SmallArrowLRef                        =document.getElementById("smallArrowLJustice");
    SmallArrowLRef.style.visibility           = "hidden";
    break;

  case 7:
   VideosRef = document.getElementById("MainPic7");
   VideosRef1= document.getElementById("QatarCharityvideo11");
   VideosRef2= document.getElementById("QatarCharityvideo22");
  if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }
    var MainPic7Ref                               =document.getElementById("MainPicForProject7");
    MainPic7Ref.style.animation                   = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic7Ref.style.display                     = "none";
    var QatarCharityVideoTextRef                  = document.getElementById("QatarCharityVideoText");
    var QatarCharityVideoPhargaphRef              = document.getElementById("QatarCharityVideoPhargraph");
    QatarCharityVideoTextRef.style.animation      = "PoliceVideoTextAnimationReverse 1s linear  1"; 
    QatarCharityVideoPhargaphRef.style.animation  = "PoliceVideoText2AnimationReverse 1s linear  1"; 
    QatarCharityVideoRef.style.pointerEvents      ="auto";

    QatarCharityVideoRef.style.animation          = "RoundQatarCharityContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon7ContainerRef.style.animation             = "QatarCharityReverseAnimation 0.5s linear forwards 1"; 
    SmallArrowRRef                                =document.getElementById("smallArrowRQatarCharity");
    SmallArrowRRef.style.visibility               = "hidden";
    SmallArrowLRef                                =document.getElementById("smallArrowLQatarCharity");
    SmallArrowLRef.style.visibility               = "hidden";
    break;
  
    case 8:
     VideosRef = document.getElementById("MainPic8");
     VideosRef1= document.getElementById("Ashghalvideo11");
     VideosRef2= document.getElementById("Ashghalvideo22");
      if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }
    var MainPic8Ref             =document.getElementById("MainPicForProject8");
    MainPic8Ref.style.animation                = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic8Ref.style.display                  ="none";
    var awarnessCampTextRef                    =document.getElementById("awarnessCampText");
    var awarnessCampPharagaphRef               =document.getElementById("awarnessCampPharagaph");
    awarnessCampTextRef.style.animation        = "PoliceVideoTextAnimationReverse 1s linear  1"; 
    awarnessCampPharagaphRef.style.animation   = "PoliceVideoText2AnimationReverse 1s linear  1"; 
    awarnessCampRef.style.pointerEvents        ="auto";
    awarnessCampRef.style.animation            = "RoundPR1ContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon8ContainerRef.style.animation          = "PR1ReverseAnimation 0.5s linear forwards 1"; 
    SmallArrowRRef                             =document.getElementById("smallArrowRAshghal");
    SmallArrowRRef.style.visibility            = "hidden";
    SmallArrowLRef                             =document.getElementById("smallArrowLAshghal");
    SmallArrowLRef.style.visibility            = "hidden";
    break;

  case 9:
    VideosRef = document.getElementById("MainPic9");
    VideosRef1= document.getElementById("Eduvideo11");
    VideosRef2= document.getElementById("Eduvideo22");
    VideosRef3= document.getElementById("Eduvideo33");
    if(!VideosRef.paused)
    {
      VideosRef.pause();
    }else if(!VideosRef1.paused){
      VideosRef1.pause();
    }else if(!VideosRef2.paused){
      VideosRef2.pause();
    }else if(!VideosRef3.paused){
      VideosRef3.pause();
    }
    var MainPic9Ref                          =document.getElementById("MainPicForProject9");
    MainPic9Ref.style.animation              = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
  
    MainPic9Ref.style.display                    ="none";
    var SoialCampTextRef                     =document.getElementById("SoialCampText");
    var SoialCamppharagraphRef               =document.getElementById("SoialCamppharagraph");
    SoialCampTextRef.style.animation        = "PoliceVideoTextAnimationReverse 1s linear  1"; 
    SoialCamppharagraphRef.style.animation  = "PoliceVideoText2AnimationReverse 1s linear  1"; 
    SoialCampRef.style.pointerEvents    ="auto";
    SoialCampRef.style.animation            = "RoundSoialContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon9ContainerRef.style.animation       = "SoialReverseAnimation 0.5s linear forwards 1"; 
    SmallArrowRRef               =document.getElementById("smallArrowREdu");
    SmallArrowRRef.style.visibility  = "hidden";
    SmallArrowLRef               =document.getElementById("smallArrowLEdu");
    SmallArrowLRef.style.visibility  = "hidden";
    break;

  case 10:
   VideosRef = document.getElementById("MainPic10");
   VideosRef1= document.getElementById("Qcbvideo11");
   VideosRef2= document.getElementById("Qcbvideo22");
   VideosRef3= document.getElementById("Qcbvideo33");
    if(!VideosRef.paused)
    {
      VideosRef.pause();
    }else if(!VideosRef1.paused){
      VideosRef1.pause();
    }else if(!VideosRef2.paused){
      VideosRef2.pause();
    }else if(!VideosRef3.paused){
      VideosRef3.pause();
    }
    var MainPic10Ref                             =document.getElementById("MainPicForProject10");
    MainPic10Ref.style.animation                 = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic10Ref.style.display                   = "none";
    var QatarCenterCampTextRef                   = document.getElementById("QatarCenterCampText");
    var QatarCenterCampPhargraphRef              = document.getElementById("QatarCenterCampPhargraph");
    QatarCenterCampTextRef.style.animation       = "PoliceVideoTextAnimationReverse 1s linear  1"; 
    QatarCenterCampPhargraphRef.style.animation  = "PoliceVideoText2AnimationReverse 1s linear  1"; 
    QatarCenterCampRef.style.pointerEvents       ="auto";
    QatarCenterCampRef.style.animation           = "RoundPR2ContainerReveseAnimation 0.5s linear forwards 1"; 
    Icon10ContainerRef.style.animation           = "PR2ReverseAnimation 0.5s linear forwards 1"; 
    SmallArrowRRef                               =document.getElementById("smallArrowRQcb");
    SmallArrowRRef.style.visibility              = "hidden";
    SmallArrowLRef                               =document.getElementById("smallArrowLQcb");
    SmallArrowLRef.style.visibility              = "hidden";
    break;
    default:
      break;
}
   
  }

  function ReturnToOurProjectsMobile(){

    var VideosRef,VideosRef1,VideosRef2,VideosRef3,VideosRef4,VideosRef5,VideosRef6,VideosRef7,MunTextRef,ProjectLogoRef,MainTextRef  ;
    var SmallArrowRRef , SmallArrowLRef; 
    var ProjectTextRef             =document.getElementById("OurProjectsWordMobile");
    ProjectTextRef.style.animation =  "ReverseProjectTextAnimation 1s ease forwards 1"; 
    var BackArrowRef               =document.getElementById("BackArrowMobile");
    BackArrowRef.style.visibility  = "hidden";

    var DevlopAppsRef        =document.getElementById("RoundContainerMobile1");
    var MunpilcyMinistryRef  =document.getElementById("RoundContainerMobile2");
    var PoliceVideosRef      =document.getElementById("RoundContainerMobile3");
    var QatarCharityVideoRef =document.getElementById("RoundContainerMobile4");
    var LawAwarenessRef      =document.getElementById("RoundContainerMobile5");
    var CyberTrainingRef     =document.getElementById("RoundContainerMobile6");
    var atfalnaAppRef        =document.getElementById("RoundContainerMobile7");
    var QatarCenterCampRef   =document.getElementById("RoundContainerMobile8");
    var SoialCampRef         =document.getElementById("RoundContainerMobile9");
    var awarnessCampRef      =document.getElementById("RoundContainerMobile10");
    DevlopAppsRef.style.visibility = "visible";
    MunpilcyMinistryRef.style.visibility  = "visible";
    QatarCharityVideoRef.style.visibility  = "visible";
    LawAwarenessRef.style.visibility  = "visible";
    CyberTrainingRef.style.visibility  = "visible";
    atfalnaAppRef.style.visibility  = "visible";
    QatarCenterCampRef.style.visibility  = "visible";
    SoialCampRef.style.visibility  = "visible";
    awarnessCampRef.style.visibility  = "visible";
    PoliceVideosRef.style.visibility  = "visible";
    
    var Icon1ContainerRef=document.getElementById("PoliceMobileIconContainer");
    var Icon2ContainerRef=document.getElementById("MunMobileIconContainer");
    var Icon3ContainerRef=document.getElementById("DevelopedMobileIconContainer");
    var Icon4ContainerRef=document.getElementById("AtfalanaMobileIconContainer");
    var Icon5ContainerRef=document.getElementById("JusticeMobileIconContainer");
    var Icon6ContainerRef=document.getElementById("CyberobileIconContainer");
    var Icon7ContainerRef=document.getElementById("QatarCharityMobileIconContainer");
    var Icon8ContainerRef=document.getElementById("PrAshaalMobileIconContainer");
    var Icon9ContainerRef=document.getElementById("soialMediaMobileIconContainer");
    var Icon10ContainerRef=document.getElementById("PrQimccMobileIconContainer");
    Icon1ContainerRef.style.visibility  = "visible";
    Icon2ContainerRef.style.visibility  = "visible";
    Icon3ContainerRef.style.visibility  = "visible";
    Icon4ContainerRef.style.visibility  = "visible";
    Icon5ContainerRef.style.visibility  = "visible";
    Icon6ContainerRef.style.visibility  = "visible"; 
    Icon7ContainerRef.style.visibility  = "visible";
    Icon8ContainerRef.style.visibility  = "visible";
    Icon9ContainerRef.style.visibility  = "visible";
    Icon10ContainerRef.style.visibility  = "visible";
switch(flagProjMobile)
{
  case 1:

    var MainRef                        =document.getElementById("MainPicForProject1");
    var MainPic1Ref                    =document.getElementById("MainPic1");
    MainPic1Ref.pause();
 //   MainPic1Ref.style.animation                = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainRef.style.display              = "none";
    var PoliceVideosTextRef            =document.getElementById("PoliceTextMobile");
    PoliceVideosTextRef.style.display  ='none';
     ProjectLogoRef                    =document.getElementById("ProjectsDohaLogo");
    var PoliceLogoRef                  =document.getElementById("PoliceProjectLogo");
     MainTextRef                       =document.getElementById("MainText1");
    ProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1";  
    PoliceLogoRef.style.animation      ="FaddedOpacityAnimation .1s linear forwards 1";    
    MainTextRef.style.display          ='none';
    PoliceVideosRef.style.pointerEvents        ="auto";
 
    break;

  case 2:
     VideosRef = document.getElementById("MainPic2");
     VideosRef1= document.getElementById("Munvideo11");
     VideosRef2= document.getElementById("Munvideo22");
     VideosRef3= document.getElementById("Munvideo33");
     VideosRef4= document.getElementById("Munvideo44");
     VideosRef5= document.getElementById("Munvideo55");
    if(!VideosRef.paused)
    {
      VideosRef.pause();
    }else if(!VideosRef1.paused){
      VideosRef1.pause();
    }else if(!VideosRef2.paused){
      VideosRef2.pause();
    }else if(!VideosRef3.paused){
      VideosRef3.pause();
    }else if(!VideosRef4.paused){
      VideosRef4.pause();
    }else if(!VideosRef5.paused){
      VideosRef5.pause();
    }

    var MainPic2Ref                                = document.getElementById("MainPicForProject2");
    //MainPic2Ref.style.visibility                   = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic2Ref.style.display                      = "none";
    MunpilcyMinistryRef.style.pointerEvents        = "auto";
    SmallArrowRRef               =document.getElementById("smallArrowRMunMobile");
    SmallArrowRRef.style.visibility  = "hidden";
    SmallArrowLRef               =document.getElementById("smallArrowLMunMobile");
    SmallArrowLRef.style.visibility  = "hidden";
    
    var MunTextRef2                    =document.getElementById("MunTextMobile");
    MunTextRef2.style.display          ='none';
     ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    ProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1";  
    var MunProjectLogoRef              =document.getElementById("MunProjectLogo");
    MunProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1"; 
    MainTextRef                =document.getElementById("MainText2");
    MainTextRef.style.display                      = "none";
    break;

  case 3:
     VideosRef =document.getElementById("MainPic3");
     VideosRef1=document.getElementById("Developedvideo11");
    if(!VideosRef.paused)
    {
      VideosRef.pause();
    }else if(!VideosRef1.paused){
      VideosRef1.pause();
    }

    var MainPic3Ref       =document.getElementById("MainPicForProject3");
    MainPic3Ref.style.display="none";
    SmallArrowRRef              =document.getElementById("smallArrowRDevelopedMobile");
    SmallArrowRRef.style.visibility  = "hidden";
    SmallArrowLRef              =document.getElementById("smallArrowLDevelopedMobile");
    SmallArrowLRef.style.visibility  = "hidden"; 
     MunTextRef   =document.getElementById("DevLearnTextMobile");
     ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var DevLearnProjectLogoRef          =document.getElementById("DevLearnProjectLogo");
    DevLearnProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1"; 
     MainTextRef                =document.getElementById("MainText3");
    MainTextRef.style.display= "none";
    ProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1";  
    MunTextRef.style.display          ='none';
    DevlopAppsRef.style.pointerEvents       ="auto";

    break;

  case 4:

    VideosRef = document.getElementById("MainPic4");
    VideosRef1= document.getElementById("Atfalnavideo11");
    VideosRef2= document.getElementById("Atfalnavideo22");
    VideosRef3= document.getElementById("Atfalnavideo33");
    VideosRef4= document.getElementById("Atfalnavideo44");
    VideosRef5= document.getElementById("Atfalnavideo55");
    if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }else if(!VideosRef3.paused){
        VideosRef3.pause();
      }else if(!VideosRef4.paused){
        VideosRef4.pause();
      }else if(!VideosRef5.paused){
        VideosRef5.pause();
      }
    var MainPic4Ref                         =document.getElementById("MainPicForProject4");
   // MainPic4Ref.style.animation             = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic4Ref.style.display               ="none";
     ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var AtfalnaProjectLogoRef     =document.getElementById("AtfalnaProjectLogo");
     MunTextRef   =document.getElementById("AtfalnaTextMobile");
     MainTextRef                =document.getElementById("MainText7");

    AtfalnaProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1"; 
    MainTextRef.style.display    = "none";
    ProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1";  
    MunTextRef.style.display     ='none';
    atfalnaAppRef.style.pointerEvents       ="auto";
     SmallArrowRRef                          =document.getElementById("smallArrowRAtfalnaMobile");
    SmallArrowRRef.style.visibility         = "hidden";
    SmallArrowLRef                          =document.getElementById("smallArrowLAtfalnaMobile");
    SmallArrowLRef.style.visibility         = "hidden";
    break;

  case 5:
    VideosRef = document.getElementById("MainPic5");
    VideosRef1= document.getElementById("Cybervideo11");
    VideosRef2= document.getElementById("Cybervideo22");
    VideosRef3= document.getElementById("Cybervideo33");
    VideosRef4= document.getElementById("Cybervideo44");
    if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }else if(!VideosRef3.paused){
        VideosRef3.pause();
      }else if(!VideosRef4.paused){
        VideosRef4.pause();
      }
    var MainPic5Ref                            =document.getElementById("MainPicForProject5");
   // MainPic5Ref.style.animation                = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic5Ref.style.display                  ="none";
    
     ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var CyberProjectLogoRef      =document.getElementById("CyberProjectLogo");
     MainTextRef                =document.getElementById("MainText4");
     MunTextRef   =document.getElementById("CyberTextMobile");
    CyberProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1"; 
    MainTextRef.style.display    = "none";
    ProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1";  
    MunTextRef.style.display     ='none';
    CyberTrainingRef.style.pointerEvents       ="auto";
  
    SmallArrowRRef                             =document.getElementById("smallArrowRCyberMobile");
    SmallArrowRRef.style.visibility            = "hidden";
    SmallArrowLRef                             =document.getElementById("smallArrowLCyberMobile");
    SmallArrowLRef.style.visibility            = "hidden";
    break;

  case 6:
     VideosRef = document.getElementById("MainPic6");
     VideosRef1= document.getElementById("Justicevideo11");
     VideosRef2= document.getElementById("Justicevideo22");
     VideosRef3= document.getElementById("Justicevideo33");
     VideosRef4= document.getElementById("Justicevideo44");
     VideosRef5= document.getElementById("Justicevideo55");
     VideosRef6= document.getElementById("Justicevideo66");
     VideosRef7= document.getElementById("Justicevideo77");
    if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }else if(!VideosRef3.paused){
        VideosRef3.pause();
      }else if(!VideosRef4.paused){
        VideosRef4.pause();
      }else if(!VideosRef5.paused){
        VideosRef5.pause();
      }else if(!VideosRef6.paused){
        VideosRef6.pause();
      }else if(!VideosRef7.paused){
        VideosRef7.pause();
      }
    var MainPic6Ref                           =document.getElementById("MainPicForProject6");
   // MainPic6Ref.style.animation               = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic6Ref.style.display                 = "none";
    
    
     ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var JusticeProjectLogoRef      =document.getElementById("JusticeProjectLogo");
     MainTextRef                =document.getElementById("MainText8");
     MunTextRef   =document.getElementById("JusticeTextMobile");
    JusticeProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1"; 
    MainTextRef.style.display    = "none";
    ProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1";  
    MunTextRef.style.display     ='none';
    LawAwarenessRef.style.pointerEvents       ="auto";
    
    SmallArrowRRef                        =document.getElementById("smallArrowRJusticeMobile");
    SmallArrowRRef.style.visibility           = "hidden";
    SmallArrowLRef                        =document.getElementById("smallArrowLJusticeMobile");
    SmallArrowLRef.style.visibility           = "hidden";
    break;

  case 7:
   VideosRef = document.getElementById("MainPic7");
   VideosRef1= document.getElementById("QatarCharityvideo11");
   VideosRef2= document.getElementById("QatarCharityvideo22");
  if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }
    var MainPic7Ref                               =document.getElementById("MainPicForProject7");
    //MainPic7Ref.style.animation                   = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic7Ref.style.display                     = "none";
     ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var QatarCharityProjectLogoRef      =document.getElementById("QatarCharityProjectLogo");
     MainTextRef                =document.getElementById("MainText5");
     MunTextRef   =document.getElementById("QatarCharityTextMobile");
    QatarCharityProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1"; 
    MainTextRef.style.display    = "none";
    ProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1";  
    MunTextRef.style.display     ='none';
    QatarCharityVideoRef.style.pointerEvents      ="auto";

    SmallArrowRRef                                =document.getElementById("smallArrowRQatarCharityMobile");
    SmallArrowRRef.style.visibility               = "hidden";
    SmallArrowLRef                                =document.getElementById("smallArrowLQatarCharityMobile");
    SmallArrowLRef.style.visibility               = "hidden";
    break;
  
    case 8:
     VideosRef = document.getElementById("MainPic8");
     VideosRef1= document.getElementById("Ashghalvideo11");
     VideosRef2= document.getElementById("Ashghalvideo22");
      if(!VideosRef.paused)
      {
        VideosRef.pause();
      }else if(!VideosRef1.paused){
        VideosRef1.pause();
      }else if(!VideosRef2.paused){
        VideosRef2.pause();
      }
    var MainPic8Ref             =document.getElementById("MainPicForProject8");
   // MainPic8Ref.style.animation                = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic8Ref.style.display                  ="none";
    
     ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var AshghalProjectLogoRef      =document.getElementById("AshghalProjectLogo");
     MainTextRef                =document.getElementById("MainText9");
     MunTextRef   =document.getElementById("AshghalTextMobile");
    AshghalProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1"; 
    MainTextRef.style.display    = "none";
    ProjectLogoRef.style.animation     ="FaddedOpacityAnimation .1s linear forwards 1";  
    MunTextRef.style.display     ='none';
    awarnessCampRef.style.pointerEvents        ="auto";
    
    SmallArrowRRef                             =document.getElementById("smallArrowRAshghalMobile");
    SmallArrowRRef.style.visibility            = "hidden";
    SmallArrowLRef                             =document.getElementById("smallArrowLAshghalMobile");
    SmallArrowLRef.style.visibility            = "hidden";
    break;

  case 9:
    VideosRef = document.getElementById("MainPic9");
    VideosRef1= document.getElementById("Eduvideo11");
    VideosRef2= document.getElementById("Eduvideo22");
    VideosRef3= document.getElementById("Eduvideo33");
    if(!VideosRef.paused)
    {
      VideosRef.pause();
    }else if(!VideosRef1.paused){
      VideosRef1.pause();
    }else if(!VideosRef2.paused){
      VideosRef2.pause();
    }else if(!VideosRef3.paused){
      VideosRef3.pause();
    }
    var MainPic9Ref                          =document.getElementById("MainPicForProject9");
   // MainPic9Ref.style.animation              = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic9Ref.style.display                    ="none";
     ProjectLogoRef             =document.getElementById("ProjectsDohaLogo");
    var SoialProjectLogoRef        =document.getElementById("SoialProjectLogo");
     MainTextRef                =document.getElementById("MainText6");
     MunTextRef   =document.getElementById("SoialTextMobile");
    SoialProjectLogoRef.style.animation   ="FaddedOpacityAnimation .1s linear forwards 1"; 
    MainTextRef.style.display             = "none";
    ProjectLogoRef.style.animation        ="FaddedOpacityAnimation .1s linear forwards 1";  
    MunTextRef.style.display              ='none';

    SoialCampRef.style.pointerEvents    ="auto";
    SmallArrowRRef               =document.getElementById("smallArrowREduMobile");
    SmallArrowRRef.style.visibility  = "hidden";
    SmallArrowLRef               =document.getElementById("smallArrowLEduMobile");
    SmallArrowLRef.style.visibility  = "hidden";
    break;

  case 10:
   VideosRef = document.getElementById("MainPic10");
   VideosRef1= document.getElementById("Qcbvideo11");
   VideosRef2= document.getElementById("Qcbvideo22");
   VideosRef3= document.getElementById("Qcbvideo33");
    if(!VideosRef.paused)
    {
      VideosRef.pause();
    }else if(!VideosRef1.paused){
      VideosRef1.pause();
    }else if(!VideosRef2.paused){
      VideosRef2.pause();
    }else if(!VideosRef3.paused){
      VideosRef3.pause();
    }
    var MainPic10Ref                             =document.getElementById("MainPicForProject10");
   // MainPic10Ref.style.animation                 = "ImageFaddedOpacityAnimation 0.5s linear forwards 1"; 
    MainPic10Ref.style.display                   = "none";
     ProjectLogoRef           =document.getElementById("ProjectsDohaLogo");
    var SoialProjectLogoRef      =document.getElementById("QatarCenterProjectLogo");
     MainTextRef              =document.getElementById("MainText10");
     MunTextRef               =document.getElementById("QatarCenterTextMobile");
    SoialProjectLogoRef.style.animation   ="FaddedOpacityAnimation .1s linear forwards 1"; 
    MainTextRef.style.display             = "none";
    ProjectLogoRef.style.animation        ="FaddedOpacityAnimation .1s linear forwards 1";  
    MunTextRef.style.display              ='none';
    
    QatarCenterCampRef.style.pointerEvents       ="auto";
    SmallArrowRRef                               =document.getElementById("smallArrowRQcbMobile");
    SmallArrowRRef.style.visibility              = "hidden";
    SmallArrowLRef                               =document.getElementById("smallArrowLQcbMobile");
    SmallArrowLRef.style.visibility              = "hidden";
    break;
    default:
      break;
}
   
  }

      return (
        <div className="ContainerProject">
        {(isMobile)&&
        
          <div className="MobileContainerProject">

            <div className="ProjectsImgContainerMobile" id="ProjectsImgContainerMobile"  >
            <img className="ProjectsImgMobile" id ="ProjectsImgMobile" fetchpriority="high"  loading="lazy" src={ProjectsImgMobile2}  alt="ProjectsImgMobile"></img>
            </div>
          
            <p className="OurProjectsWordMobile" id="OurProjectsWordMobile" > مشاريعنا</p>     
             <div className="BackArrowMobile" id="BackArrowMobile"  onClick={ReturnToOurProjectsMobile}></div>
            <p className="MainText" id="MainText1"  style={{ marginLeft: '30vw'}}>فيديوهات إرشادية 
                <span  style={{ fontSize: '4.09vw'}}><br/>(معهد تدريب الشرطة)
              </span></p>
              <p className="MainText" id="MainText2" style={{ marginLeft:'32vw'}}> وزارةالبلدية والبيئة
              <span  style={{ fontSize: '4.09vw'}}><br/>حملة توعية
            </span></p>  
            <p className="MainText" id="MainText3" style={{ marginLeft:'15vw'}}>وزارة التربية والتعليم
            والتعليم العالي  
                          <span  style={{ fontSize: '4.09vw'}}><br/>تطوير التعليم الإلكتروني
                        </span></p> 
                        <p className="MainText" id="MainText4" style={{ marginLeft:'33vw'}} > وزارة الخارجية

              
              <span  style={{ fontSize: '4.09vw'}}><br/>تدريب الأمن السيبراني
            </span></p>
            <p className="MainText" id="MainText5" style={{ marginLeft:'38vw'}}>قطر الخيرية
              
              <span  style={{ fontSize: '4.09vw'}}><br/> أفلام كرتونية
            </span></p>

            <p className="MainText" id="MainText6" style={{ marginLeft:'13vw'}}>وزارة التربية والتعليم
              والتعليم العالي
              <span  style={{ fontSize: '4.09vw'}}><br/>إدارة منصات التواصل الاجتماعي
            </span></p>
           
            <p className="MainText" id="MainText7" style={{ marginLeft:'34vw'}}> تطبيق أطفالنا
              </p>   
              
              <p className="MainText" id="MainText8" style={{ marginLeft:'38vw'}}>وزارة العدل

              <span  style={{ fontSize: '4.09vw'}}><br/>حملة توعوية
            </span></p> 
          
            <p className="MainText" id="MainText9" style={{ marginLeft:'29vw'}}>  هيئة أشغال العامة

              <span  style={{ fontSize: '4.09vw'}}><br/>حملة علاقات عامة
            </span></p>   

            <p  className="MainText" id="MainText10" style={{ marginLeft:'17vw'}}>مركز قطر للمعلومات
             الائتمانية
              <span  style={{ fontSize: '4.09vw'}}><br/>حملة علاقات عامة
            </span></p>     

              <div className="PoliceMobileIconContainer"  id="PoliceMobileIconContainer" onClick={goToPoliceMobile} >
              </div>
              <div className="RoundContainerMobile" id="RoundContainerMobile1" style={{  background: 'rgba(255,255,255,0.5)',top: '17%', marginLeft:'34.1vw'}} onClick={goToPoliceMobile}>
              <p className="RoundContainerTextMobile" >فيديوهات إرشادية 
                <span  style={{ fontSize: '2.817vw',top:'0%'}}><br/>(معهد تدريب الشرطة)
              </span></p>

              </div>
              <div className="smallArrowLMobile" id="smallArrowLMunMobile"          onClick={SwapVideosForMun}></div>
              <div className="smallArrowLMobile" id="smallArrowLDevelopedMobile"    onClick={SwapVideosForDeveloped}></div>
              <div className="smallArrowLMobile" id="smallArrowLQatarCharityMobile" onClick={SwapVideosForQatarCharity}></div>
              <div className="smallArrowLMobile" id="smallArrowLAshghalMobile"      onClick={SwapVideosForAshghal}></div>
              <div className="smallArrowLMobile" id="smallArrowLEduMobile"          onClick={SwapVideosForEdu}></div>
              <div className="smallArrowLMobile" id="smallArrowLQcbMobile"          onClick={SwapVideosForQcb}></div>
              <div className="smallArrowLMobile" id="smallArrowLJusticeMobile"      onClick={SwapVideosForJustice}></div>
              <div className="smallArrowLMobile" id="smallArrowLCyberMobile"        onClick={SwapVideosForCyber}></div>
              <div className="smallArrowLMobile" id="smallArrowLAtfalnaMobile"      onClick={SwapVideosForAtfalna}></div>
              <div className="smallArrowRMobile" id="smallArrowRMunMobile"          onClick={SwapVideosForMunByminus}></div>
              <div className="smallArrowRMobile" id="smallArrowRDevelopedMobile"    onClick={SwapVideosForDeveloped}></div>
              <div className="smallArrowRMobile" id="smallArrowRQatarCharityMobile" onClick={SwapVideosForQatarCharityByminus}></div>
              <div className="smallArrowRMobile" id="smallArrowRAshghalMobile"      onClick={SwapVideosForAshghalByminus}></div>
              <div className="smallArrowRMobile" id="smallArrowREduMobile"          onClick={SwapVideosForEduByminus}></div>
              <div className="smallArrowRMobile" id="smallArrowRQcbMobile"          onClick={SwapVideosForQcbByminus}></div>
              <div className="smallArrowRMobile" id="smallArrowRJusticeMobile"      onClick={SwapVideosForJusticeByminus}></div>
              <div className="smallArrowRMobile" id="smallArrowRCyberMobile"        onClick={SwapVideosForCyberByminus}></div>
              <div className="smallArrowRMobile" id="smallArrowRAtfalnaMobile"      onClick={SwapVideosForAtfalnaByminus}></div>
              
              <div className="MainPicForProjectMobile" id="MainPicForProject1">
                <video  poster={VideoPoliceImg}               controls  controlsList="nodownload" src={policeVideo} type="video/mp4"  className="MainPicMobile" id="MainPic1"></video>
              </div>

              <div className="MainPicForProjectMobile" id="MainPicForProject2">
                <video  poster={Mun1Thumbnail}                controls  controlsList="nodownload" src={MunVideo1} type="video/mp4"   className="MainPicMobile" id="MainPic2"></video>
                <video  poster={Mun2Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo2} type="video/mp4"   className="MainPicMobile" style={{visibility:"hidden"}} id="Munvideo11"></video>
                <video  poster={Mun3Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo3} type="video/mp4"   className="MainPicMobile" style={{visibility:"hidden"}} id="Munvideo22"></video>
                <video  poster={Mun4Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo4} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Munvideo33"></video>
                <video  poster={Mun5Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo5} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Munvideo44"></video>
                <video  poster={Mun6Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo6} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Munvideo55"></video>
              </div>

              <div className="MainPicForProjectMobile" id="MainPicForProject3">
                <video  poster={Devloped1Thumbnail} preload="none"  controls   src={Devloped1Video} type="video/mp4"  className="MainPicMobile" id="MainPic3"></video>
                <video  poster={Devloped2Thumbnail} preload="none"  controls   src={Devloped2Video} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Developedvideo11"></video>
              </div>

              <div className="MainPicForProjectMobile" id="MainPicForProject4">
                <video poster={AtfalnaThumbnail1}                controls  controlsList="nodownload" src={AtfalnaVideo1} type="video/mp4"  className="MainPicMobile" id="MainPic4"></video>
                <video poster={AtfalnaThumbnail2} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo2} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Atfalnavideo11"></video>
                <video poster={AtfalnaThumbnail3} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo3} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Atfalnavideo22"></video>
                <video poster={AtfalnaThumbnail4} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo4} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Atfalnavideo33"></video>
                <video poster={AtfalnaThumbnail5} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo5} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Atfalnavideo44"></video>
                <video poster={AtfalnaThumbnail6} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo6} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Atfalnavideo55"></video>

              </div>

              <div className="MainPicForProjectMobile" id="MainPicForProject5">
                <video  poster={CyberThumbnail1}                controls  controlsList="nodownload" src={CyberVideo1} type="video/mp4"  className="MainPicMobile" id="MainPic5"></video>
                <video  poster={CyberThumbnail2} preload="none" controls  controlsList="nodownload" src={CyberVideo2} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Cybervideo11"></video>
                <video  poster={CyberThumbnail3} preload="none" controls  controlsList="nodownload" src={CyberVideo3} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Cybervideo22"></video>
                <video  poster={CyberThumbnail4} preload="none" controls  controlsList="nodownload" src={CyberVideo4} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Cybervideo33"></video>
                <video  poster={CyberThumbnail5} preload="none" controls  controlsList="nodownload" src={CyberVideo5} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Cybervideo44"></video>
                
              </div>
              <div className="MainPicForProjectMobile" id="MainPicForProject6">
                <video poster={Jutice1Thumbnail}                 controls  controlsList="nodownload" src={JuticeVideo1} type="video/mp4"  className="MainPicMobile" id="MainPic6"></video>
                <video poster={Jutice2Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo2} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Justicevideo11"></video>
                <video poster={Jutice3Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo3} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Justicevideo22"></video>
                <video poster={Jutice4Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo4} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Justicevideo33"></video>
                <video poster={Jutice5Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo5} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Justicevideo44"></video>
                <video poster={Jutice6Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo6} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Justicevideo55"></video>
                <video poster={Jutice7Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo7} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Justicevideo66"></video>
                <video poster={Jutice8Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo8} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Justicevideo77"></video>

              </div>
              <div className="MainPicForProjectMobile" id="MainPicForProject7">
                <video  poster={QatarCharity1Thumbnail}                 controls  controlsList="nodownload" src={QatarCharity1Video} type="video/mp4"  className="MainPicMobile" id="MainPic7"></video>
                <video  poster={QatarCharity2Thumbnail} preload="none"  controls  controlsList="nodownload" src={QatarCharity2Video} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="QatarCharityvideo11"></video>
                <video  poster={QatarCharity3Thumbnail} preload="none"  controls  controlsList="nodownload" src={QatarCharity3Video} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="QatarCharityvideo22"></video>
              
              </div>
              <div className="MainPicForProjectMobile" id="MainPicForProject8">
                <video  poster={AshghalThumbnail1}                 controls  controlsList="nodownload" src={AshghalVideo1} type="video/mp4"   className="MainPicMobile" id="MainPic8"></video>
                <video  poster={AshghalThumbnail2} preload="none"  controls  controlsList="nodownload" src={AshghalVideo2} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Ashghalvideo11"></video>
                <video  poster={AshghalThumbnail3} preload="none"  controls  controlsList="nodownload" src={AshghalVideo3} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Ashghalvideo22"></video>
              </div>
              <div className="MainPicForProjectMobile" id="MainPicForProject9">
                <video  poster={EduThumbnail1}                controls  controlsList="nodownload" src={EduVideo1} type="video/mp4"  className="MainPicMobile" id="MainPic9"></video>
                <video  poster={EduThumbnail2} preload="none" controls  controlsList="nodownload" src={EduVideo2} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Eduvideo11"></video>
                <video  poster={EduThumbnail3} preload="none" controls  controlsList="nodownload" src={EduVideo3} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Eduvideo22"></video>
                <video  poster={EduThumbnail4} preload="none" controls  controlsList="nodownload" src={EduVideo4} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Eduvideo33"></video>

                  </div>
              <div className="MainPicForProjectMobile" id="MainPicForProject10">
                <video  poster={QcbThumbnail1}                controls  controlsList="nodownload" src={QcbVideo1} type="video/mp4"  className="MainPicMobile" id="MainPic10"></video>
                <video  poster={QcbThumbnail2} preload="none" controls  controlsList="nodownload" src={QcbVideo2} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Qcbvideo11"></video>
                <video  poster={QcbThumbnail3} preload="none" controls  controlsList="nodownload" src={QcbVideo3} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Qcbvideo22"></video>
                <video  poster={QcbThumbnail4} preload="none" controls  controlsList="nodownload" src={QcbVideo4} type="video/mp4"  className="MainPicMobile" style={{visibility:"hidden"}} id="Qcbvideo33"></video>

              </div>
              <div className="ProjectsTextMobile" id="PoliceTextMobile">
              لمواكبة التطور في نظام العمل الأمني أنشأ معهد تدريب الشرطة منصة تدريبية وقاعدة بيانات متكاملة لخدمة منتسبي المعهد.

              </div>
               <div className="ProjectsTextMobile" id="MunTextMobile">
               التوعوية بالقوانين والغرامات المالية المترتبة على مخالفة قانون النظافة العامة وغيرها بما يتناسب مع مختلف الشرائح المجتمع والفئات العمرية
              </div>
              <div className="ProjectsTextMobile" id="DevLearnTextMobile">
              تدعم العملية التعليمية وتنمية المهارات عن طريق تطوير النظم التعليمية الالكترونية التفاعلية وإدراج محتوى رقمي متعدد الوسائط.
              </div>
              <div className="ProjectsTextMobile" id="AtfalnaTextMobile">
              تطبيق أطفالنا هـو مشروع طموح ومنتج فــــريد تطوره ثلاثة أقسام عاملة بالدوحة لينك
              </div>
              <div className="ProjectsTextMobile" id="CyberTextMobile">
              تدريب الأمن السيبراني وطوير المواد التدريبية للدبلوماسيين وموظفي وزارة الخارجية والفئات الأخرى التابعة للوزارة داخل وخارج دولة قطر.
              </div>
              <div className="ProjectsTextMobile" id="JusticeTextMobile">
              مشروع التثقيف القانوني لمختلف الشرائح المجتمع حول التعاقد والعقود
              </div>
              <div className="ProjectsTextMobile" id="QatarCharityTextMobile">
              إنتاج سلسة أفلام كرتونية (إنيميشن) عالية الجودة ضمن حملة تثقيفية للأطفال وحثهم على عمل الخير.
              </div>
              <div className="ProjectsTextMobile" id="AshghalTextMobile">
              تعريف العامة بالهيئة وأعمالها وإنجازاتها وتكوين صورة إيجابية عنها.            
                </div>
              <div className="ProjectsTextMobile" id="SoialTextMobile">
              ترسيخ حضور الوزارة على مواقع التواصل الاجتماعي، ويشمل ذلك إدارة منصاتها تقديم تصاميم جذابة وكتابة المحتوى، بالإضافة الى النشر في البوابة الداخلية لموقعها.     
                   </div>
              <div className="ProjectsTextMobile" id="QatarCenterTextMobile">
              تعريف العامة بأعمال المركز وخدماته وكيفية التسجيل الاستفادة من الخدمات.       
              </div>
              <img className="ProjectsDohaLogo" fetchpriority="high"  loading="lazy" id="ProjectsDohaLogo" src={ProjectsDohaLogo}  alt="ProjectsDohaLogo"></img>
              <img className="PoliceProjectLogo" fetchpriority="high"  loading="lazy" id="PoliceProjectLogo" src={PoliceProjectLogo}  alt="PoliceProjectLogo"></img>
              <img className="MunProjectLogo" fetchpriority="high"  loading="lazy" id="MunProjectLogo" src={MunProjectLogo}  alt="MunProjectLogo"></img>
              <img className="DevLearnProjectLogo" fetchpriority="high"  loading="lazy" id="DevLearnProjectLogo" src={DevLearnProjectLogo}  alt="DevLearnProjectLogo"></img>
              <img className="AtfalnaProjectLogo" fetchpriority="high"  loading="lazy" id="AtfalnaProjectLogo" src={AtfalnaProjectLogo}  alt="AtfalnaProjectLogo"></img>
              <img className="CyberProjectLogo" fetchpriority="high"  loading="lazy" id="CyberProjectLogo" src={CyberProjectLogo}  alt="CyberProjectLogo"></img>
              <img className="JusticeProjectLogo" fetchpriority="high"  loading="lazy" id="JusticeProjectLogo" src={JusticeProjectLogo}  alt="CyberProjectLogo"></img>
              <img className="QatarCharityProjectLogo"fetchpriority="high"  loading="lazy" id="QatarCharityProjectLogo" src={QatarCharityProjectLogo}  alt="QatarCharityProjectLogo"></img>
              <img className="AshghalProjectLogo" fetchpriority="high"  loading="lazy" id="AshghalProjectLogo" src={AshghalProjectLogo}  alt="AshghalProjectLogo"></img>
              <img className="SoialProjectLogo"fetchpriority="high"  loading="lazy" id="SoialProjectLogo" src={SoialProjectLogo}  alt="SoialProjectLogo"></img>
              <img className="QatarCenterProjectLogo" fetchpriority="high"  loading="lazy" id="QatarCenterProjectLogo" src={QatarCenterProjectLogo}  alt="QatarCenterProjectLogo"></img>

              
              
              
            
              
              <div className="DevelopedMobileIconContainer"    id="DevelopedMobileIconContainer" onClick={goToDevLearnMobile}></div>
              <div className="RoundContainerMobile" id="RoundContainerMobile2" style={{ top: '33%',marginLeft:'34.1vw'}} >
              <p className="RoundContainerTextMobile" style={{ fontSize: '3vw'}}>وزارة التربية والتعليم
والتعليم العالي  
                <span  style={{ fontSize: '2.7vw',top:'0%'}}><br/>تطوير التعليم الإلكتروني
              </span></p>
       
                  </div>
              <div className="CyberobileIconContainer"         id="CyberobileIconContainer" onClick={goToCyberMobile}></div>
              <div className="RoundContainerMobile" id="RoundContainerMobile3" style={{ top: '48%', marginLeft:'34.1vw'}} >
              <p className="RoundContainerTextMobile" >وزارة الخارجية
   
                <span  style={{ fontSize: '2.817vw',top:'0%'}}><br/>تدريب الأمن السيبراني
              </span></p>

                  </div>
                  
              <div className="QatarCharityMobileIconContainer" id="QatarCharityMobileIconContainer" onClick={goToQatarCharityMobile}></div>
              <div className="RoundContainerMobile" id="RoundContainerMobile4" style={{ top: '64%',  marginLeft:'34.1vw'}} >
              <p className="RoundContainerTextMobile">قطر الخيرية
  
   
   <span  style={{ fontSize: '2.817vw',top:'0%'}}><br/>أفلام كرتونية
 </span></p>

                  </div>
                 
              <div className="soialMediaMobileIconContainer"   id="soialMediaMobileIconContainer" onClick={goToSoialMobile}></div>
              <div className="RoundContainerMobile" id="RoundContainerMobile5" style={{ top: '80%', marginLeft:'34.1vw'}} >
                  
              <p className="RoundContainerTextMobile" style={{ fontSize: '3vw'}}>وزارة التربية والتعليم
والتعليم العالي
              <span  style={{ fontSize: '2.4vw',top:'0%'}}><br/>إدارة منصات التواصل الاجتماعي
            </span></p>
                  </div>
              <div className="MunMobileIconContainer"         id="MunMobileIconContainer" onClick={goToMunMobile}></div>
              <div className="RoundContainerMobile" id="RoundContainerMobile6"  style={{ top: '25%', marginLeft:'16vw'}} >
              <p className="RoundContainerTextMobile" style={{ marginLeft:'15%'}}>وزارةالبلدية والبيئة
              <span  style={{ fontSize: '2.817vw',top:'0%'}}><br/>حملة توعية
            </span></p>   
           

                  </div>
              <div className="AtfalanaMobileIconContainer"     id="AtfalanaMobileIconContainer" onClick={goToAtfalnaMobile}></div>
              <div className="RoundContainerMobile" id="RoundContainerMobile7"  style={{ top: '41%',  marginLeft:'16vw'}} >
              <p className="RoundContainerTextMobile" style={{ marginLeft:'15%'}}> تطبيق أطفالنا
              </p>   

                  </div>
              <div className="JusticeMobileIconContainer"      id="JusticeMobileIconContainer" onClick={goToJusticeMobile}></div>
              <div className="RoundContainerMobile" id="RoundContainerMobile8"  style={{ top: '56.2%',marginLeft:'16vw'}} >
              <p className="RoundContainerTextMobile" style={{ marginLeft:'15%'}}>وزارة العدل
              <span  style={{ fontSize: '2.817vw',top:'0%'}}><br/>حملة توعوية
            </span></p> 
          
                  </div>
              <div className="PrAshaalMobileIconContainer"     id="PrAshaalMobileIconContainer" onClick={goToAshghalMobile}></div>
              <div className="RoundContainerMobile" id="RoundContainerMobile9"  style={{ top: '72%', marginLeft:'16vw'}}  >
              <p className="RoundContainerTextMobile" style={{ marginLeft:'15%'}}>هيئة أشغال العامة
              <span  style={{ fontSize: '2.817vw',top:'0%'}}><br/> حملة علاقات عامة
            </span></p>   
          
                  </div>
              <div className="PrQimccMobileIconContainer"      id="PrQimccMobileIconContainer" onClick={goToQatarCenterMobile}></div>
              <div className="RoundContainerMobile"  id="RoundContainerMobile10"  style={{ top: '87%', marginLeft:'16vw'}} >
              <p className="RoundContainerTextMobile" style={{ marginLeft:'13%'}}> مركز قطر للمعلومات
      الائتمانية
              <span  style={{ fontSize: '2.4vw',top:'0%'}}><br/>حملة علاقات عامة
            </span></p>     

              </div>
             
          </div>
        }
        {(!isMobile)&&  
         
<div className="container">
        <div className="ProjectBackgroundContainer" id="ProjectBackgroundContainer">
            <img className="ProjectBackgroundImg" id ="ProjectBackgroundImg" src={ProjectBackgroundImg}  alt="ProjectBackgroundImg"></img>
        </div>
  <div className=" col-1 " >
 
  </div>
  <div className=" col-2 ">
 
  </div>
  <div className="col-3" >
    
  </div>
  <div className="col-4">
    
  </div>
  <div className="col-5" >
      <div className="RoundContainer" style={{ marginLeft:'0vw'}} id="awarnessCamp" onClick={goPRashghalPro}>
        <p className="RoundContainerText" id="awarnessCampText">هيئة أشغال العامة
          <p className="RoundContainerText" id="second"style={{ fontSize: '0.8vw',top:'70.425%'}}>(حملة علاقات عامة)
          </p>
        </p>
        <span className="RoundContainerText2"id="awarnessCampPharagaph" style={{ top:'50%',fontSize: '0.8vw',lineHeight: '0.8vw'}} >
        تعريف العامة بالهيئة وأعمالها وإنجازاتها وتكوين صورة إيجابية عنها.
        </span>
      </div>
      <div className="RoundContainer" style={{ top: '33.425%',marginLeft:'0vw'}} id="SoialCamp" onClick={goSoialPro}>
      <p className="RoundContainerText" id="SoialCampText" style={{ fontSize: '0.8vw'}}>وزارة التربية والتعليم 
و التعليم العالي
  

      <p className="RoundContainerText" id="second"style={{ fontSize: '0.8vw',top:'70.425%'}}>إدارة منصات التواصل الاجتماعي
      </p></p>

      <span className="RoundContainerText2" id="SoialCamppharagraph" style={{ top:'35%',fontSize: '0.7vw',lineHeight: '0.8vw'}} >
      ترسيخ حضور الوزارة على مواقع التواصل الاجتماعي، ويشمل ذلك إدارة منصاتها تقديم تصاميم جذابة وكتابة المحتوى، بالإضافة الى النشر في البوابة الداخلية لموقعها. </span>
      </div>
      <div className="RoundContainer" style={{ top: '52.4%',marginLeft:'0vw'}} id="QatarCenterCamp" onClick={goPRQatarPro}>
      <p className="RoundContainerText" id="QatarCenterCampText">مركز قطر للمعلومات الائتمانية


      <p className="RoundContainerText" id="second"style={{ fontSize: '1vw',top:'70.425%'}}>حملة علاقات عامة
      </p></p>

      <span className="RoundContainerText2" id="QatarCenterCampPhargraph" style={{ top:'50%',fontSize: '0.8vw',lineHeight: '0.8vw'}} >
      تعريف العامة بأعمال المركز وخدماته وكيفية التسجيل الاستفادة من الخدمات.
 </span>
      </div>
  </div>
  <div className="col-6">
    <div className="smallArrowL" id="smallArrowL"             onClick={SwapVideosForMun}></div>
    <div className="smallArrowL" id="smallArrowLDeveloped"    onClick={SwapVideosForDeveloped}></div>
    <div className="smallArrowL" id="smallArrowLQatarCharity" onClick={SwapVideosForQatarCharity}></div>
    <div className="smallArrowL" id="smallArrowLAshghal"      onClick={SwapVideosForAshghal}></div>
    <div className="smallArrowL" id="smallArrowLEdu"          onClick={SwapVideosForEdu}></div>
    <div className="smallArrowL" id="smallArrowLQcb"          onClick={SwapVideosForQcb}></div>
    <div className="smallArrowL" id="smallArrowLJustice"      onClick={SwapVideosForJustice}></div>
    <div className="smallArrowL" id="smallArrowLCyber"        onClick={SwapVideosForCyber}></div>
    <div className="smallArrowL" id="smallArrowLAtfalna"      onClick={SwapVideosForAtfalna}></div>

      <div className="PR1Container"   id="PR1Container"   onClick={goPRashghalPro}> </div>
      <div className="SoialContainer" id="SoialContainer" onClick={goSoialPro}> </div>
      <div className="PR2Container"   id="PR2Container"   onClick={goPRQatarPro}> </div>
  </div>
  <div className="col-7" >
 
    <div className="MainPicForProject" id="MainPicForProject1">
      <video  poster={VideoPoliceImg}               controls  controlsList="nodownload" src={policeVideo} type="video/mp4"  className="MainPic" id="MainPic1"></video>
    </div>
    <div className="MainPicForProject" id="MainPicForProject2">
      <video  poster={Mun1Thumbnail}                controls  controlsList="nodownload" src={MunVideo1} type="video/mp4"  className="MainPic" id="MainPic2"></video>
      <video  poster={Mun2Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo2} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Munvideo11"></video>
      <video  poster={Mun3Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo3} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Munvideo22"></video>
      <video  poster={Mun4Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo4} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Munvideo33"></video>
      <video  poster={Mun5Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo5} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Munvideo44"></video>
      <video  poster={Mun6Thumbnail} preload="none" controls  controlsList="nodownload" src={MunVideo6} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Munvideo55"></video>
    </div>
    <div className="MainPicForProject" id="MainPicForProject3">
      <video  poster={Devloped1Thumbnail} preload="none"  controls   src={Devloped1Video} type="video/mp4"  className="MainPic" id="MainPic3"></video>
      <video  poster={Devloped2Thumbnail} preload="none"  controls   src={Devloped2Video} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Developedvideo11"></video>
    </div>
    <div className="MainPicForProject" id="MainPicForProject4">
      <video poster={AtfalnaThumbnail1}                controls  controlsList="nodownload" src={AtfalnaVideo1} type="video/mp4"  className="MainPic" id="MainPic4"></video>
      <video poster={AtfalnaThumbnail2} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo2} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Atfalnavideo11"></video>
      <video poster={AtfalnaThumbnail3} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo3} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Atfalnavideo22"></video>
      <video poster={AtfalnaThumbnail4} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo4} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Atfalnavideo33"></video>
      <video poster={AtfalnaThumbnail5} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo5} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Atfalnavideo44"></video>
      <video poster={AtfalnaThumbnail6} preload="none" controls  controlsList="nodownload" src={AtfalnaVideo6} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Atfalnavideo55"></video>

    </div>
    <div className="MainPicForProject" id="MainPicForProject5">
      <video  poster={CyberThumbnail1}                controls  controlsList="nodownload" src={CyberVideo1} type="video/mp4"  className="MainPic" id="MainPic5"></video>
      <video  poster={CyberThumbnail2} preload="none" controls  controlsList="nodownload" src={CyberVideo2} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Cybervideo11"></video>
      <video  poster={CyberThumbnail3} preload="none" controls  controlsList="nodownload" src={CyberVideo3} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Cybervideo22"></video>
      <video  poster={CyberThumbnail4} preload="none" controls  controlsList="nodownload" src={CyberVideo4} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Cybervideo33"></video>
      <video  poster={CyberThumbnail5} preload="none" controls  controlsList="nodownload" src={CyberVideo5} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Cybervideo44"></video>
     
    </div>
    <div className="MainPicForProject" id="MainPicForProject6">
      <video poster={Jutice1Thumbnail}                 controls  controlsList="nodownload" src={JuticeVideo1} type="video/mp4"  className="MainPic" id="MainPic6"></video>
      <video poster={Jutice2Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo2} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Justicevideo11"></video>
      <video poster={Jutice3Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo3} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Justicevideo22"></video>
      <video poster={Jutice4Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo4} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Justicevideo33"></video>
      <video poster={Jutice5Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo5} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Justicevideo44"></video>
      <video poster={Jutice6Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo6} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Justicevideo55"></video>
      <video poster={Jutice7Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo7} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Justicevideo66"></video>
      <video poster={Jutice8Thumbnail} preload="none"  controls  controlsList="nodownload" src={JuticeVideo8} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Justicevideo77"></video>

    </div>
    <div className="MainPicForProject" id="MainPicForProject7">
      <video  poster={QatarCharity1Thumbnail}                 controls  controlsList="nodownload" src={QatarCharity1Video} type="video/mp4"  className="MainPic" id="MainPic7"></video>
      <video  poster={QatarCharity2Thumbnail} preload="none"  controls  controlsList="nodownload" src={QatarCharity2Video} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="QatarCharityvideo11"></video>
      <video  poster={QatarCharity3Thumbnail} preload="none"  controls  controlsList="nodownload" src={QatarCharity3Video} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="QatarCharityvideo22"></video>
    
    </div>
    <div className="MainPicForProject" id="MainPicForProject8">
      <video  poster={AshghalThumbnail1}                 controls  controlsList="nodownload" src={AshghalVideo1} type="video/mp4"  className="MainPic" id="MainPic8"></video>
      <video  poster={AshghalThumbnail2} preload="none"  controls  controlsList="nodownload" src={AshghalVideo2} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Ashghalvideo11"></video>
      <video  poster={AshghalThumbnail3} preload="none"  controls  controlsList="nodownload" src={AshghalVideo3} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Ashghalvideo22"></video>
    </div>
    <div className="MainPicForProject" id="MainPicForProject9">
      <video  poster={EduThumbnail1}                controls  controlsList="nodownload" src={EduVideo1} type="video/mp4"  className="MainPic" id="MainPic9"></video>
      <video  poster={EduThumbnail2} preload="none" controls  controlsList="nodownload" src={EduVideo2} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Eduvideo11"></video>
      <video  poster={EduThumbnail3} preload="none" controls  controlsList="nodownload" src={EduVideo3} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Eduvideo22"></video>
      <video  poster={EduThumbnail4} preload="none" controls  controlsList="nodownload" src={EduVideo4} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Eduvideo33"></video>

       </div>
    <div className="MainPicForProject" id="MainPicForProject10">
      <video  poster={QcbThumbnail1}                controls  controlsList="nodownload" src={QcbVideo1} type="video/mp4"  className="MainPic" id="MainPic10"></video>
      <video  poster={QcbThumbnail2} preload="none" controls  controlsList="nodownload" src={QcbVideo2} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Qcbvideo11"></video>
      <video  poster={QcbThumbnail3} preload="none" controls  controlsList="nodownload" src={QcbVideo3} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Qcbvideo22"></video>
      <video  poster={QcbThumbnail4} preload="none" controls  controlsList="nodownload" src={QcbVideo4} type="video/mp4"  className="MainPic" style={{visibility:"hidden"}} id="Qcbvideo33"></video>

    </div>
    
  <div className="RoundContainer" style={{ marginLeft:'5.729vw'}} onClick={goAtfalnaPro} id="atfalnaApp">
            <p className="RoundContainerText" id="atfalnaAppText">تطبيق أطفالنا 
            </p>
            <span className="RoundContainerText2" id="atfalnaAppPharagph" >
            تطبيق أطفالنا هـو مشروع طموح ومنتج
فــــريد تطوره ثلاثة أقسام عاملة بالدوحة
لينك</span>
      
  </div>
 <div className="RoundContainer" style={{ top: '33.425%', marginLeft:'5.729vw'}} onClick={goCyberPro} id="CyberTraining">
 <p className="RoundContainerText" style={{ left:'47%'}} id="CyberTrainingText">وزارة الخارجية

  
 <p className="RoundContainerText" id="second"style={{ fontSize: '0.8vw',top:'70.425%'}}>تدريب الأمن السيبراني

</p></p>

 <span className="RoundContainerText2" style={{ fontSize: '0.7vw'}} id="CyberTrainingPharagph" >
 تدريب الأمن السيبراني وطوير المواد التدريبية للدبلوماسيين وموظفي وزارة الخارجية والفئات الأخرى التابعة للوزارة داخل وخارج دولة قطر.


 </span>
 </div>
 <div className="RoundContainer" style={{ top: '52.4%', marginLeft:'5.729vw'}} id="LawAwareness" onClick={goFairnessPro}>
 <p className="RoundContainerText" style={{ marginLeft:'0.5vw'}} id="LawAwarenessText">وزارة العدل
  
  <p className="RoundContainerText" id="second"style={{ fontSize: '0.8vw',top:'70.425%'}}>حملة توعوية

 </p></p>
 
  <span className="RoundContainerText2" style={{ top:'50%',fontSize: '0.7vw',lineHeight: '0.8vw'}} id="LawAwarenessPhargaph">
  مشروع التثقيف القانوني لمختلف الشرائح المجتمع حول التعاقد والعقود.

   </span>
 </div>
 <div className="RoundContainer" style={{ top: '72.31%', marginLeft:'5.729vw'}} id="QatarCharityVideo" onClick={goQatarCharityPro}>
 <p className="RoundContainerText" id="QatarCharityVideoText" style={{ marginLeft:'0.5vw'}}>قطر الخيرية

  
<p className="RoundContainerText" id="second"style={{ fontSize: '0.7vw',top:'70.425%'}}>أفلام كرتونية
</p></p>

<span className="RoundContainerText2" style={{ top:'45%',fontSize: '0.8vw',lineHeight: '1vw'}}   id="QatarCharityVideoPhargraph">
إنتاج سلسة أفلام كرتونية (إنيميشن) عالية الجودة ضمن حملة تثقيفية للأطفال وحثهم على عمل الخير.</span>
 </div>

  </div>
  <div className="col-8 ">
    <div className="OurProjectsText11" id="OurProjectsText11" onClick={ReturnToOurProjects}>
      <span className="OurProjectsTextHeader">مشاريعنا</span>
    </div>
  </div>
  <div className="col-9" >
    <div className="AtfalanaContainer"     id="AtfalanaContainer"     onClick={goAtfalnaPro}> </div>
    <div className="FaienessContainer"     id="FaienessContainer"     onClick={goFairnessPro}> </div>
    <div className="CyberContainer"        id="CyberContainer"        onClick={goCyberPro}>  </div>
    <div className="QatarCharityContainer" id="QatarCharityContainer" onClick={goQatarCharityPro} > </div>
  </div>
  <div className="col-10" >
    <div className="BackArrow" id="BackArrow" onClick={ReturnToOurProjects} >

    </div>
 <div className="RoundContainer" onClick={goToPoliceVideos} id="PoliceVideos"> 
 <p className="RoundContainerText" id="PoliceVideosText">معهد تدريب الشرطة
 <p className="RoundContainerText" id="PoliceVideosText2"style={{ fontSize: '0.8vw',top:'70.425%'}}>فيديوهات إرشادية
</p>
 </p>
 <span className="RoundContainerText2" id="PoliceVideosPhargraph" style={{ fontSize: '0.7vw'}} >
 لمواكبة التطور في نظام العمل الأمني أنشأ معهد تدريب الشرطة منصة تدريبية وقاعدة بيانات متكاملة لخدمة منتسبي المعهد.

</span>
 </div>
 <div className="RoundContainer" style={{ top: '33.425%'}} id="MunpilcyMinistry" onClick={goToMunplicyMinistryPro}> 
 <p className="RoundContainerText" id="MunpilcyMinistryText"> وزارة البلدية والبيئة
 <p className="RoundContainerText" id="second"style={{ fontSize: '0.8vw',top:'70.425%'}}>حملة توعية

</p></p>

 <span className="RoundContainerText2" id="MunpilcyMinistryPhargraph"style={{top:'40%'}} >
 التوعوية بالقوانين والغرامات المالية المترتبة على مخالفة قانون النظافة العامة وغيرها بما يتناسب مع مختلف الشرائح المجتمع والفئات العمرية
 
</span>
 </div>
 <div className="RoundContainer" style={{ top: '52.4%'}} id="DevlopApps" onClick={goToDepLearnPro}>
 <p className="RoundContainerText" id="DevelopedAppText"style={{ fontSize: '0.88vw',left:'43%'}}>البرامج التعليمية المطورة
 <p className="RoundContainerText" id="second"style={{ fontSize: '0.6vw',top:'70.425%'}}>(وزارة التربية والتعليم
والتعليم العالي)
</p>
 </p>
 <span className="RoundContainerText2" id="DevelopedAppPharaghap" >
 إحدى التخصصات الرئيسية التي تعمل بها
الدوحة لينك هي تطوير المناهج التعليمية
ومنصات التعلم الإلكتروني
 </span>
 </div>
  </div>
   <div className="col-11">
  </div>
  <div className="col-12" >

    <div className="smallArrowR" id="smallArrowR"             onClick={SwapVideosForMunByminus}></div>
    <div className="smallArrowR" id="smallArrowRDeveloped"    onClick={SwapVideosForDeveloped}></div>
    <div className="smallArrowR" id="smallArrowRQatarCharity" onClick={SwapVideosForQatarCharityByminus}></div>
    <div className="smallArrowR" id="smallArrowRAshghal"      onClick={SwapVideosForAshghalByminus}></div>
    <div className="smallArrowR" id="smallArrowREdu"          onClick={SwapVideosForEduByminus}></div>
    <div className="smallArrowR" id="smallArrowRQcb"          onClick={SwapVideosForQcbByminus}></div>
    <div className="smallArrowR" id="smallArrowRJustice"      onClick={SwapVideosForJusticeByminus}></div>
    <div className="smallArrowR" id="smallArrowRCyber"        onClick={SwapVideosForCyberByminus}></div>
    <div className="smallArrowR" id="smallArrowRAtfalna"      onClick={SwapVideosForAtfalnaByminus}></div>

    <div className="VideoContainer"     id="VideoContainer"     onClick={goToPoliceVideos} > </div>
    <div className="AwareContainer"     id="AwareContainer"     onClick={goToMunplicyMinistryPro}> </div>
    <div className="DevelopedContainer" id="DevelopedContainer" onClick={goToDepLearnPro}> </div>
  </div>
  
</div>
 }
 </div>
        
      );
    
  }
  
  export default Projects;