import React, {useRef , useState }  from "react";

import "./ContactUs.css";
import ContactUsImg from './Images/Background4ourServices.png';
import twitterImg from './Images/Xlogo.png';
import facebookImg from './Images/Group 26.png';
import instagramImg from './Images/Group 24.png';
import linkedinImg from './Images/Group 29.png';
import googleImg from './Images/Group 27.png';
import locationIcon from './Images/Group 32.png';
import MobileIcon from './Images/Group 37.png';
import EmailIcon from './Images/Group 34.png';
import SuccessParatnersIcon1 from './Images/CivilLogo.png';
import SuccessParatnersIcon2 from './Images/MOEHELogo.png';
import SuccessParatnersIcon3 from './Images/MOILogo.png';
import SuccessParatnersIcon4 from './Images/MOALogo.png';
import SuccessParatnersIcon5 from './Images/MOJLogo.png';
import SuccessParatnersIcon6 from './Images/AspireLogo.png';
import SuccessParatnersIcon7 from './Images/Ash3alLogo.png';
import SuccessParatnersIcon8 from './Images/QatarCharityLogo.png';
import SuccessParatnersIcon9 from './Images/QCBLogo.png';
import SuccessParatnersIcon10 from './Images/MunMinistryLogo.png';
import Partner1 from './Images/MoeImg.png';
import Partner2 from './Images/MunImg.png';
import Partner3 from './Images/QfMobile.png';
import Partner4 from './Images/JusticeMobile (2).png';
import Partner5 from './Images/MoiMobile.png';
import Partner6 from './Images/AshghalMobile (2).png';
import Partner7 from './Images/AspireMobile.png';
import BackgroundMobile from './Images/ContactUsMobile.png';

import flags from 'react-phone-number-input/flags';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import emailjs from '@emailjs/browser';
import LogoDohaContactimg from './Images/Group 5181.png';
function ContactUs({ isMobile }){
    const form = useRef();
    const [value, setValue] = useState();
 
    function sendEmail(e) {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”
        var flag1var=false;
        var flag2var=false;
        var flag3var=false;
        var firstnameclient = document.getElementById("fist");
        var lastnameclient = document.getElementById("Last");
        var emailOfclient = document.getElementById("ClientEmail");
        var MobileOfclient = document.getElementById("ClientMobile");
        var wayOfconnection = document.getElementById("Preffered");
        var ClientMessage = document.getElementById("subject");
        console.log("firstName");
        console.log(firstnameclient.value);
        console.log("emailOfclient");
        console.log(emailOfclient.value);
        console.log("MobileOfclient");
        console.log(MobileOfclient.value);
        console.log("wayOfconnection");
        console.log(wayOfconnection.value);
        console.log("ClientMessage");
        console.log(ClientMessage.value);
        if(firstnameclient.value ===""){
            console.log("inside red1");
            firstnameclient.style.borderColor="red";
            firstnameclient.setCustomValidity('first name required');
            firstnameclient.reportValidity();            
        }else{
            firstnameclient.style.borderColor="black";
            console.log("inside black");
            firstnameclient.setCustomValidity('');
         
            flag1var=true;
        }

        if(emailOfclient.value ===""){
            console.log("inside red2");
            emailOfclient.style.borderColor="red";
            emailOfclient.setCustomValidity('Email required');
            emailOfclient.reportValidity();  
        }else{
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailOfclient.value))){
                console.log("inside redzzzz");
                emailOfclient.style.borderColor="red";
                emailOfclient.setCustomValidity('enter valid Email');
                emailOfclient.reportValidity();  
            }else{
            emailOfclient.style.borderColor="black";
            console.log("inside black");
            flag2var=true;
            emailOfclient.setCustomValidity('');
        }
        }
        if(MobileOfclient.value==="+974"){
            console.log("inside red3");
            MobileOfclient.style.borderColor="red";
            MobileOfclient.setCustomValidity('Mobile required');
            MobileOfclient.reportValidity();  
        }else{
            MobileOfclient.style.borderColor="black";
            console.log("inside black");
            
            flag3var=true;
            MobileOfclient.setCustomValidity('');
        }
        /*   toast(' سيتم التواصل معك قريبا تم ارسال الاستفسار بنجاح',
        {position: toast.POSITION.BOTTOM_CENTER});*/
        console.log("flag1");
        console.log(flag1var);
        console.log("flag2");
        console.log(flag2var);
        console.log("flag3");
        console.log(flag3var);
         if(flag3var===true && flag2var===true&&flag1var===true){
            console.log("inside flags");
            emailjs.send("service_ehya999","template_swwew2c",{
                from_name: firstnameclient.value,
                way: wayOfconnection.value,
                message: ClientMessage.value,
                client_number:MobileOfclient.value,
                client_email: emailOfclient.value,
                },"yvcTBIlseB2X62lx8")
                
                
        
              .then((result) => {
                  // show the user a success message
                 
                  lastnameclient.value="";
                  firstnameclient.value="";
                  ClientMessage.value="";
                  MobileOfclient.value="";
                  emailOfclient.value="";
                  var InputsBackgroundRef      = document.getElementById("SubmitBackground");
                  var SubmitBackgroundTextRef1 = document.getElementById("SubmitBackgroundText1");
                  var SubmitBackgroundTextRef2 = document.getElementById("SubmitBackgroundText2");
                  InputsBackgroundRef.style.display     ="block";
                  SubmitBackgroundTextRef1.style.display="block";
                  SubmitBackgroundTextRef2.style.display="block";
                  
                  setTimeout(() => {
                    InputsBackgroundRef.style.display     ="none";
                    SubmitBackgroundTextRef1.style.display="none";
                    SubmitBackgroundTextRef2.style.display="none";
                    }, 5000);
              }, (error) => {
                lastnameclient.value="";
                firstnameclient.value="";
                ClientMessage.value="";
                MobileOfclient.value="";
                emailOfclient.value="";
                var InputsBackgroundRef2      = document.getElementById("SubmitBackground2");
                var SubmitBackgroundTextRef12 = document.getElementById("SubmitBackgroundText12");
                var SubmitBackgroundTextRef22 = document.getElementById("SubmitBackgroundText22");
                InputsBackgroundRef2.style.display     ="block";
                SubmitBackgroundTextRef12.style.display="block";
                SubmitBackgroundTextRef22.style.display="block";
                
                setTimeout(() => {
                  InputsBackgroundRef2.style.display     ="none";
                  SubmitBackgroundTextRef12.style.display="none";
                  SubmitBackgroundTextRef22.style.display="none";
                  }, 5000);
                
              });
         }else{

         }
        
   /**/
      };

    
      return (
           <div className="ContactUsContainerAll">
            {(isMobile)&&
            <div className="ContactUsContainerMobile">
                <div className="MobileBackground">
                    <img className="ContactusMobileBackgroundImg" fetchpriority="high"  loading="lazy" src={BackgroundMobile}></img>
                </div>
                <div className="BackgroundOfLogosMobile"></div>
                <p className="PartnerText">شركاء النجاح</p>
               <img className="Partner1" fetchpriority="high"  loading="lazy" alt="Partner1" src={Partner1} ></img>
               <img className="Partner2" fetchpriority="high"  loading="lazy" alt="Partner2" src={Partner2}></img>
               <img className="Partner3" fetchpriority="high"  loading="lazy" alt="Partner3" src={Partner3}></img>
               <img className="Partner4" fetchpriority="high"  loading="lazy" alt="Partner4" src={Partner4}></img>
               <img className="Partner5" fetchpriority="high"  loading="lazy" alt="Partner5" src={Partner5}></img>
               <img className="Partner6" fetchpriority="high"  loading="lazy" alt="Partner6"  src={Partner6}></img>
               <img className="Partner7" fetchpriority="high"  loading="lazy" alt="Partner7" src={Partner7}></img>
               <p className="ContactusText">تواصل معنا</p>
               <form ref={form}>
               <div className="SubmitBackgroundMobile" id="SubmitBackground"> </div>
               <p className="SubmitBackgroundTextMobile" id="SubmitBackgroundText1" style={{ marginLeft: '40%'}}>تم ارسال الطلب بنجاح</p>
          <p className="SubmitBackgroundTextMobile" id="SubmitBackgroundText2"  style={{ top: '78%',marginLeft: '37%'}}>سيتم التواصل معك قريبا</p> 
          <div className="SubmitBackgroundMobile2" id="SubmitBackground2"> </div>
               <p className="SubmitBackgroundTextMobile" id="SubmitBackgroundText12" style={{ marginLeft: '40%'}}> لم يتم الطلب للاسف</p>
          <p className="SubmitBackgroundTextMobile" id="SubmitBackgroundText22"  style={{ top: '78%',marginLeft: '43%'}}>حاول مرة اخرى</p> 
        
        <div className="InputsBackgroundMobile" id="InputsBackground">
        <div className="d-inputMobile">
        <span className="resizeMobile" id="resize">
        <input  type="text" placeholder="First Name*" id="fist"/>

        </span>
        <span className="resizeMobile" >
        <input type="text" placeholder="Last Name" id="Last"/>
        </span>
        </div>
        <div className="d-inputMobile">
        <span className="resizeMobile">
        <input  type="email" placeholder="Email*" id="ClientEmail"/>
        </span>
        <PhoneInput 
        className="InputPhoneNumberMobile"
        international
        countryCallingCodeEditable={false}
            flags={flags}
            id="ClientMobile"
            defaultCountry="QA"
            value={value}
            onChange={setValue} />

        </div>
        <p className="PrefferedTextMobile">Preferred method of contact</p>
        <div className="d-input1Mobile">
        <select className="SelectInputMobile"id="Preffered" name="Preffered">
            <option value="phone">Phone</option>
            <option value="email">E-mail</option>
        </select>


        </div>
<div className="d-inputMobile">

<textarea className="textareaMobile"id="subject" name="subject" placeholder="Write your message.." ></textarea>


</div>
<div className="d-input2Mobile">
<span className="resize2">
<input className="SubmitBtnMobile" type="submit" value="Submit" onClick={sendEmail}></input>

  </span>
  </div>

                </div>
                </form>
            </div>
            }
            {(!isMobile)&&
 <div className="container">
             <ToastContainer />
        <div className="FollowUsText" id="FollowUsText">
             ALDOHA LINK ON SOCIAL MEDIA<br></br>
             Copyright © 2024
        </div>
        <header className="header1">
         <img src={locationIcon} alt="a logo"></img>
            <div className="header-text">
                Street 920 – Building No. 35 - Floor No. 10
                Al-Dafna 63, Doha – Qatar. 
            </div>
        </header>
        <div className="BackgroundOfLogos"></div>
            <div className=" col-1 " >
            <div className="images">
            <img  className="SuccessParatnersIcon1" id ="SuccessParatnersIcon1" src={SuccessParatnersIcon1}  alt="SuccessParatnersIcon1"></img>
            
            </div>
           
            <div className="images6" id="SuccessParatnersIcon6Container">
                <img  className="SuccessParatnersIcon9" id ="SuccessParatnersIcon6" src={SuccessParatnersIcon7}  alt="SuccessParatnersIcon2"></img>
            </div>
          <div className="SubmitBackground" id="SubmitBackground"> </div>  
          <div className="SubmitBackground2" id="SubmitBackground2"> </div>  
            <form ref={form}>
        <div className="InputsBackground" id="InputsBackground">
        <div className="d-input">
        <span className="resize" id="resize">
        <input  type="text" placeholder="First Name*" id="fist"/>

        </span>
        <span className="resize" >
        <input type="text" placeholder="Last Name" id="Last"/>
        </span>
        </div>
        <div className="d-input">
        <span className="resize">
        <input  type="email" placeholder="Email*" id="ClientEmail"/>
        </span>
        <PhoneInput 
        className="InputPhoneNumber"
        international
        countryCallingCodeEditable={false}
            flags={flags}
            id="ClientMobile"
            defaultCountry="QA"
            value={value}
            onChange={setValue} />

        </div>
        <p className="PrefferedText">Preferred method of contact</p>
        <div className="d-input1">
        <select className="SelectInput"id="Preffered" name="Preffered">
            <option value="phone">Phone</option>
            <option value="email">E-mail</option>
        </select>


        </div>
<div className="d-input">

<textarea className="textarea"id="subject" name="subject" placeholder="Write your message.." ></textarea>


</div>
<div className="d-input2">
<span className="resize2">
<input className="SubmitBtn" type="submit" value="Submit" onClick={sendEmail}></input>

  </span>
  </div>

                </div>
                </form>
                  
                <div className="ContactUsContainer" id="ContactUsContainer">
                    <img className="ContactUsImg" id ="ContactUsImg" src={ContactUsImg}  alt="ContactUsImg"></img>
                </div>
                <div className="LinesA">
                    <div className="Lines">
                <div className="LinesAstyle">

                </div>
                </div>
            </div>
            <div className="LinesB">
                    <div className="Lines">
                <div className="LinesBstyle">

                </div>
                </div>
            </div>
            </div>
            
            <div className=" col-2 ">
            <p className="SubmitBackgroundText" id="SubmitBackgroundText1" style={{ marginLeft: '3%'}}>تم ارسال الطلب بنجاح</p>
            <p className="SubmitBackgroundText" id="SubmitBackgroundText2"  style={{ top: '27%'}}>سيتم التواصل معك قريبا</p> 
            <p className="SubmitBackgroundText" id="SubmitBackgroundText12" style={{ marginLeft: '3%'}}>لم يتم الطلب للاسف</p>
            <p className="SubmitBackgroundText" id="SubmitBackgroundText22"  style={{ top: '27%'}}>حاول مرة اخرى</p> 

            </div>
            <div className="col-3" > 
           

           
            </div>
            <div className="col-4">
            <div className="images2" id="SuccessParatnersIcon2Container">
                <img  className="SuccessParatnersIcon2" id ="SuccessParatnersIcon2" src={SuccessParatnersIcon2}  alt="SuccessParatnersIcon2"></img>
            </div>
            <header className="header2">
                <img src={MobileIcon} alt="a logo"></img>
                <div className="header-text">
                Phone: +974 44189854
 
                </div>
            </header>
            </div>
            <div className="col-5" >
            <div className="images8" id="SuccessParatnersIcon2Container">
                <img  className="SuccessParatnersIcon8" id ="SuccessParatnersIcon8" src={SuccessParatnersIcon8}  alt="SuccessParatnersIcon2"></img>
            </div>
            
            <div className="LogoDohaContact" id="LogoDohaContact">
                <img  className="LogoDohaContactimg" id ="LogoDohaContactimg" src={LogoDohaContactimg}  alt="LogoDohaContactimg"></img>
            </div>
           
            </div>
            <div className="col-6">
            <div className="images5" id="SuccessParatnersIcon5Container">
                <img  className="SuccessParatnersIcon5" id ="SuccessParatnersIcon5" src={SuccessParatnersIcon5}  alt="SuccessParatnersIcon2"></img>
           
            </div>
                <div className="SuccessParatners" id="SuccessParatners">
                شركاء النجاح
                </div>
               
            </div>
           
            <div className="col-7" >
            <header className="header2">
                <img src={EmailIcon} alt="a logo"></img>
                <div className="header-text" >
                <a target="_self" href="mailto:info@aldohalink.com">
                info@aldohalink.com</a>
            
                </div>
            </header>
            </div>
            <div className="col-8 ">
            <div className="images7" id="SuccessParatnersIcon7Container">
                <img  className="SuccessParatnersIcon7" id ="SuccessParatnersIcon7" src={SuccessParatnersIcon6}  alt="SuccessParatnersIcon2"></img>

            </div>
            <div className="images3" id="SuccessParatnersIcon3Container">
                <img  className="SuccessParatnersIcon3" id ="SuccessParatnersIcon3" src={SuccessParatnersIcon3}  alt="SuccessParatnersIcon3"></img>

            </div>
         <div >
            <iframe className="googleMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.5884671797135!2d51.51982057397231!3d25.318027126798626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c4c910445585%3A0x4ae36775725b6eaa!2sQatar%20Tower!5e0!3m2!1sen!2sqa!4v1689840484332!5m2!1sen!2sqa" title="AldohaLinkAddress" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            </div>
            <div className="col-9" >
            <div className="images4" id="SuccessParatnersIcon3Container">
                <img  className="SuccessParatnersIcon4" id ="SuccessParatnersIcon4" src={SuccessParatnersIcon4}  alt="SuccessParatnersIcon3"></img>

            </div>
            </div>
            <div className="col-10" >
           
            </div>
            <div className="col-11">

            <div className="images10" id="SuccessParatnersIcon10Container">
                <img  className="SuccessParatnersIcon10" id ="SuccessParatnersIcon10" src={SuccessParatnersIcon10}  alt="SuccessParatnersIcon10"></img>
            </div>
            <div className="SoialMedia">
                      <a href="https://twitter.com/aldohalink?s=11&t=3yQOXYv2ranr7DKT-pM0GA" className="twitter" id="twitter">
                      <img className="twitterImg" id ="twitterImg" src={twitterImg}  alt="twitterkImg"></img>

                      </a>
                      <a href="https://www.facebook.com/profile.php?id=100093057608293" className="facebook" id="facebook">
                      <img className="facebookImg" id ="facebookImg" src={facebookImg}  alt="facebookImg"></img>

                      </a>
                      <a href="https://instagram.com/aldohalink?igshid=MzRlODBiNWFlZA==" className="facebook" id="instagram">
                      <img className="facebookImg" id ="facebookImg" src={instagramImg}  alt="facebookImg"></img>

                      </a>
                
                      <a href="https://www.linkedin.com/company/doha-link/" className="facebook" id="linkedin">
                      <img className="facebookImg" id ="facebookImg" src={linkedinImg}  alt="facebookImg"></img>
                      </a>
    
              </div>
            </div>
            <div className="col-12" >
            <div className="images9" id="SuccessParatnersIcon2Container">
                <img  className="SuccessParatnersIcon9" id ="SuccessParatnersIcon9" src={SuccessParatnersIcon9}  alt="SuccessParatnersIcon2"></img>
            </div>
            </div>
           
</div>  
            }
       
</div>
      );
    
  }
  
  export default ContactUs;