import React ,{useRef, useState , useEffect} from "react";
import "./App.css";
import Section from "./Components/Section";
import logo from './Images/logo.png';
import MoreOptions from './Images/MoreOption.png';
import { Link, animateScroll as scroll } from "react-scroll";

export default function App2 ({isMobile1}) {
  const[isMobileFlag]=useState(window.innerWidth<window.innerHeight);
  const ref = useRef(null);
  var element2=[];
 // const element2 =document.querySelectorAll('.section');
   
      useEffect(() => {
        // 👇️ use a ref (best)
       element2 =ref.current.children;

      }, [ref]); 

     function scrollToContactUs() {

        scroll.scrollTo( (((window.innerWidth*972)/1920) *4)+1);
      }  
      function scrollToProjects() {
   
        scroll.scrollTo( (((window.innerWidth*972)/1920) *3)+1);
      }  
      function scrollToProjectsMobile() {
   
        scroll.scrollTo( 3*(((window.innerWidth*739)/390)));
      }  
      function scrollToService() {

        scroll.scrollTo( (((window.innerWidth*972)/1920) *2)+1);
      }  
      function scrollToServiceMobile() {

        scroll.scrollTo( 2*((window.innerWidth*739)/390));
      }  
      function scrollToAbout() {
  
        scroll.scrollTo( (((window.innerWidth*972)/1920) )+1);
      }  
      function scrollToTop() {
  
        scroll.scrollToTop();
      }  
      function navHighlighter() {
       
         
        // Get current scroll position
        let scrollY = window.scrollY;
        let sectionId=0;
       
        // Now we loop through sections to get height, top and ID values for each
        for(let i=1;i<element2.length+1;i++){
          const sectionHeight = element2[i-1].offsetHeight;
          const sectionTop = element2[i-1].offsetTop -(window.innerWidth*(180/1920));
         
          /*
          - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
          - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
          */
          if (
            scrollY > sectionTop &&
            scrollY <= sectionTop + sectionHeight
          ){
            
            document.querySelector(".section"+i).classList.add("active");
          
          } else {
           
            document.querySelector(".section"+i).classList.remove("active");
           
          }
       
      }}
      function navHighlighterMobile() {
       
        
      // Get current scroll position
      let scrollY = window.scrollY;
      let sectionId=0;
     
      // Now we loop through sections to get height, top and ID values for each
      for(let i=1;i<6;i++){
        const sectionHeight = element2[i-1].offsetHeight;
        const sectionTop = element2[i-1].offsetTop -(63*390/800);
       
        
        /*
        - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
        - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
        */
        if (
          scrollY > sectionTop &&
          scrollY <= sectionTop + sectionHeight
        ){
         
          document.querySelector(".section"+i).classList.add("active");
          
        } else {
          
          document.querySelector(".section"+i).classList.remove("active");
        }
     
    }
       
      }
      function DecidedWich(){
        if(!isMobile1){
          window.addEventListener('scroll', navHighlighter);         
        }else{
          window.addEventListener('scroll', navHighlighterMobile); 
        
        }

      }
      
    return (
  
      
    <div  key={isMobile1}>
         <div className="navContainer" >
      {
       
        (  isMobile1)
         && 
         
        <div className="navContainerMobile">
          <DecidedWich/>
         <nav className="navMobile" id="navbar">
         <div className="nav-contentMobile">
           <img
             src={logo}
             className="nav-logoMobile "
             alt="Logo"
            
           />
           <ul className="nav-items">
           <li className="nav-itemMobile ">
             
             <a className="section5" id="contusnav" > تواصل معنا</a>

              </li>
             <li className="nav-itemMobile ">
             
              <a className="section4" onClick={scrollToProjectsMobile} > مشاريعنا</a>

               </li>
             <li className="nav-itemMobile ">
             <a className="section3" onClick={scrollToServiceMobile}> الخدمات</a>
             </li>
             <li className="nav-itemMobile " >
             <a className="section2" id="aboutnav"> عن الدوحة</a>
             </li>
             <li className="nav-itemMobile " >
             <a className="section1" onClick={scrollToTop}> الصفحةالرئيسية</a>
             </li>
           </ul>
          
         </div>
        
       </nav>
       </div>
      }
      {
        (!isMobile1)&&
      <nav className="nav" id="navbar">
         <DecidedWich/>
        <div className="nav-content">
          <img
            src={logo}
            className="nav-logo"
            alt="Logo"
           onClick={scrollToTop}
          />
          <ul className="nav-items">
            <li className="nav-item">
              
              <a className="section5" onClick={scrollToContactUs} >تواصل معنا</a>
            </li>
           
            <li className="nav-item">
             
              <a className="section4"  onClick={scrollToProjects}>مشاريعنا</a>
              </li>
            <li className="nav-item">
              
              <a className="section3" onClick={scrollToService}>الخدمات</a>
            </li>
            <li className="nav-item">
             
              <a className="section2" onClick={scrollToAbout}>عن الدوحة لينك</a>

            </li>
            <li className="nav-item" >
              
              <a className="section1" onClick={scrollToTop}>الصفحةالرئيسية</a>

            </li>
          </ul>
          
        </div>
       
      </nav>
      
      }
       <div className={ (isMobile1 ? 'NavRectangleMobile' : 'NavRectangle')}>

        </div>
      </div>
      { (!isMobile1) &&
      
         <div ref={ref} className="App">
      
       <Section  className="sectionSelect" title="Home" id="section1"/> 
        <Section className="sectionSelect" title="About" id="section2"/>
         <Section className="sectionSelect" title="section3" id="section3" />
       
        <Section className="sectionSelect"
          title="Section 4"
          id="section4"
         
        />
        <Section className="sectionSelect"
          title="Section 5"
          id="section5"
          
        />
       
     
        </div>
      }
      {
        (isMobile1) &&
        <div ref={ref} className="App">

        <Section title="Home" id="section1"/> 
        <Section title="About" id="section2"/>
         <Section title="section3" id="section3" />
       
        <Section
          title="Section 4"
          id="section4"
         
        />
        <Section
          title="Section 5"
          id="section5"
          
        />
         <Section
          title="NewSection"
          id="section6"
        />
        
      </div>
    }
   
    </div>  
    );

}


