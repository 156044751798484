import './index.css';
import './App.css';
import './MainPrec.css';
import './AboutDoha.css'
import './Projects.css';
import './newServices.css';
import './ContactUs.css';
import './NewSection.css';
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from './serviceWorker';


document.addEventListener("DOMContentLoaded", function(event) {
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById('root')
  );
});

serviceWorker.unregister();