import React, { useState }  from "react";
import "./MainPrec";
import "./newServices.css";
import NewServicesImg from './Images/Background4ourServices.png';
import CircuitImg from './Images/qasw-01.png';
import IconImg1 from './Images/NewCyberIcon.png';
import IconImg2 from './Images/NewEducationIcon.png';
import IconImg3 from './Images/NewSoialIcon.png';
import IconImg4 from './Images/NewAwarnessIcon.png';
import IconImg5 from './Images/NewConsultantIcon.png';
import IconImg6 from './Images/NewAppsIcon.png';
import SoialBackgroundImg from './Images/soialMediaBack.png';
import ServiceHandsImg from './Images/Hand.psd.png';
import MinistryEduProImg from './Images/Group 6697.png';
import MinistryMunProImg from './Images/Group 6708.png';
import ConsulatantBackgroundImg from './Images/ConsulatantBachground.png';
import CuriculmBackgroundImg from './Images/Curiclum.png';
import AwarnessBackgroundImg from './Images/AwarnessBackground.png';
import AppsBackgroundImg from './Images/AppBackground.png';
import ElearningBackgroundImg from './Images/ElearningBackground.png';
import ServiceImgMobile from  './Images/ServiceMobileImg.png';
import CircuitImgMobile from  './Images/1111111111111swdafcx-01.png';
import ConsultantIconMobile from  './Images/ConsultantMobileicon.png';
import DevelopIconMobile from './Images/DevelopedAppIcon.png';
import AwarnessIconMobile from './Images/AwarnessMobileIcon.png';
import SoialIconMobile from './Images/SoialMediaMobileIcon.png';
import CurriclumIconMobile from './Images/CuriculamMobileIcon.png';
import ElearningIconMobile from './Images/CyberImg.png';

import  ConsultantMobileimg from './Images/ConsltantMobileImg.png';
import  CurriclumMobileimg from './Images/CuriculimMobileImg.png';
import  DevelopMobileimg from './Images/DevelopedMobileImg.png';
import  ElearningMobileimg from './Images/ElearningMobileImg.png';

function ServicesFinal({ isMobile }){
 
  const [flag, setFlag] = useState( true);

  const [flagMobile, setFlagMobile] = useState( true);
  const [flagMobileService, setFlagMobileService] = useState( 0);
  const [flagWebService, setFlagWebService] = useState( 0);

  
  function goToElearning(){

    setFlag(false);
    setFlagWebService(6);
    var BackArrow2ServicesRef = document.getElementById("BackArrow2Services");
    var CircuitRef    =document.getElementById("CircuitBlueContainer");
    var ServiceTextRef=document.getElementById("MainServiceText1");
   
    var Icon1ContainerRef=document.getElementById("Icon1Container");
    var Icon2ContainerRef=document.getElementById("Icon2Container");
    var Icon3ContainerRef=document.getElementById("Icon3Container");
    var Icon4ContainerRef=document.getElementById("Icon4Container");
    var Icon5ContainerRef=document.getElementById("Icon5Container");
    var Icon6ContainerRef=document.getElementById("Icon6Container");

    var CuriclamTextRef    =document.getElementById("CuriclamText");
    var ELearningTextRef   =document.getElementById("ELearningText");
    var ConsulatantTextRef =document.getElementById("ConsulatantText");
    var AppsTextRef        =document.getElementById("AppsText");
    var AwarnessTextRef    =document.getElementById("AwarnessText");
    var SoialNetworkTextRef=document.getElementById("SoialNetworkText");

    var HandsRef                       =document.getElementById("ServiceHandsContainer");
    var SoialMediaTextDetailsRef       =document.getElementById("SoialMediaTextDetails");
    var ElearningBackgroundContainerRef     =document.getElementById("ElearningBackgroundContainer");


    HandsRef.style.animation    = "HandsAnimation 1s linear forwards 1"; 
    CircuitRef.style.animation  = "CurcuitAnimation 1s linear forwards 1"; 
    
    ELearningTextRef.style.animation = "upElearningAnimation 1s linear forwards 1"; 
    
    Icon1ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon2ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon3ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon4ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon5ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon6ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 

    ConsulatantTextRef.style.animation  = "downAnimation 1s linear forwards 1"; 
    AwarnessTextRef.style.animation    = "downAnimation 1s linear forwards 1"; 
    CuriclamTextRef.style.animation     = "downAnimation 1s linear forwards 1"; 
    AppsTextRef.style.animation         = "downAnimation 1s linear forwards 1"; 
    SoialNetworkTextRef.style.animation = "downAnimation 1s linear forwards 1"; 

    
    ElearningBackgroundContainerRef.style.animation     = "AppsPictureAnimation 1s linear forwards 1"; 

    setTimeout(() => {
        ServiceTextRef.style.opacity =1;
        BackArrow2ServicesRef.style.visibility  = "visible";
        SoialMediaTextDetailsRef.innerHTML =`ومع اتساع شبكات الإنترنت أصبحت الجرائم الإلكترونية أكثر تعقيدا وانتشارا، حيث ان التدريب التقليدي لا يتوافق مع التحديات والاختراقات الأمنية المتطورة وتلجأ العديد من الجهات للخبراء في تعزز قدرات الموظفين والطلاب لديها. يمتلك فريق الدوحة لينك الخبرة العملية في مجال الأمن السيبراني وقدرته على صياغة استراتيجيات حديثة ومتطورة تتوافق مع التحديات الحالية والمستقبلية.`;   
        SoialMediaTextDetailsRef.style.animation       = "OpacityAnimation 0.5s linear forwards 1"; 
    },1000);    
  }

  function goToAwarness(){
    setFlag(false);
    setFlagWebService(5);
    var CircuitRef    =document.getElementById("CircuitBlueContainer");
    var ServiceTextRef=document.getElementById("MainServiceText1");
    var BackArrow2ServicesRef=document.getElementById("BackArrow2Services");

    var Icon1ContainerRef=document.getElementById("Icon1Container");
    var Icon2ContainerRef=document.getElementById("Icon2Container");
    var Icon3ContainerRef=document.getElementById("Icon3Container");
    var Icon4ContainerRef=document.getElementById("Icon4Container");
    var Icon5ContainerRef=document.getElementById("Icon5Container");
    var Icon6ContainerRef=document.getElementById("Icon6Container");

    var CuriclamTextRef    =document.getElementById("CuriclamText");
    var ELearningTextRef   =document.getElementById("ELearningText");
    var ConsulatantTextRef =document.getElementById("ConsulatantText");
    var AppsTextRef        =document.getElementById("AppsText");
    var AwarnessTextRef    =document.getElementById("AwarnessText");
    var SoialNetworkTextRef=document.getElementById("SoialNetworkText");

    var HandsRef                       =document.getElementById("ServiceHandsContainer");
    var SoialMediaTextDetailsRef       =document.getElementById("SoialMediaTextDetails");
    var AwarnessBackgroundContainerRef =document.getElementById("AwarnessBackgroundContainer");

    
    HandsRef.style.animation    = "HandsAnimation 1s linear forwards 1"; 
    CircuitRef.style.animation  = "CurcuitAnimation 1s linear forwards 1"; 
    
    AwarnessTextRef.style.animation = "upAwarnessAnimation 1s linear forwards 1"; 

    Icon1ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon2ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon3ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon4ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon5ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon6ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 

    ConsulatantTextRef.style.animation  = "downAnimation 1s linear forwards 1"; 
    ELearningTextRef.style.animation    = "downAnimation 1s linear forwards 1"; 
    CuriclamTextRef.style.animation     = "downAnimation 1s linear forwards 1"; 
    AppsTextRef.style.animation         = "downAnimation 1s linear forwards 1"; 
    SoialNetworkTextRef.style.animation = "downAnimation 1s linear forwards 1"; 

     AwarnessBackgroundContainerRef.style.animation     = "AppsPictureAnimation 1s linear forwards 1"; 

     setTimeout(() => {
        ServiceTextRef.style.opacity =1;
        BackArrow2ServicesRef.style.visibility  = "visible";
        SoialMediaTextDetailsRef.innerHTML =`تعمل الحملات التوعوية على تعزيز التماسك الاجتماعي والتشجيع على المعرفة ومشاركة الجمهور.
        تقوم شركة الدوحة لينك بتنفيذ الحملات التوعوية التي تتناسب مع مختلف فئات المجتمع والشرائح العمرية، وذلك من خلال تقديم أفكار إبداعية وتصميم محتوى قوي وجذاب قادر على الوصول الى العملاء المستهدفين بسرعة ورسائل ذات مصداقية وتأثير نفسي عميق قادرة على الثبات في ذاكرة العميل. وتعتمد الدوحة لينك على عدة أنواع من الحملات التوعوية (التصوير الفوتوغرافي –إنتاج الانيميشن)
        `;
        SoialMediaTextDetailsRef.style.animation       = "OpacityAnimation 0.5s linear forwards 1"; 
      },1000);  
  }





  function goToAppsDevelop(){
    setFlag(false);
    setFlagWebService(3);
    var CircuitRef    =document.getElementById("CircuitBlueContainer");
    var ServiceTextRef=document.getElementById("MainServiceText1");
    var BackArrow2ServicesRef=document.getElementById("BackArrow2Services");

    var Icon1ContainerRef=document.getElementById("Icon1Container");
    var Icon2ContainerRef=document.getElementById("Icon2Container");
    var Icon3ContainerRef=document.getElementById("Icon3Container");
    var Icon4ContainerRef=document.getElementById("Icon4Container");
    var Icon5ContainerRef=document.getElementById("Icon5Container");
    var Icon6ContainerRef=document.getElementById("Icon6Container");

    var CuriclamTextRef    =document.getElementById("CuriclamText");
    var ELearningTextRef   =document.getElementById("ELearningText");
    var ConsulatantTextRef =document.getElementById("ConsulatantText");
    var AppsTextRef        =document.getElementById("AppsText");
    var AwarnessTextRef    =document.getElementById("AwarnessText");
    var SoialNetworkTextRef=document.getElementById("SoialNetworkText");

    var HandsRef                       =document.getElementById("ServiceHandsContainer");
    var SoialMediaTextDetailsRef       =document.getElementById("SoialMediaTextDetails");
    var AppsBackgroundContainerRef     =document.getElementById("AppsBackgroundContainer");



    HandsRef.style.animation    = "HandsAnimation 1s linear forwards 1"; 
    CircuitRef.style.animation  = "CurcuitAnimation 1s linear forwards 1"; 
    
    AppsTextRef.innerHTML = "الخدمات التكنولوجية وتطوير المواقع الالكترونية وتطبيقات الهاتف";
    AppsTextRef.style.animation = "upAppsAnimation 1s linear forwards 1"; 

    Icon1ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon2ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon3ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon4ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon5ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon6ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 

    ConsulatantTextRef.style.animation  = "downAnimation 1s linear forwards 1"; 
    ELearningTextRef.style.animation    = "downAnimation 1s linear forwards 1"; 
    CuriclamTextRef.style.animation     = "downAnimation 1s linear forwards 1"; 
    AwarnessTextRef.style.animation     = "downAnimation 1s linear forwards 1"; 
    SoialNetworkTextRef.style.animation = "downAnimation 1s linear forwards 1"; 
    AppsBackgroundContainerRef.style.animation = "AppsPictureAnimation 1s linear forwards 1"; 

   
    setTimeout(() => {
        ServiceTextRef.style.opacity =1;
        BackArrow2ServicesRef.style.visibility  = "visible";

        SoialMediaTextDetailsRef.innerHTML =` تعمل الدوحة لينك على بناء المواقع الإلكترونية وتطبيقات الهواتف الذكية المختلفة ( متاجر الكترونية، منصات تعليمية، بوابات داخلية للموظفين، مواقع للشركات) بحيث تحقق الفعالية وتراعي الجمالية وسهولة الاستخدام. يتعاون فريق البرمجة في الشركة مع كلا من فريق التصميم و فريق تطوير المحتوى على تلبية الطلبات المتنوعة لعملائنا. `;   
        SoialMediaTextDetailsRef.style.animation       = "OpacityAnimation 0.5s linear forwards 1"; 
      },1000);  
  }
  function goToCurriculm(){
    setFlag(false);
    setFlagWebService(4);
    var CircuitRef    =document.getElementById("CircuitBlueContainer");
    var ServiceTextRef=document.getElementById("MainServiceText1");
    var BackArrow2ServicesRef=document.getElementById("BackArrow2Services");

    var Icon1ContainerRef=document.getElementById("Icon1Container");
    var Icon2ContainerRef=document.getElementById("Icon2Container");
    var Icon3ContainerRef=document.getElementById("Icon3Container");
    var Icon4ContainerRef=document.getElementById("Icon4Container");
    var Icon5ContainerRef=document.getElementById("Icon5Container");
    var Icon6ContainerRef=document.getElementById("Icon6Container");

    var CuriclamTextRef    =document.getElementById("CuriclamText");
    var ELearningTextRef   =document.getElementById("ELearningText");
    var ConsulatantTextRef =document.getElementById("ConsulatantText");
    var AppsTextRef        =document.getElementById("AppsText");
    var AwarnessTextRef    =document.getElementById("AwarnessText");
    var SoialNetworkTextRef=document.getElementById("SoialNetworkText");

    var HandsRef                       =document.getElementById("ServiceHandsContainer");
    var SoialMediaTextDetailsRef       =document.getElementById("SoialMediaTextDetails");
    var CuriculmBackgroundContainerRef =document.getElementById("CuriculmBackgroundContainer");

   
    HandsRef.style.animation    = "HandsAnimation 1s linear forwards 1"; 
    CircuitRef.style.animation  = "CurcuitAnimation 1s linear forwards 1"; 
    
    CuriclamTextRef.innerHTML = "التعليم الإلكتروني وتطوير المناهج الدراسية";
    CuriclamTextRef.style.animation = "upCuriclumAnimation 1s linear forwards 1"; 

    Icon1ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon2ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon3ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon4ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon5ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon6ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 

    ConsulatantTextRef.style.animation  = "downAnimation 1s linear forwards 1"; 
    ELearningTextRef.style.animation    = "downAnimation 1s linear forwards 1"; 
    AppsTextRef.style.animation         = "downAnimation 1s linear forwards 1"; 
    AwarnessTextRef.style.animation     = "downAnimation 1s linear forwards 1"; 
    SoialNetworkTextRef.style.animation = "downAnimation 1s linear forwards 1"; 

    CuriculmBackgroundContainerRef.style.animation = "CuriculmPictureAnimation 1s linear forwards 1"; 

    
    setTimeout(() => {
        ServiceTextRef.style.opacity =1;
        BackArrow2ServicesRef.style.visibility  = "visible";

        SoialMediaTextDetailsRef.innerHTML             = `يُعّرف التعليم الالكتروني الحديث على انه وسيلة تدعم العملية التعليمية وتحولها من طور التلقين إلى طور الإبداع والتفاعل وتنمية المهارات. وتسعى العديد من المؤسسات التعليمية إلى خلق بيئة إلكترونية رقمية متكاملة تعمل على خدمة أهدافها التعليمية والتربوية على المدى القريب والبعيد. تعمل شركة الدوحة لينك لتكنولوجيا المعلومات على تطوير النظم التعليمية الكترونية التفاعلية وتوظيف التقنيات الحديثة وإدراج محتوى رقمي متعدد الوسائط لتحقيق اهداف المؤسسات التعليمية ورفع عائدها الاستثماري من التعليم الالكتروني، وتقف الخبرات المتعاقبة في مجال تطوير المناهج الدراسية شاهدًا على جودة المخرجات التي تقدمها الدوحة لينك.`;
        SoialMediaTextDetailsRef.style.animation       = `OpacityAnimation 0.5s linear forwards 1`; 
      },1000);  
  }

  function goToConsultant(){
    setFlag(false);
    setFlagWebService(1);
    var CircuitRef    =document.getElementById("CircuitBlueContainer");
    var ServiceTextRef=document.getElementById("MainServiceText1");
    var BackArrow2ServicesRef=document.getElementById("BackArrow2Services");

    var Icon1ContainerRef=document.getElementById("Icon1Container");
    var Icon2ContainerRef=document.getElementById("Icon2Container");
    var Icon3ContainerRef=document.getElementById("Icon3Container");
    var Icon4ContainerRef=document.getElementById("Icon4Container");
    var Icon5ContainerRef=document.getElementById("Icon5Container");
    var Icon6ContainerRef=document.getElementById("Icon6Container");

    var CuriclamTextRef    =document.getElementById("CuriclamText");
    var ELearningTextRef   =document.getElementById("ELearningText");
    var ConsulatantTextRef =document.getElementById("ConsulatantText");
    var AppsTextRef        =document.getElementById("AppsText");
    var AwarnessTextRef    =document.getElementById("AwarnessText");
    var SoialNetworkTextRef=document.getElementById("SoialNetworkText");

    var HandsRef                    =document.getElementById("ServiceHandsContainer");
    var SoialMediaTextDetailsRef    =document.getElementById("SoialMediaTextDetails");
    var ConsultantgroundContainerRef=document.getElementById("ConsulatantBackgroundContainer");

  
    HandsRef.style.animation    = "HandsAnimation 1s linear forwards 1"; 
    CircuitRef.style.animation  = "CurcuitAnimation 1s linear forwards 1"; 

    ConsulatantTextRef.innerHTML = "التدريب المهني والاستشارات";
    ConsulatantTextRef.style.animation = "upConsultantAnimation 1s linear forwards 1"; 

    Icon1ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon2ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon3ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon4ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon5ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon6ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 

    CuriclamTextRef.style.animation     = "downAnimation 1s linear forwards 1"; 
    ELearningTextRef.style.animation    = "downAnimation 1s linear forwards 1"; 
    AppsTextRef.style.animation         = "downAnimation 1s linear forwards 1"; 
    AwarnessTextRef.style.animation     = "downAnimation 1s linear forwards 1"; 
    SoialNetworkTextRef.style.animation = "downAnimation 1s linear forwards 1"; 

    ConsultantgroundContainerRef.style.animation    = "ConsultantPictureAnimation 1s linear forwards 1"; 

    
    setTimeout(() => {
        ServiceTextRef.style.opacity =1;
        BackArrow2ServicesRef.style.visibility  = "visible";

        SoialMediaTextDetailsRef.innerHTML =`تبحث الشركات والمؤسسات المتقدمة دائمًا عن تطوير أداء العاملين فيها لتحسين جودة مخرجاتها وتحقيق أهدافها الاستراتيجية، ويتطلب الوصول إلى هذه الأهداف اتباع خطوات استباقية في صقل وتعزيز قدرات موظفيها. في هذا الإطار، تقدم الدوحة لينك خدمات الاستشارة والتدريب للعديد من القطاعات العاملة، ففريقنا المختص من الخبراء والأكاديميين يعملون على صياغة خطط تطويرية تناسب احتياجات جميع عملائنا.`;
        SoialMediaTextDetailsRef.style.animation       = "OpacityAnimation 0.5s linear forwards 1"; 
      },1000);  
   
  }
  function goToSoial(){
    setFlag(false);
    setFlagWebService(2);
    var CircuitRef=document.getElementById("CircuitBlueContainer");
   // var ServiceTextRef=document.getElementById("MainServiceText");
    var ServiceTextRef=document.getElementById("MainServiceText1");
    var BackArrow2ServicesRef=document.getElementById("BackArrow2Services");

    var Icon1ContainerRef=document.getElementById("Icon1Container");
    var Icon2ContainerRef=document.getElementById("Icon2Container");
    var Icon3ContainerRef=document.getElementById("Icon3Container");
    var Icon4ContainerRef=document.getElementById("Icon4Container");
    var Icon5ContainerRef=document.getElementById("Icon5Container");
    var Icon6ContainerRef=document.getElementById("Icon6Container");
    var CuriclamTextRef=document.getElementById("CuriclamText");
    var ELearningTextRef=document.getElementById("ELearningText");
    var ConsulatantTextRef=document.getElementById("ConsulatantText");
    var AppsTextRef=document.getElementById("AppsText");
    var AwarnessTextRef=document.getElementById("AwarnessText");
    var SoialNetworkTextRef=document.getElementById("SoialNetworkText");
    var HandsRef=document.getElementById("ServiceHandsContainer");
    var SoialMediaTextDetailsRef=document.getElementById("SoialMediaTextDetails");
    var SoialBackgroundContainerRef=document.getElementById("SoialBackgroundContainer");

    
    HandsRef.style.animation = "HandsAnimation 1s linear forwards 1"; 
    CircuitRef.style.animation = "CurcuitAnimation 1s linear forwards 1"; 


    Icon2ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon3ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon4ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon5ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon6ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    CuriclamTextRef.style.animation = "downAnimation 1s linear forwards 1"; 
    ELearningTextRef.style.animation = "downAnimation 1s linear forwards 1"; 
    ConsulatantTextRef.style.animation = "downAnimation 1s linear forwards 1"; 
    AppsTextRef.style.animation = "downAnimation 1s linear forwards 1"; 
    AwarnessTextRef.style.animation = "downAnimation 1s linear forwards 1"; 
    Icon1ContainerRef.style.animation = "downAnimation 1s linear forwards 1"; 
    SoialNetworkTextRef.innerHTML = "التسويق الالكتروني وإدارة منصات التواصل الاجتماعي";
    SoialNetworkTextRef.style.animation = "upSoialAnimation 1s linear forwards 1"; 
      SoialBackgroundContainerRef.style.animation = "pictureAnimation 1s linear forwards 1"; 
      setTimeout(() => {
        ServiceTextRef.style.opacity=1;
        BackArrow2ServicesRef.style.visibility  = "visible";
        SoialMediaTextDetailsRef.innerHTML =`نسير معك في الدوحة لينك خطوة بخطوة نحو الوصول إلى حضور قوي يرسخ في ذاكرة عملائك، وننطلق من صناعة الهوية البصرية، مرورًا بدراسة المنافسين، ووضع استراتيجيات التواصل، وتطوير وصناعة المحتوى باللغتين العربية والإنجليزية، وتسليمه لفريق إبداعي من المصممين والمصورين، ومن ثم نشرف على إدارة هذه المنصات بكفاءة عالية.`;
        //SoialMediaTextDetailsRef.style.opacity=1;
        SoialMediaTextDetailsRef.style.animation = "OpacityAnimation 0.5s linear forwards 1"; 
        SoialBackgroundContainerRef.style.animation    = "pictureAnimation 1s linear forwards 1";
      },1000);  
     
  
  }


  function ReturnToService(){
    if(flag===false){
    setFlag(true);
    var BackArrow2ServicesRef=document.getElementById("BackArrow2Services");

    var ServiceTextRef     =document.getElementById("MainServiceText1");
    var Icon1ContainerRef  =document.getElementById("Icon1Container");
    var Icon2ContainerRef  =document.getElementById("Icon2Container");
    var Icon3ContainerRef  =document.getElementById("Icon3Container");
    var Icon4ContainerRef  =document.getElementById("Icon4Container");
    var Icon5ContainerRef  =document.getElementById("Icon5Container");
    var Icon6ContainerRef  =document.getElementById("Icon6Container");
    var CuriclamTextRef    =document.getElementById("CuriclamText");
    var ELearningTextRef   =document.getElementById("ELearningText");
    var ConsulatantTextRef =document.getElementById("ConsulatantText");
    var AppsTextRef        =document.getElementById("AppsText");
    var AwarnessTextRef    =document.getElementById("AwarnessText");
    var SoialNetworkTextRef=document.getElementById("SoialNetworkText");

    BackArrow2ServicesRef.style.visibility  = "hidden";

    Icon1ContainerRef.style.animation  = "ReversedownAnimation 1s linear forwards 1"; 
    Icon2ContainerRef.style.animation  = "ReversedownAnimation 1s linear forwards 1"; 
    Icon3ContainerRef.style.animation  = "ReversedownAnimation 1s linear forwards 1"; 
    Icon4ContainerRef.style.animation  = "ReversedownAnimation 1s linear forwards 1"; 
    Icon5ContainerRef.style.animation  = "ReversedownAnimation 1s linear forwards 1"; 
    Icon6ContainerRef.style.animation  = "ReversedownAnimation 1s linear forwards 1"; 
    CuriclamTextRef.style.animation    = "ReversedownAnimation 1s linear forwards 1"; 
    ELearningTextRef.style.animation   = "ReversedownAnimation 1s linear forwards 1"; 
    ConsulatantTextRef.style.animation = "ReversedownAnimation 1s linear forwards 1"; 
    AppsTextRef.style.animation        = "ReversedownAnimation 1s linear forwards 1"; 
    AwarnessTextRef.style.animation    = "ReversedownAnimation 1s linear forwards 1"; 
    SoialNetworkTextRef.innerHTML      = `التسويق الالكتروني <br/>وإدارة منصات <br/>التواصل الاجتماعي`;
    CuriclamTextRef.innerHTML          = `التعليم الإلكتروني<br/>وتطوير المناهج الدراسية`;
    AppsTextRef.innerHTML              =`  الخدمات التكنولوجية<br/>وتطوير المواقع الالكترونية<br/>وتطبيقات الهاتف`;
    ConsulatantTextRef.innerHTML = `التدريب المهني<br/> والاستشارات`;
    SoialNetworkTextRef.style.animation = "ReversedownAnimation 1s linear forwards 1"; 

    var HandsRef=document.getElementById("ServiceHandsContainer");
    var CircuitRef=document.getElementById("CircuitBlueContainer");
    ServiceTextRef.style.opacity=0;
    HandsRef.style.animation = "ReverseHandsAnimation 1s linear forwards 1"; 
    CircuitRef.style.animation = "ReverseCurcuitAnimation 1s linear forwards 1"; 
    
    
    var ConsultantgroundContainerRef    =document.getElementById("ConsulatantBackgroundContainer");
    var SoialBackgroundContainerRef     =document.getElementById("SoialBackgroundContainer");
    var CuriculmBackgroundContainerRef  =document.getElementById("CuriculmBackgroundContainer");
    var AppsBackgroundContainerRef      =document.getElementById("AppsBackgroundContainer");
    var ElearningBackgroundContainerRef =document.getElementById("ElearningBackgroundContainer");
    var AwarnessBackgroundContainerRef  =document.getElementById("AwarnessBackgroundContainer");


    var SoialMediaTextDetailsRef             =document.getElementById("SoialMediaTextDetails");
    SoialMediaTextDetailsRef.style.animation = "FaddedOpacityAnimation 0.5s linear forwards 1"; 
    switch (flagWebService)
    {
        case 1:
          ConsultantgroundContainerRef.style.animation = "FaddedOpacityAnimation 0.5s linear forwards 1"; 
        break;
        case 2:
          SoialBackgroundContainerRef.style.animation = "FaddedOpacityAnimation 0.5s linear forwards 1"; 
        break;
        case 3:
          AppsBackgroundContainerRef.style.animation = "FaddedOpacityAnimation 0.5s linear forwards 1"; 
        break;
        case 4:
          CuriculmBackgroundContainerRef.style.animation = "FaddedOpacityAnimation 0s ease  "; 
        break;
        case 5:
          AwarnessBackgroundContainerRef.style.animation = "FaddedOpacityAnimation 0.5s linear forwards 1"; 
        break;
        case 6:
          ElearningBackgroundContainerRef.style.animation = "FaddedOpacityAnimation 0.5s linear forwards 1"; 
        break;
         default:
          break;

        }
  
  
  }
  }
 

  function goToMobileConsultant(){
    var ConsulltantContainerRef   =document.getElementById("ConsultantContainerMobile");
    if(window.getComputedStyle(ConsulltantContainerRef).getPropertyValue("opacity") !==0){

    setFlagMobile(false);
    setFlagMobileService(2);
    var CircuitContainerRef       =document.getElementById("CircuitImgContainerMobile");

    var WordsMobileRef  =document.getElementById("WordsMobile");
    var SoialMediaWordsMobileRef  =document.getElementById("SoialMediaWordsMobile");
    var ElearningWordsMobileRef   =document.getElementById("ElearningWordsMobile");
    var DelveopedAppWordsMobileRef=document.getElementById("DelveopedAppWordsMobile");
    var CuriculmAppWordsMobileRef =document.getElementById("CuriculmAppWordsMobile");
    var AwarnessWordsMobileRef    =document.getElementById("AwarnessWordsMobile");
    
    var SoialMediaMobileRefIcon   =document.getElementById("SoialContainerMobile");
    var ElearningMobileRefIcon    =document.getElementById("ElearingContainerMobile");
    var DelveopedAppsMobileRefIcon=document.getElementById("DevelopContainerMobile");
    var CuriculmMobileRefIcon     =document.getElementById("CurriclamContainerMobile");
    var AwarnessMobileRefIcon     =document.getElementById("AwarnessContainerMobile");

    var ConsultantMobileimgRef    =document.getElementById("ConsultantMobileimg");
    var ConsultantDeatailsMobileRef =document.getElementById("ConsultantDeatailsMobile");

    CircuitContainerRef.style.animation        = "CircuitMobileAnimation 0.5s linear forwards 1"; 
    SoialMediaWordsMobileRef.style.visibility = "hidden"; 
    ElearningWordsMobileRef.style.visibility = "hidden"; 
    DelveopedAppWordsMobileRef.style.visibility = "hidden"; 
    CuriculmAppWordsMobileRef.style.visibility = "hidden";  
    AwarnessWordsMobileRef.style.visibility = "hidden"; 

    SoialMediaMobileRefIcon.style.visibility = "hidden"; 
    ElearningMobileRefIcon.style.visibility = "hidden"; 
    DelveopedAppsMobileRefIcon.style.visibility = "hidden"; 
    CuriculmMobileRefIcon.style.visibility = "hidden"; 
    AwarnessMobileRefIcon.style.visibility = "hidden"; 

    ConsulltantContainerRef.style.animation     = "ConsultantMobileAnimation 0.1s linear forwards 1";
    WordsMobileRef.style.animation              = "ConsultantMobileWordsAnimation 0.1s linear forwards 1";
    ConsultantMobileimgRef.style.animation      = "OpacityAnimation 0.1s linear forwards 1";
    ConsultantDeatailsMobileRef.style.animation = "OpacityAnimation 0.1s linear forwards 1";
  }
  }
  function goToMobileSoialMedia(){
    var SoialMediaMobileRefIcon   =document.getElementById("SoialContainerMobile");

    if(window.getComputedStyle(SoialMediaMobileRefIcon).getPropertyValue("opacity") !==0){

    setFlagMobile(false);
    setFlagMobileService(1);
    var CircuitContainerRef       =document.getElementById("CircuitImgContainerMobile");

    var WordsMobileRef            =document.getElementById("WordsMobile");
    var SoialMediaWordsMobileRef  =document.getElementById("SoialMediaWordsMobile");
    var ElearningWordsMobileRef   =document.getElementById("ElearningWordsMobile");
    var DelveopedAppWordsMobileRef=document.getElementById("DelveopedAppWordsMobile");
    var CuriculmAppWordsMobileRef =document.getElementById("CuriculmAppWordsMobile");
    var AwarnessWordsMobileRef    =document.getElementById("AwarnessWordsMobile");
    
    var ConsulltantContainerRef   =document.getElementById("ConsultantContainerMobile");
    var ElearningMobileRefIcon    =document.getElementById("ElearingContainerMobile");
    var DelveopedAppsMobileRefIcon=document.getElementById("DevelopContainerMobile");
    var CuriculmMobileRefIcon     =document.getElementById("CurriclamContainerMobile");
    var AwarnessMobileRefIcon     =document.getElementById("AwarnessContainerMobile");
    var soialMediaMobileimgRef    =document.getElementById("SoialMediaMobileimg");
    var SoialMediaDeatailsMobileRef =document.getElementById("SoialMediaDeatailsMobile");

    CircuitContainerRef.style.animation        = "CircuitMobileAnimation 0.5s linear forwards 1"; 
    WordsMobileRef.style.visibility             = "hidden"; 
    ElearningWordsMobileRef.style.visibility    = "hidden"; 
    DelveopedAppWordsMobileRef.style.visibility = "hidden"; 
    CuriculmAppWordsMobileRef.style.visibility = "hidden"; 
    AwarnessWordsMobileRef.style.visibility = "hidden"; 

    ConsulltantContainerRef.style.visibility = "hidden"; 
    ElearningMobileRefIcon.style.visibility = "hidden"; 
    DelveopedAppsMobileRefIcon.style.visibility = "hidden"; 
    CuriculmMobileRefIcon.style.visibility = "hidden"; 
    AwarnessMobileRefIcon.style.visibility = "hidden"; 
    SoialMediaMobileRefIcon.style.animation     = "ConsultantMobileAnimation 0.1s linear forwards 1";
    SoialMediaWordsMobileRef.style.animation    = "SoialMediaMobileWordsAnimation 0.1s linear forwards 1";
    soialMediaMobileimgRef.style.animation      = "OpacityAnimation 0.1s linear forwards 1";
    SoialMediaDeatailsMobileRef.style.animation = "OpacityAnimation 0.1s linear forwards 1";
    }
  }
  function goToMobileDeveloped(){
    var DelveopedAppsMobileRefIcon  =document.getElementById("DevelopContainerMobile");

    if(window.getComputedStyle(DelveopedAppsMobileRefIcon).getPropertyValue("opacity") !==0){
    setFlagMobile(false);
    setFlagMobileService(4);
    var CircuitContainerRef       =document.getElementById("CircuitImgContainerMobile");

    var WordsMobileRef            =document.getElementById("WordsMobile");
    var SoialMediaWordsMobileRef  =document.getElementById("SoialMediaWordsMobile");
    var ElearningWordsMobileRef   =document.getElementById("ElearningWordsMobile");
    var DelveopedAppWordsMobileRef=document.getElementById("DelveopedAppWordsMobile");

    var CuriculmAppWordsMobileRef =document.getElementById("CuriculmAppWordsMobile");
    var AwarnessWordsMobileRef    =document.getElementById("AwarnessWordsMobile");
    
    var ConsulltantContainerRef     =document.getElementById("ConsultantContainerMobile");
    var SoialMediaMobileRefIcon     =document.getElementById("SoialContainerMobile");
    var ElearningMobileRefIcon      =document.getElementById("ElearingContainerMobile");
    var CuriculmMobileRefIcon       =document.getElementById("CurriclamContainerMobile");
    var AwarnessMobileRefIcon       =document.getElementById("AwarnessContainerMobile");
    var DevelopMobileimgRef      =document.getElementById("DevelopMobileimg");
    var DevelopedDeatailsMobileRef =document.getElementById("DevelopedDeatailsMobile");

    CircuitContainerRef.style.animation        = "CircuitMobileAnimation 0.5s linear forwards 1"; 
    WordsMobileRef.style.visibility = "hidden";  
    ElearningWordsMobileRef.style.visibility = "hidden"; 
    CuriculmAppWordsMobileRef.style.visibility = "hidden"; 
    AwarnessWordsMobileRef.style.visibility = "hidden"; 
    SoialMediaWordsMobileRef.style.visibility = "hidden"; 
    DelveopedAppWordsMobileRef.style.animation = "DevelopedMobileWordsAnimation 0.1s linear forwards 1"; 


    ConsulltantContainerRef.style.visibility = "hidden"; 
    ElearningMobileRefIcon.style.visibility = "hidden"; 
    DelveopedAppsMobileRefIcon.style.animation  = "ConsultantMobileAnimation 0.1s linear forwards 1";
    CuriculmMobileRefIcon.style.visibility = "hidden"; 
    AwarnessMobileRefIcon.style.visibility = "hidden"; 
    SoialMediaMobileRefIcon.style.visibility = "hidden"; 
    
    DevelopMobileimgRef.style.animation      = "OpacityAnimation 0.1s linear forwards 1";
    DevelopedDeatailsMobileRef.style.animation = "OpacityAnimation 0.1s linear forwards 1";
  }
  }
  function goToMobileCuriculam(){
    var CuriculmMobileRefIcon       =document.getElementById("CurriclamContainerMobile");
    if(window.getComputedStyle(CuriculmMobileRefIcon).getPropertyValue("opacity") !==0){

    setFlagMobile(false);
    setFlagMobileService(3);
    var CircuitContainerRef       =document.getElementById("CircuitImgContainerMobile");

    var WordsMobileRef            =document.getElementById("WordsMobile");
    var SoialMediaWordsMobileRef  =document.getElementById("SoialMediaWordsMobile");
    var ElearningWordsMobileRef   =document.getElementById("ElearningWordsMobile");
    var DelveopedAppWordsMobileRef=document.getElementById("DelveopedAppWordsMobile");
    var CuriculmAppWordsMobileRef =document.getElementById("CuriculmAppWordsMobile");
    var AwarnessWordsMobileRef    =document.getElementById("AwarnessWordsMobile");
    
    var ConsulltantContainerRef     =document.getElementById("ConsultantContainerMobile");
    var SoialMediaMobileRefIcon     =document.getElementById("SoialContainerMobile");
    var ElearningMobileRefIcon      =document.getElementById("ElearingContainerMobile");
    var DelveopedAppsMobileRefIcon  =document.getElementById("DevelopContainerMobile");
    var AwarnessMobileRefIcon       =document.getElementById("AwarnessContainerMobile");
    var CurriclumMobileimgRef         =document.getElementById("CurriclumMobileimg");
    var CuriculmDeatailsMobileRef =document.getElementById("CuriculmDeatailsMobile");

    CircuitContainerRef.style.animation        = "CircuitMobileAnimation 0.5s linear forwards 1"; 
    WordsMobileRef.style.visibility = "hidden";
    ElearningWordsMobileRef.style.visibility = "hidden"; 
    CuriculmAppWordsMobileRef.style.animation  = "CuriculamMobileWordsAnimation 0.1s linear forwards 1"; 
    AwarnessWordsMobileRef.style.visibility = "hidden"; 
    SoialMediaWordsMobileRef.style.visibility = "hidden"; 
    DelveopedAppWordsMobileRef.style.visibility = "hidden"; 


    ConsulltantContainerRef.style.visibility = "hidden"; 
    ElearningMobileRefIcon.style.visibility = "hidden"; 
    DelveopedAppsMobileRefIcon.style.visibility = "hidden"; 
    CuriculmMobileRefIcon.style.animation       = "ConsultantMobileAnimation 0.1s linear forwards 1";
    AwarnessMobileRefIcon.style.visibility = "hidden"; 
    SoialMediaMobileRefIcon.style.visibility = "hidden"; 
    
    CurriclumMobileimgRef.style.animation      = "OpacityAnimation 0.1s linear forwards 1";
    CuriculmDeatailsMobileRef.style.animation = "OpacityAnimation 0.1s linear forwards 1";
    }
  }
  function goToMobileAwarness(){
    var AwarnessMobileRefIcon       =document.getElementById("AwarnessContainerMobile");

    if(window.getComputedStyle(AwarnessMobileRefIcon).getPropertyValue("opacity") !==0){

    setFlagMobile(false);
    setFlagMobileService(5);
    var CircuitContainerRef       =document.getElementById("CircuitImgContainerMobile");

    var WordsMobileRef            =document.getElementById("WordsMobile");
    var SoialMediaWordsMobileRef  =document.getElementById("SoialMediaWordsMobile");
    var ElearningWordsMobileRef   =document.getElementById("ElearningWordsMobile");
    var DelveopedAppWordsMobileRef=document.getElementById("DelveopedAppWordsMobile");
    var CuriculmAppWordsMobileRef =document.getElementById("CuriculmAppWordsMobile");
    var AwarnessWordsMobileRef    =document.getElementById("AwarnessWordsMobile");
    
    var ConsulltantContainerRef      =document.getElementById("ConsultantContainerMobile");
    var SoialMediaMobileRefIcon      =document.getElementById("SoialContainerMobile");
    var ElearningMobileRefIcon       =document.getElementById("ElearingContainerMobile");
    var DelveopedAppsMobileRefIcon   =document.getElementById("DevelopContainerMobile");
    var CuriculmMobileRefIcon        =document.getElementById("CurriclamContainerMobile");
    var AwarnessMobileimgRef         =document.getElementById("AwarnessMobileimg");
    var AwarenessDeatailsMobileRef   =document.getElementById("AwarnessDeatailsMobile");

    CircuitContainerRef.style.animation         = "CircuitMobileAnimation 0.5s linear forwards 1"; 
    WordsMobileRef.style.visibility             = "hidden"; 
    ElearningWordsMobileRef.style.visibility    = "hidden"; 
    CuriculmAppWordsMobileRef.style.visibility  = "hidden"; 
    AwarnessWordsMobileRef.style.animation      = "AwarnessMobileWordsAnimation 0.1s linear forwards 1"; 
    SoialMediaWordsMobileRef.style.visibility   = "hidden"; 
    DelveopedAppWordsMobileRef.style.visibility = "hidden";  


    ConsulltantContainerRef.style.visibility    = "hidden"; 
    ElearningMobileRefIcon.style.visibility     = "hidden"; 
    DelveopedAppsMobileRefIcon.style.visibility = "hidden"; 
    CuriculmMobileRefIcon.style.visibility      = "hidden"; 
    AwarnessMobileRefIcon.style.animation       = "ConsultantMobileAnimation 0.1s linear forwards 1";
    SoialMediaMobileRefIcon.style.visibility    = "hidden"; 
    
    AwarnessMobileimgRef.style.animation       = "OpacityAnimation 0.1s linear forwards 1";
    AwarenessDeatailsMobileRef.style.animation = "OpacityAnimation 0.1s linear forwards 1";
    }
  }
  function goToMobileElearning(){
    var ElearningMobileRefIcon      =document.getElementById("ElearingContainerMobile");

    if(window.getComputedStyle(ElearningMobileRefIcon).getPropertyValue("opacity") !==0){

    setFlagMobile(false);
    setFlagMobileService(6);
    var CircuitContainerRef       =document.getElementById("CircuitImgContainerMobile");

    var WordsMobileRef            =document.getElementById("WordsMobile");
   // WordsMobileRef.style.visibility             = "hidden"; 
    var SoialMediaWordsMobileRef  =document.getElementById("SoialMediaWordsMobile");
    var ElearningWordsMobileRef   =document.getElementById("ElearningWordsMobile");
    var DelveopedAppWordsMobileRef=document.getElementById("DelveopedAppWordsMobile");
    var CuriculmAppWordsMobileRef =document.getElementById("CuriculmAppWordsMobile");
    var AwarnessWordsMobileRef    =document.getElementById("AwarnessWordsMobile");
    
    var ConsulltantContainerRef     =document.getElementById("ConsultantContainerMobile");
    var SoialMediaMobileRefIcon     =document.getElementById("SoialContainerMobile");
    var DelveopedAppsMobileRefIcon  =document.getElementById("DevelopContainerMobile");
    var CuriculmMobileRefIcon       =document.getElementById("CurriclamContainerMobile");
    var AwarnessMobileRefIcon       =document.getElementById("AwarnessContainerMobile");
    var ElearningMobileimgRef        =document.getElementById("ElearningMobileimg");
    var ElearningDeatailsMobileRef  =document.getElementById("ElearningDeatailsMobile");

    CircuitContainerRef.style.animation         = "CircuitMobileAnimation 0.5s linear forwards 1"; 
    WordsMobileRef.style.visibility             = "hidden"; 
    ElearningWordsMobileRef.style.animation     = "ElearningMobileWordsAnimation 0.1s linear forwards 1"; 
    CuriculmAppWordsMobileRef.style.visibility  = "hidden"; 
    AwarnessWordsMobileRef.style.visibility     = "hidden"; 
    SoialMediaWordsMobileRef.style.visibility   = "hidden"; 
    DelveopedAppWordsMobileRef.style.visibility = "hidden"; 


    ConsulltantContainerRef.style.visibility    = "hidden"; 
    ElearningMobileRefIcon.style.animation      = "ConsultantMobileAnimation 0.1s linear forwards 1";
    DelveopedAppsMobileRefIcon.style.visibility = "hidden"; 
    CuriculmMobileRefIcon.style.visibility      = "hidden"; 
    AwarnessMobileRefIcon.style.visibility      = "hidden"; 
    SoialMediaMobileRefIcon.style.visibility    = "hidden";  
    
    ElearningMobileimgRef.style.animation       = "OpacityAnimation 0.1s linear forwards 1";
    ElearningDeatailsMobileRef.style.animation  = "OpacityAnimation 0.1s linear forwards 1";
    }
  }

  function ReturnToMobileService(){
    if(flagMobile===(false)){
      setFlagMobile(true);
    var CircuitContainerRef       =document.getElementById("CircuitImgContainerMobile");

    var WordsMobileRef            =document.getElementById("WordsMobile");
    var SoialMediaWordsMobileRef  =document.getElementById("SoialMediaWordsMobile");
    var ElearningWordsMobileRef   =document.getElementById("ElearningWordsMobile");
    var DelveopedAppWordsMobileRef=document.getElementById("DelveopedAppWordsMobile");
    var CuriculmAppWordsMobileRef =document.getElementById("CuriculmAppWordsMobile");
    var AwarnessWordsMobileRef    =document.getElementById("AwarnessWordsMobile");
    
    var ConsulltantContainerRef     =document.getElementById("ConsultantContainerMobile");
    var SoialMediaMobileRefIcon     =document.getElementById("SoialContainerMobile");
    var ElearningMobileRefIcon      =document.getElementById("ElearingContainerMobile");
    var DelveopedAppsMobileRefIcon  =document.getElementById("DevelopContainerMobile");
    var CuriculmMobileRefIcon       =document.getElementById("CurriclamContainerMobile");
    var AwarnessMobileRefIcon       =document.getElementById("AwarnessContainerMobile");

    var DevelopMobileimgRef      =document.getElementById("DevelopMobileimg");
    var DevelopedDeatailsMobileRef =document.getElementById("DevelopedDeatailsMobile");

    var CurriclumMobileimgRef         =document.getElementById("CurriclumMobileimg");
    var CuriculmDeatailsMobileRef =document.getElementById("CuriculmDeatailsMobile");

    var ConsultantMobileimgRef    =document.getElementById("ConsultantMobileimg");
    var ConsultantDeatailsMobileRef =document.getElementById("ConsultantDeatailsMobile");

    var soialMediaMobileimgRef    =document.getElementById("SoialMediaMobileimg");
    var SoialMediaDeatailsMobileRef =document.getElementById("SoialMediaDeatailsMobile");

   
    var AwarnessMobileimgRef         =document.getElementById("AwarnessMobileimg");
    var AwarenessDeatailsMobileRef =document.getElementById("AwarnessDeatailsMobile");
    
    var ElearningMobileimgRef        =document.getElementById("ElearningMobileimg");
    var ElearningDeatailsMobileRef  =document.getElementById("ElearningDeatailsMobile");

    CircuitContainerRef.style.animation        = "ReverseCircuitMobileAnimation 0.5s ease forwards 1"; 
    switch (flagMobileService)
    {
       case 1:
        WordsMobileRef.style.visibility ="visible";
        ElearningWordsMobileRef.style.visibility ="visible";
        CuriculmAppWordsMobileRef.style.visibility ="visible";
        AwarnessWordsMobileRef.style.visibility ="visible"; 
        SoialMediaWordsMobileRef.style.animation   = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1";
        DelveopedAppWordsMobileRef.style.visibility ="visible";
    
    
        ConsulltantContainerRef.style.visibility ="visible";
        ElearningMobileRefIcon.style.visibility ="visible";
        DelveopedAppsMobileRefIcon.style.visibility ="visible";
        CuriculmMobileRefIcon.style.visibility ="visible";
        AwarnessMobileRefIcon.style.visibility ="visible";
        SoialMediaMobileRefIcon.style.animation     = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1";

        soialMediaMobileimgRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
        SoialMediaDeatailsMobileRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
        break;
      
      case 2:
        WordsMobileRef.style.animation             = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1"; 
        ElearningWordsMobileRef.style.visibility ="visible";
        CuriculmAppWordsMobileRef.style.visibility ="visible";
        AwarnessWordsMobileRef.style.visibility ="visible";
        SoialMediaWordsMobileRef.style.visibility ="visible";
        DelveopedAppWordsMobileRef.style.visibility ="visible";
    
    
        ConsulltantContainerRef.style.animation     = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1";
        ElearningMobileRefIcon.style.visibility ="visible";
        DelveopedAppsMobileRefIcon.style.visibility ="visible";
        CuriculmMobileRefIcon.style.visibility ="visible";
        AwarnessMobileRefIcon.style.visibility ="visible";
        SoialMediaMobileRefIcon.style.visibility ="visible";


        ConsultantMobileimgRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
        ConsultantDeatailsMobileRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
        break;
      
    case 3:
              WordsMobileRef.style.visibility ="visible";

      ElearningWordsMobileRef.style.visibility ="visible";
      CuriculmAppWordsMobileRef.style.animation  = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1"; 
      AwarnessWordsMobileRef.style.visibility ="visible";
      SoialMediaWordsMobileRef.style.visibility ="visible";
      DelveopedAppWordsMobileRef.style.visibility ="visible";
  
  
      ConsulltantContainerRef.style.visibility ="visible";
      ElearningMobileRefIcon.style.visibility ="visible";
      DelveopedAppsMobileRefIcon.style.visibility ="visible";
      CuriculmMobileRefIcon.style.animation       = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1";
      AwarnessMobileRefIcon.style.visibility ="visible";
      SoialMediaMobileRefIcon.style.visibility ="visible";

        CurriclumMobileimgRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
        CuriculmDeatailsMobileRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1";
        break;  

    case 4:
        WordsMobileRef.style.visibility ="visible";
        ElearningWordsMobileRef.style.visibility ="visible";
        CuriculmAppWordsMobileRef.style.visibility ="visible";
        AwarnessWordsMobileRef.style.visibility ="visible";
        SoialMediaWordsMobileRef.style.visibility ="visible";
        DelveopedAppWordsMobileRef.style.animation = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1"; 
    
    
        ConsulltantContainerRef.style.visibility ="visible";
        ElearningMobileRefIcon.style.visibility ="visible";
        DelveopedAppsMobileRefIcon.style.animation  = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1";
        CuriculmMobileRefIcon.style.visibility ="visible";
        AwarnessMobileRefIcon.style.visibility ="visible";
        SoialMediaMobileRefIcon.style.visibility ="visible";
        DevelopMobileimgRef.style.animation         = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
        DevelopedDeatailsMobileRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
        break;
      
    case 5:
      WordsMobileRef.style.visibility ="visible";
      ElearningWordsMobileRef.style.visibility ="visible";
      CuriculmAppWordsMobileRef.style.visibility ="visible";
      AwarnessWordsMobileRef.style.animation     = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1"; 
      SoialMediaWordsMobileRef.style.visibility ="visible";
      DelveopedAppWordsMobileRef.style.visibility ="visible";
  
  
      ConsulltantContainerRef.style.visibility ="visible";
      ElearningMobileRefIcon.style.visibility ="visible";
      DelveopedAppsMobileRefIcon.style.visibility ="visible";
      CuriculmMobileRefIcon.style.visibility ="visible";
      AwarnessMobileRefIcon.style.animation       = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1";
      SoialMediaMobileRefIcon.style.visibility ="visible";

      AwarnessMobileimgRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
      AwarenessDeatailsMobileRef.style.animation = "FaddedOpacityAnimation 0.1s linear forwards 1"; 

        break; 
 case 6 :
        WordsMobileRef.style.visibility ="visible";
        ElearningWordsMobileRef.style.animation    = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1"; 
        CuriculmAppWordsMobileRef.style.visibility ="visible";
        AwarnessWordsMobileRef.style.visibility ="visible";
        SoialMediaWordsMobileRef.style.visibility ="visible";
        DelveopedAppWordsMobileRef.style.visibility ="visible";


        ConsulltantContainerRef.style.visibility ="visible";
        ElearningMobileRefIcon.style.animation      = "ReverseSoialMediaMobileWordsAnimation 0.2s linear forwards 1";
        DelveopedAppsMobileRefIcon.style.visibility ="visible";
        CuriculmMobileRefIcon.style.visibility ="visible";
        AwarnessMobileRefIcon.style.visibility ="visible";
        SoialMediaMobileRefIcon.style.visibility ="visible";
        ElearningMobileimgRef.style.animation       = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
        ElearningDeatailsMobileRef.style.animation  = "FaddedOpacityAnimation 0.1s linear forwards 1"; 
      break;
    } 
      
    } 
  }
      return (
        <div className="ServicesMobileContainerAll">
        {(isMobile)&&  
        <div className="ServicesMobileContainer">
          <div className="ServiceImgContainerMobile" id="ServiceImgContainerMobile"  >
            <img className="ServiceImgMobile" id ="ServiceImgMobile" fetchpriority="high"  loading="lazy" src={ServiceImgMobile}  alt="ServiceImgMobile"></img>
          </div>
          <div className="CircuitImgContainerMobile" id="CircuitImgContainerMobile" onClick={ReturnToMobileService}>
            <img className="CircuitImgMobile" id ="CircuitImgMobile" fetchpriority="high"  loading="lazy" src={CircuitImgMobile}  alt="CircuitImgMobile"></img>
          </div>
          
          <div className="ConsultantContainerMobile" id="ConsultantContainerMobile" onPointerEnter={goToMobileConsultant}>
            <img className="ConsultantIconMobile" id ="ConsultantIconMobile" fetchpriority="high"  loading="lazy" src={ConsultantIconMobile}  alt="ConsultantIconMobile">  
          </img>
           
          </div>
          
          <p className="SevicesWordMobile" id="SevicesWordMobile" onPointerEnter={ReturnToMobileService}> الخدمات</p>    
          <p className="WordsMobile" id="WordsMobile" onPointerEnter={goToMobileConsultant}>
          التدريب المهني<br/> والاستشارات  
          </p>

            <p className="ConsultantDeatailsMobile" id="ConsultantDeatailsMobile">تبحث الشركات والمؤسسات المتقدمة دائمًا عن تطوير أداء العاملين فيها لتحسين جودة مخرجاتها وتحقيق أهدافها الاستراتيجية، ويتطلب الوصول إلى هذه الأهداف اتباع خطوات استباقية في صقل وتعزيز قدرات موظفيها. في هذا الإطار، تقدم الدوحة لينك خدمات الاستشارة والتدريب للعديد من القطاعات العاملة، ففريقنا المختص من الخبراء والأكاديميين يعملون على صياغة خطط تطويرية تناسب احتياجات جميع عملائنا.</p>
            <p className="SoialMediaWordsMobile" id="SoialMediaWordsMobile" onPointerEnter={goToMobileSoialMedia}>
            التسويق الالكتروني وإدارة <br/> منصات التواصل الاجتماعي 
            </p>
            <p className="ConsultantDeatailsMobile" id="SoialMediaDeatailsMobile">نسير معك في الدوحة لينك خطوة بخطوة نحو الوصول إلى حضور قوي يرسخ في ذاكرة عملائك، وننطلق من صناعة الهوية البصرية، مرورًا بدراسة المنافسين، ووضع استراتيجيات التواصل، وتطوير وصناعة المحتوى باللغتين العربية والإنجليزية، وتسليمه لفريق إبداعي من المصممين والمصورين، ومن ثم نشرف على إدارة هذه المنصات بكفاءة عالية.</p>

            <p className="DelveopedAppWordsMobile" id="DelveopedAppWordsMobile" onPointerEnter={goToMobileDeveloped}>
              التطبيقات <br/> وتطوير المواقع 
            </p>
            <p className="ConsultantDeatailsMobile" id="DevelopedDeatailsMobile"> تعمل الدوحة لينك على بناء المواقع الإلكترونية وتطبيقات الهواتف المحمولة بحيث تحقق الفعالية وتراعي الجمالية وسهولة الاستخدام، حيث يتعاون فريق البرمجة في الشركة مع قسم التصميم وتطوير المحتوى لتلبية عملائنا. </p>

            <p className="CuriculmAppWordsMobile" id="CuriculmAppWordsMobile" onPointerEnter={goToMobileCuriculam}>
            التعليم الإلكتروني<br/>وتطوير المناهج الدراسية 
            </p>
            <p className="ConsultantDeatailsMobile" id="CuriculmDeatailsMobile">يُعّرف التعليم الالكتروني الحديث على انه وسيلة تدعم العملية التعليمية وتحولها من طور التلقين إلى طور الإبداع والتفاعل وتنمية المهارات. وتسعى العديد من المؤسسات التعليمية إلى خلق بيئة إلكترونية رقمية متكاملة تعمل على خدمة اهدافها التعليمية والتربوية على المدى القريب والبعيد. ومع الاقبال المتزايد على التعليم الالكتروني تعمل شركة الدوحة لينك لتكنولوجيا المعلومات على تطوير النظم التعليمية الكترونية التفاعلية وتوظيف التقنيات الحديثة وإدراج محتوى رقمي متعدد الوسائط لتحقيق اهداف المؤسسات التعليمية ورفع عائدها الاستثماري من التعليم الالكتروني، وتقف الخبرات المتعاقبة في مجال تطوير المناهج الدراسية شاهدًا على جودة المخرجات التي تقدمها الدوحة لينك. </p>

            <p className="AwarnessWordsMobile" id="AwarnessWordsMobile" onPointerEnter={goToMobileAwarness}>
              حملات التوعية 
            </p>
            <p className="ConsultantDeatailsMobile" id="AwarnessDeatailsMobile">تعمل الحملات التوعوية على تعزيز التماسك الاجتماعي والتشجيع على المعرفة ومشاركة الجمهور.
تقوم شركة الدوحة لينك بتنفيذ الحملات التوعوية التي تتناسب مع مختلف فئات المجتمع والشرائح العمرية، وذلك من خلال تقديم أفكار إبداعية وتصميم محتوى قوي وجذاب قادر على الوصول الى العملاء المستهدفين بسرعة ورسائل ذات مصداقية وتأثير نفسي عميق قادرة على الثبات في ذاكرة العميل. وتعتمد الدوحة لينك على عدة أنواع من الحملات التوعوية (التصوير الفوتوغرافي –إنتاج الانيميشن)
 </p>

            <p className="ElearningWordsMobile" id="ElearningWordsMobile" onPointerEnter={goToMobileElearning}>
            الأمن السيبراني 
            </p>
            <p className="ConsultantDeatailsMobile" id="ElearningDeatailsMobile">ومع اتساع شبكات الإنترنت أصبحت الجرائم الإلكترونية أكثر تعقيدا وانتشارا، حيث ان التدريب التقليدي لا يتوافق مع التحديات والاختراقات الأمنية المتطورة وتلجأ العديد من الجهات للخبراء في تعزز قدرات الموظفين والطلاب لديها. يمتلك فريق الدوحة لينك الخبرة العملية في مجال الأمن السيبراني وقدرته على صياغة استراتيجيات حديثة ومتطورة تتوافق مع التحديات الحالية والمستقبلية.  </p>

            <img className="ConsultantMobileimg" id ="ConsultantMobileimg" fetchpriority="high"  loading="lazy" src={ConsultantMobileimg}  alt="ConsultantMobileimg"></img>
            <img className="SoialMediaMobileimg" id ="SoialMediaMobileimg"fetchpriority="high"  loading="lazy" src={SoialBackgroundImg}  alt="SoialMediaMobileimg"></img>
            <img className="CurriclumMobileimg" id ="CurriclumMobileimg"  fetchpriority="high"  loading="lazy" src={CurriclumMobileimg}  alt="CurriclumMobileimg"></img>
            <img className="AwarnessMobileimg" id ="AwarnessMobileimg"   fetchpriority="high"  loading="lazy"  src={AwarnessBackgroundImg}  alt="AwarnessMobileimg"></img>
            <img className="DevelopMobileimg" id ="DevelopMobileimg"      fetchpriority="high"  loading="lazy" src={DevelopMobileimg}  alt="DevelopMobileimg"></img>
            <img className="ElearningMobileimg" id ="ElearningMobileimg"  fetchpriority="high"  loading="lazy" src={ElearningMobileimg}  alt="ElearningMobileimg"></img>
   
            
          <div className="DevelopContainerMobile" id="DevelopContainerMobile" onClick={goToMobileDeveloped}>
            <img className="DevelopIconMobile" id ="DevelopIconMobile" fetchpriority="high"  loading="lazy" src={DevelopIconMobile}  alt="DevelopIconMobile"></img>
          
          </div>
          <div className="AwarnessContainerMobile" id="AwarnessContainerMobile" onClick={goToMobileAwarness}>
            <img className="AwarnessIconMobile" id ="AwarnessIconMobile" fetchpriority="high"  loading="lazy" src={AwarnessIconMobile}  alt="AwarnessIconMobile"></img>
          
          </div>
          <div className="SoialContainerMobile" id="SoialContainerMobile" onClick={goToMobileSoialMedia} >
            <img className="SoialIconMobile" id ="SoialIconMobile" fetchpriority="high"  loading="lazy" src={SoialIconMobile}  alt="SoialIconMobile"></img>
          
          </div>
          <div className="CurriclamContainerMobile" id="CurriclamContainerMobile" onClick={goToMobileCuriculam}>
            <img className="CurriclumIconMobile" id ="CurriclumIconMobile"  fetchpriority="high"  loading="lazy" src={CurriclumIconMobile}  alt="CurriclumIconMobile"></img>
          
          </div>
          <div className="ElearingContainerMobile" id="ElearingContainerMobile" onClick={goToMobileElearning}>
            <img className="ElearningIconMobile" id ="ElearningIconMobile" fetchpriority="high"  loading="lazy" src={ElearningIconMobile}  alt="ElearningIconMobile"></img>
          
          </div>
          </div>
          } 
        {(!isMobile)&& 
<div className="container">

  <div className=" col-1 " >
  <div className="NewServicesContainer" id="NewServicesContainer">
      <img className="NewServicesImg" id ="NewServicesImg" src={NewServicesImg}  alt="NewServicesImg"></img>
  </div>
  <div className="CircuitBlueContainer" onClick={ReturnToService}  id="CircuitBlueContainer">
    
      <img className="CircuitImg" id ="CircuitImg" src={CircuitImg}  alt="CircuitImg"></img>
      <p className="MainServiceText" onClick={ReturnToService}  id="MainServiceText">الخدمات </p>
  </div>
  <div className="ServiceHandsContainer" id="ServiceHandsContainer">
      <img className="ServiceHandsImg" id ="ServiceHandsImg" src={ServiceHandsImg}  alt="ServiceHandsImg"></img>
  </div>
  </div>
  <div className=" col-2 ">

  </div>
  <div className="col-3" >
    
  </div>
  <div className="col-4">
  <div className="BackArrow2Services" id="BackArrow2Services" onClick={ReturnToService}></div>
  <p className="MainServiceText1" id="MainServiceText1">الخدمات </p>
  </div>
  <div className="col-5" >

  <div className="MinistryEduProContainer" id="MinistryEduProContainer">
      <img className="MinistryEduProImg" id ="MinistryEduProImg" src={MinistryEduProImg}  alt="MinistryEduProImg"></img>
  <span className="caption">إدارة منصات التواصل لوزارة التربية والتعليم</span>
  </div>
  </div>
  <div className="col-6">
  <div className="OurProjsRectBulletContainer" id="OurProjsRectBulletContainer">
      <div className="OurProjsRectBullet" >
        <div className="OurProjsRectBulletText">من مشروعاتنا</div>
    </div></div>
  </div>
  <div className="col-7" >
  <div className="Icon1Container" id="Icon1Container" onClick={goToElearning}>
      <img className="IconImg1" id ="IconImg1" src={IconImg1}  alt="IconImg1"></img>
  </div>
  <div className="Icon2Container" id="Icon2Container" onClick={goToCurriculm}>
      <img className="IconImg2" id ="IconImg2" src={IconImg2}  alt="IconImg2"></img>
  </div>
  <div className="Icon3Container" id="Icon3Container" onClick={goToSoial}>
      <img className="IconImg3" id ="IconImg3" src={IconImg3}  alt="IconImg3"></img>
  </div>
    <div className="SoialMediaTextDetails" id ="SoialMediaTextDetails">
    نسير معك في الدوحة لينك خطوة بخطوة نحو الوصول إلى حضور قوي يرسخ في ذاكرة عملائك، وننطلق من صناعة الهوية البصرية، مرورًا بدراسة المنافسين، ووضع استراتيجيات التواصل، وتطوير وصناعة المحتوى باللغتين العربية والإنجليزية، وتسليمه لفريق إبداعي من المصممين والمصورين، ومن ثم نشرف على إدارة هذه المنصات بكفاءة عالية.
    </div>
<div className="MinistryMunProContainer" id="MinistryMunProContainer">
      <img className="MinistryMunProImg" id ="MinistryMunProImg" src={MinistryMunProImg}  alt="MinistryMunProImg"></img>
      <span className="caption">حملة توعية وزارة البلدية</span>

  </div>

  
  </div>

  <div className="SoialNetworkText" id="SoialNetworkText" onClick={goToSoial}>التسويق الالكتروني <br/>
  وإدارة منصات <br/>التواصل الاجتماعي</div>
<div className="CuriclamText" id="CuriclamText" onClick={goToCurriculm} >التعليم الإلكتروني<br/>وتطوير المناهج الدراسية 
</div>
<div className="ELearningText" id="ELearningText" onClick={goToElearning}>الأمن السيبراني</div>
<div className="ConsulatantText" id="ConsulatantText" onClick={goToConsultant}><span className="Consoo">التدريب المهني<br/> والاستشارات</span></div>
<div className="AppsText" id="AppsText" onClick={goToAppsDevelop}><span className="Consoo">الخدمات التكنولوجية<br/>
وتطوير المواقع الالكترونية<br/>
وتطبيقات الهاتف 
</span></div>
<div className="AwarnessText" id="AwarnessText" onClick={goToAwarness}><span className="Consoo">الحملات التوعوية</span></div>
  <div className="col-8 ">
 <div className="SoialBackgroundContainer" id="SoialBackgroundContainer">
      <img className="SoialBackgroundImg" id ="SoialBackgroundImg" src={SoialBackgroundImg}  alt="SoialBackgroundImg"></img>
  </div>
  <div className="ConsulatantBackgroundContainer" id="ConsulatantBackgroundContainer">
      <img className="ConsulatantBackgroundImg" id ="ConsulatantBackgroundImg" src={ConsulatantBackgroundImg}  alt="ConsulatantBackgroundImg"></img>
  </div>
  <div className="AppsBackgroundContainer" id="AppsBackgroundContainer">
      <img className="AppsBackgroundImg" id ="AppsBackgroundImg" src={AppsBackgroundImg}  alt="AppsBackgroundImg"></img>
  </div>
  <div className="AwarnessBackgroundContainer" id="AwarnessBackgroundContainer">
      <img className="AwarnessBackgroundImg" id ="AwarnessBackgroundImg" src={AwarnessBackgroundImg}  alt="AwarnessBackgroundImg"></img>
  </div>
  <div className="ElearningBackgroundContainer" id="ElearningBackgroundContainer">
      <img className="ElearningBackgroundImg" id ="ElearningBackgroundImg" src={ElearningBackgroundImg}  alt="ElearningBackgroundImg"></img>
  </div>
  </div>
  <div className="col-9" >
  <div className="CuriculmBackgroundContainer" id="CuriculmBackgroundContainer">
      <img className="CuriculmBackgroundImg" id ="CuriculmBackgroundImg" src={CuriculmBackgroundImg}  alt="CuriculmBackgroundImg"></img>
  </div>


  </div>
  <div className="col-10" >
   
  </div>
   <div className="col-11">
   <div className="Icon4Container" id="Icon4Container" onClick={goToAwarness}>
      <img className="IconImg4" id ="IconImg4" src={IconImg4}  alt="IconImg4"></img>
  </div>
  <div className="Icon5Container" id="Icon5Container" onClick={goToConsultant}>
      <img className="IconImg5" id ="IconImg5" src={IconImg5}  alt="IconImg5"></img>
  </div>
  <div className="Icon6Container" id="Icon6Container" onClick={goToAppsDevelop}>
      <img className="IconImg6" id ="IconImg6" src={IconImg6}  alt="IconImg6"></img>
  </div>
  </div>
  <div className="col-12" >
  
  </div>
 
</div>
    } 
      </div>  
      );
    
  }
  
  export default ServicesFinal;