import React, { useState }  from "react";
import "./MainPrec.css";
import "./App.css";
import "./NewSection.css";
import  newImg from './Images/A1.png';
import twitterImg from './Images/Xlogo.png';
import facebookImg from './Images/Group 26.png';
import instagramImg from './Images/Group 24.png';
import linkedinImg from './Images/Group 29.png';
import googleImg from './Images/Group 27.png';
import locationIcon from './Images/Group 32.png';
import MobileIcon from './Images/Group 37.png';
import EmailIcon from './Images/Group 34.png';


function NewSection(){

      const[isMobile]=useState(window.innerWidth<window.innerHeight);

      
      return (
            <div className="Section6ContainerAll">
                  {(isMobile)&&
               
             <div className="Section6Mobile">

             <div className="SectionBackground">
              <img className="SectionBackgroundImg" fetchpriority="low"  loading="lazy" alt="SectionBackgroundImg" src={newImg}></img>
                </div>
               <p className="OurLocationText">موقعنا</p>
                    <div >
                  
            <iframe className="googleMapMobile" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.5884671797135!2d51.51982057397231!3d25.318027126798626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c4c910445585%3A0x4ae36775725b6eaa!2sQatar%20Tower!5e0!3m2!1sen!2sqa!4v1689840484332!5m2!1sen!2sqa" title="AldohaLinkAddress" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
           
                      <a href="https://twitter.com/aldohalink?s=11&t=3yQOXYv2ranr7DKT-pM0GA" className="twitterMobile" id="twitterMobile">
                      <img className="twitterImgMobile"  fetchpriority="low"  loading="lazy" id ="twitterImgMobile" src={twitterImg}  alt="twitterkImg"></img>

                      </a>
                      <a href="https://www.facebook.com/profile.php?id=100093057608293" className="facebookMobile" id="facebook">
                      <img className="facebookImgMobile" id ="facebookImgMobile"  fetchpriority="low"  loading="lazy" src={facebookImg}  alt="facebookImg"></img>

                      </a>
                      <a href="https://instagram.com/aldohalink?igshid=MzRlODBiNWFlZA==" className="instagramMobile" id="instagram">
                      <img className="facebookImgMobile" id ="facebookImg"  fetchpriority="low"  loading="lazy" src={instagramImg}  alt="facebookImg"></img>

                      </a>

                   
                      <a href="https://www.linkedin.com/company/doha-link/" className="linkedinMobile" id="linkedin">
                      <img className="facebookImgMobile" id ="facebookImg"  fetchpriority="low"  loading="lazy" src={linkedinImg}  alt="facebookImg"></img>
                      </a>
    
           
              <div className="LinesAMobile">
                    <div className="LinesMobile">
                <div className="LinesAstyleMobile">

                </div>
                </div>
            </div>

            <header className="header1Mobile">
                <img className="IconImgMobile" src={MobileIcon} alt="a logo"></img>
                <div className="header-textMobile">
                Phone: <a href="tel:+974 44189854">+974 44189854</a>
 
                </div>
            </header>

            <header className="header2Mobile">
                <img className="IconImgMobile" src={EmailIcon} alt="a logo"></img>
                <div className="header-textMobile">
                    
                Email:<a target="_self" href="mailto:info@aldohalink.com">
                info@aldohalink.com</a> 
 
                </div>
            </header>

            <header className="header3Mobile">
         <img className="IconImgMobile2" src={locationIcon} alt="a logo"></img>
            <div className="header-textMobile">Street 920 – Building No. 35 - Floor No. 10
                Al-Dafna 63, Doha – Qatar. <br></br>
             Copyright © 2024
            </div>
        </header>
              </div>  
                 
                  }
      {(!isMobile)&&
      <div className="Empty">
        </div>
}
      



</div>

      );
    };

  
  export default NewSection;