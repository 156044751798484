import React,{ useLayoutEffect ,useEffect ,useState,useRef } from "react";


import LazyLoad from 'react-lazy-load';
import { Suspense } from "react";
import "../App.css";
import MainPrec from "../MainPrec";


import AboutDoha from "../AboutDoha";
import ServicesFinal from "../ServicesFinal";
import ContactUs from "../ContactUs";
import Projects from "../Projects";
import NewSection from  "../NewSection";

//import OurProjectMainMenu from "../OurProjectMainMenu";
import { useMediaQuery } from 'usehooks-ts';
export default function Section({ title, subtitle, dark, id,randomFlag }) {
  const [size1, setSize1] = useState(500);
  
  const[isMobileFlag,setIsMobileFlag]=useState(window.innerWidth<window.innerHeight);
  //const [size2, setSize2] = useState(((window.innerWidth*864.3)/430));
 
const observed = useRef(null);
const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });


useEffect(() => { 
  console.log("I run every time my condition is changed") 
  }, [size1]); 
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
   
   
    useLayoutEffect(() => {
     
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
        if(window.innerWidth<window.innerHeight){
         // setSize2((document.querySelector('#control-height').clientHeight)-(document.querySelector('#control-height').clientHeight*(67/800)));
         // setSize1(observed.current.getBoundingClientRect().height);
        //  setSize1((document.querySelector('#control-height').clientHeight)-navHht);
        setSize1((((window.innerWidth*739)/390)));

          setIsMobileFlag(true);
        }else{
          setSize1((((window.innerWidth*972)/1920)));
          setIsMobileFlag(false);
        }
       
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  
  function ShowWindowDimensions(props) {
    useWindowSize();
   
  // return <span>Window size: {window.innerHeight} x {tooltipHeight} size1 {size1} size2 {size2} nav {navHht} bar {barHht}</span>;
  }

  
 
  return (
   <div  ref={observed} key={size1}>
    

    <ShowWindowDimensions/>
    
    <LazyLoad height={size1}>
    <div className={"section" + (dark ? " section-dark" : "")}>
       
   
      
      
      <div className="section-content"   id={id}>
      { 
          (isMobile)?{
            "section1": <MainPrec/>,
            "section2": <AboutDoha />,
            "section3": <ServicesFinal isMobile={isMobileFlag}/>,
            "section4": <Projects isMobile={isMobileFlag}/>,
            "section5": <ContactUs isMobile={isMobileFlag} />,
            

          }[id]:
          {
            "section1": <MainPrec/>,
            "section2": <AboutDoha />,
            "section3": <ServicesFinal isMobile={isMobileFlag}/>,
            "section4": <Projects isMobile={isMobileFlag}/>,
            "section5": <ContactUs isMobile={isMobileFlag} />,
            "section6": <NewSection  />,
          }[id]
        }</div>
    
      
      
    </div>
    </LazyLoad>
   
    </div>
  );
 
}